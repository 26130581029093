import React from 'react';
import { Button, Col, Layout, Result, Row } from 'antd';
import NavbarComponent from '../navigation/navbar.component';
import { Helmet } from 'react-helmet';
import FooterComponent from '../navigation/footer.component';
import { BreadCrumbItem, Product } from '../../types';
import { Link, withRouter } from 'react-router-dom';
import { formatPrice } from '../../helpers/price.helper';
import { connect } from 'react-redux';
import { AuthState } from '../../redux/states/user';
import Swal from 'sweetalert2';
import { removeItemFromWishList } from '../../redux/actions/wishlist';
import { IWishListState } from '../../redux/states/wishlist';
import { IAppState } from '../../redux/states/app';
import { HeartOutlined } from '@ant-design/icons';
import { WithTranslation, withTranslation } from 'react-i18next';
import { BannerModule } from './modules/banner.module';
import { settings } from '../../../settings';
import { ICartState } from '../../redux/states/cart';
import '../../styles/products.less';
import '../../styles/wishlist.less';
import HeaderModule from './modules/header.module';

const { Content } = Layout;

interface Props extends WithTranslation {
  history: any;
  app: IAppState;
  auth: AuthState;
  cart: ICartState;
  removeItemFromWishList: typeof removeItemFromWishList;
  wishlist: IWishListState;
}

class WishListComponent extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
    window.scrollTo(0, 0);
  }

  handleRemoveItemClick = (product: Product) => {
    const t = this.props.i18n.t.bind(this.props.i18n);
    this.props.removeItemFromWishList(product.id);
    Swal.fire({
      title: t('pages:wishlist.popup.success.title'),
      html: t('pages:wishlist.popup.success.description', {
        text: `<strong>${product.name[this.props.app.language.code]}</strong>`
      }),
      icon: 'success'
    });
  };

  render() {
    const wishListItems = this.props.wishlist.items;
    const t = this.props.i18n.t.bind(this.props.i18n);
    const voucher = this.props.cart.voucher;

    return (
      <Layout>
        <Helmet>
          <title>{t('pages:wishlist.meta.title')}</title>
          <meta name='description' content={t('pages:wishlist.meta.description')} />
          <meta name='robots' content='noindex,nofollow' />
          <link rel='canonical' href={window.location.origin + window.location.pathname} />
          <meta property='og:title' content={t('pages:wishlist.meta.title')} />
          <meta property='og:description' content={t('pages:wishlist.meta.description')} />
          <meta property='og:url' content={window.location.origin + window.location.pathname} />
          <meta name='twitter:title' content={t('pages:wishlist.meta.title')} />
          <meta name='twitter:description' content={t('pages:wishlist.meta.description')} />
        </Helmet>
        <NavbarComponent />
        <Content className={voucher && voucher.exists && voucher.voucher ? 'voucher-applied' : ''}>
          {settings.showDefaultHeader && (
            <HeaderModule
              section={{
                settings: {
                  title: { en: t('pages:wishlist.page.title') },
                  subtitle: {},
                  size: 'small',
                  parallax: settings.parallaxInHeader,
                  images: [require('../../../assets/header-main.jpg').default],
                  background_image: { non_api: true }
                },
                type: 'header',
                id: 'header',
                language: this.props.app.language.code,
                uid: 'header'
              }}
            />
          )}

          <div className={'content-body'}>
            <Row className={'wishlist-container'}>
              <Col xs={24}>
                {wishListItems.length > 0 ? (
                  <Row className={'products-container'}>
                    <Col xs={{ span: 22, offset: 1 }} md={{ span: 18, offset: 3 }}>
                      <Row>
                        <Col xs={24}>
                          <Row gutter={[20, 20]}>
                            {wishListItems.map((product, pindex) => {
                              const image =
                                product.pictures && product.pictures.length > 0
                                  ? product.pictures[0]
                                  : null;
                              return (
                                <Col
                                  key={pindex}
                                  xs={12}
                                  sm={12}
                                  lg={8}
                                  xxl={6}
                                  className={'product-col'}
                                >
                                  <div className={'product'}>
                                    <Row className={'product-image'}>
                                      <Col xs={24}>
                                        <Link
                                          to={`${settings.products.shoppingPage}${product.slug}`}
                                        >
                                          {image && (
                                            <img
                                              alt={product.summary[this.props.app.language.code]}
                                              src={process.env.REACT_APP_API_URL + image}
                                            />
                                          )}
                                        </Link>
                                      </Col>
                                    </Row>
                                    <Row>
                                      <Col xs={24} className={'product-details'}>
                                        <div>
                                          <strong>
                                            {product.name[this.props.app.language.code]}
                                          </strong>
                                        </div>
                                        <div
                                          dangerouslySetInnerHTML={{
                                            __html: formatPrice(
                                              product.price,
                                              this.props.app.currency,
                                              this.props.app.country
                                            )
                                          }}
                                        />
                                      </Col>
                                    </Row>
                                    <Row>
                                      <Col xs={24} className={'product-actions'}>
                                        <Link
                                          to={`${settings.products.shoppingPage}${product.slug}`}
                                        >
                                          <Button
                                            block={window.innerWidth < 500}
                                            style={{ float: 'left', marginBottom: 5 }}
                                            type={'primary'}
                                          >
                                            {t('pages:wishlist.button.view.text')}
                                          </Button>
                                        </Link>
                                        <Button
                                          block={window.innerWidth < 500}
                                          onClick={() => this.handleRemoveItemClick(product)}
                                          type={'dashed'}
                                        >
                                          {t('pages:wishlist.button.remove.text')}
                                        </Button>
                                      </Col>
                                    </Row>
                                  </div>
                                </Col>
                              );
                            })}
                          </Row>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                ) : (
                  <Row align={'middle'} justify={'center'} className={'no-result-container'}>
                    <Result
                      icon={<HeartOutlined />}
                      status='info'
                      title={t('pages:wishlist.no_items.title')}
                      subTitle={t('pages:wishlist.no_items.subtitle')}
                      extra={
                        <Link to={settings.products.shoppingPage}>
                          <Button size={'large'} type='primary' key='console'>
                            {t('pages:wishlist.no_items.button.go_shopping.text')}
                          </Button>
                        </Link>
                      }
                    />
                  </Row>
                )}
              </Col>
            </Row>
          </div>

          {settings.showCompanyBanner && <BannerModule />}
        </Content>
        <FooterComponent />
      </Layout>
    );
  }
}

const mapStateToProps = (state: any) => ({
  app: state.app,
  auth: state.auth,
  wishlist: state.wishlist,
  cart: state.cart
});

const mapDispatchToProps = (dispatch: any) => ({
  removeItemFromWishList: (id: number) => dispatch(removeItemFromWishList(id))
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withTranslation()(WishListComponent))
);
