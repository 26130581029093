import { Button, Col, Row } from 'antd';
import { Link, withRouter } from 'react-router-dom';
import React from 'react';
import { Event, PageSection } from '../../../types';
import { LoopBack } from '../../../redux/api';
import { connect } from 'react-redux';
import { WithTranslation, withTranslation } from 'react-i18next';
import { IAppState } from '../../../redux/states/app';
import moment from 'moment';
import { ArrowRightOutlined, RightOutlined } from '@ant-design/icons';
import '../../../styles/modules/upcoming-events.less';
import { Parallax } from 'react-parallax';
import { settings } from '../../../../settings';

interface Props extends WithTranslation {
  app: IAppState;
  section: PageSection;
  history: any;
}

interface State {
  events: Event[];
  loading: boolean;
}

class UpcomingEventsModule extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      events: [],
      loading: true
    };

    const filter: any = { order: ['start_date asc'] };
    filter.where = { status: { inq: ['upcoming', 'fully_booked'] } };
    new LoopBack()
      .get(`/events?filter=${JSON.stringify(filter)}`)
      .then(res => {
        this.setState({ events: res, loading: false });
      })
      .catch(err => {
        this.setState({ loading: false });
      });
  }

  getRow = () => {
    const t = this.props.i18n.t.bind(this.props.i18n);
    const section = this.props.section;
    const styles = section.settings.background_color
      ? { backgroundColor: section.settings.background_color }
      : {};
    let style: any = {};

    let bgClass = null;
    if (section.settings.background_image) {
      bgClass = ' has-bg-image';
    }
    if (section.settings.background_color) {
      Object.assign(style, { backgroundColor: section.settings.background_color });
    }

    return (
      <Row
        id={section.uid ? section.uid : undefined}
        key={section.id}
        className={`cms-module-upcoming-events ${bgClass ? bgClass : ''}`}
        style={styles}
      >
        <Col xs={24} sm={{ span: 22, offset: 1 }} xl={{ span: 18, offset: 3 }}>
          <Row gutter={[30, 60]} justify={'center'}>
            <Col xs={24} lg={21} xl={6}>
              <h2>{section.settings.title[this.props.app.language.code]}</h2>

              <div
                dangerouslySetInnerHTML={{
                  __html: section.settings.description[this.props.app.language.code]
                }}
              />
              <Row>
                <Col xs={0} lg={24}>
                  <div className={'cms-module-button-row'}>
                    {section.settings.button &&
                      section.settings.button.text &&
                      section.settings.button.text[section.language] && (
                        <Link to={section.settings.button.url}>
                          <Button block={window.outerWidth < 720} size={'large'} type={'primary'}>
                            {section.settings.button.text[section.language]} <RightOutlined />
                          </Button>
                        </Link>
                      )}
                  </div>
                </Col>
              </Row>
            </Col>
            {this.state.events.length === 0 && !this.state.loading && (
              <Col
                key={'no-events'}
                xs={24}
                sm={12}
                lg={7}
                xl={6}
                style={{ justifyContent: 'center' }}
              >
                <div id={'no-events-text'}
                >
                  {t('products:events.nothing_scheduled')}
                </div>
              </Col>
            )}
            {this.state.events.map((event, index) => {
              return (
                <Col key={index} xs={24} sm={12} lg={7} xl={6}>
                  <div className={'upcoming-event'}>
                    <div className={'upcoming-event-image-container'}>
                      <Link to={`${settings.events.rootUrl}${event.slug}`}>
                        <img
                          alt={event.name[this.props.app.language.code]}
                          src={process.env.REACT_APP_API_URL + event.pictures[0]}
                        />
                      </Link>
                    </div>
                    <div className={'upcoming-event-description'}>
                      <h3>{event.name[this.props.app.language.code]}</h3>
                      <p>
                        {moment(event.start_date).format('ddd, MMM DD')} |{' '}
                        {t('products:events.location.' + event.location)}
                      </p>

                      <Link to={`${settings.events.rootUrl}${event.slug}`}>
                        <Button type={'primary'}>
                          {event.status === 'fully_booked'
                            ? t('products:events.button.view_event')
                            : t('products:events.button.book_now')}{' '}
                          <ArrowRightOutlined />
                        </Button>
                      </Link>
                    </div>
                  </div>
                </Col>
              );
            })}
            <Col xs={24} lg={0}>
              <div className={'cms-module-button-row'}>
                {section.settings.button &&
                  section.settings.button.text &&
                  section.settings.button.text[section.language] && (
                    <Link to={section.settings.button.url}>
                      <Button block={window.outerWidth < 720} size={'large'} type={'primary'}>
                        {section.settings.button.text[section.language]} <RightOutlined />
                      </Button>
                    </Link>
                  )}
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    );
  };

  render() {
    const section = this.props.section;

    return (
      <>
        {section.settings.background_image ? (
          <Parallax
            bgImage={`${process.env.REACT_APP_API_URL + section.settings.background_image}`}
            bgImageAlt=''
            strength={200}
          >
            {this.getRow()}
          </Parallax>
        ) : (
          this.getRow()
        )}
      </>
    );
  }
}

const mapStateToProps = (state: any) => ({
  app: state.app
});

export default withRouter(
  connect<any, any, any>(mapStateToProps)(withTranslation()(UpcomingEventsModule))
);
