import React from 'react';
import { Button, Layout, Result, Row } from 'antd';
import NavbarComponent from '../navigation/navbar.component';
import { Helmet } from 'react-helmet';
import FooterComponent from '../navigation/footer.component';
import { connect } from 'react-redux';
import { AuthState } from '../../redux/states/user';
import * as qs from 'query-string';
import { LoopBack } from '../../redux/api';
import { Link } from 'react-router-dom';
import { IAppState } from '../../redux/states/app';
import { WithTranslation, withTranslation } from 'react-i18next';
import { settings } from '../../../settings';
import QueueAnim from 'rc-queue-anim';
import '../../styles/verify.less';
import NavbarFloatingComponent from '../navigation/navbar-floating.component';

const { Content } = Layout;

interface Props extends WithTranslation {
  app: IAppState;
  auth: AuthState;
  history: any;
}

interface State {
  loading: boolean;
  status: string;
  token: string;
}

class VerifyComponent extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    let token = this.getToken();
    this.state = {
      loading: true,
      status: 'pending',
      token: token
    };
    window.scrollTo(0, 0);
    new LoopBack()
      .post('/users/verify', { token: token })
      .then(res => {
        this.setState({ loading: false, status: 'success' });
      })
      .catch(err => {
        this.setState({ loading: false, status: 'error' });
      });
  }

  getToken() {
    let token: any = null;
    // eslint-disable-next-line no-restricted-globals
    let queryParams = qs.parse(location.search);
    if (Object.keys(queryParams).indexOf('access_token') > -1) {
      token = queryParams.access_token;
    }
    return token;
  }

  getButtons = (type: string) => {
    const t = this.props.i18n.t.bind(this.props.i18n);

    let buttons = [];
    if (settings.navbar.userAccount.style === 'page') {
      buttons.push(
        <Link to={'/login'}>
          <Button size={'large'} type={'primary'} key={'login'}>
            {t(`auth:verify.${type}.button.login.text`)}
          </Button>
        </Link>
      );
    }
    buttons.push(
      <Link to={settings.products.shoppingPage}>
        <Button size={'large'} type='primary' key='console'>
          {t(`auth:verify.${type}.button.go_shopping.text`)}
        </Button>
      </Link>
    );
    return buttons;
  };

  render() {
    const apiSettings = this.props.app.settings;
    const t = this.props.i18n.t.bind(this.props.i18n);

    return (
      <Layout>
        <Helmet>
          <title>{t('auth:verify.meta.title')}</title>
          <meta name='description' content={t('auth:verify.meta.description')} />
          <meta name='robots' content='noindex,nofollow' />
          <link rel='canonical' href={window.location.origin + window.location.pathname} />
          <meta property='og:title' content={t('auth:verify.meta.title')} />
          <meta property='og:description' content={t('auth:verify.meta.description')} />
          <meta property='og:url' content={window.location.origin + window.location.pathname} />
          <meta name='twitter:title' content={t('auth:verify.meta.title')} />
          <meta name='twitter:description' content={t('auth:verify.meta.description')} />
        </Helmet>
        <NavbarComponent />
        <Content
          id={'page-verify'}
          className={`${apiSettings?.navbar_settings?.type === 'transparent' ? 'no-offset' : ''}`}
        >
          {settings.navbar.linksStyle === 'floating' && <NavbarFloatingComponent />}

          <div className={'content-header with-overlay default-header'}>
            <QueueAnim className={'queue-anim-container'} type={'scale'} duration={1500}>
              <h1 key={1}>
                {this.state.status === 'success' ? 'Account Verified' : 'Verify Account'}
              </h1>
            </QueueAnim>
          </div>
          <Row className={'verify-container'} justify={'center'} align={'middle'}>
            {this.state.loading ? (
              <span>{t('auth:verify.loading.text')}</span>
            ) : (
              <div className={'result-container'}>
                {this.state.status === 'success' ? (
                  <Result
                    status='success'
                    title={t('auth:verify.success.title')}
                    subTitle={t('auth:verify.success.subtitle')}
                    extra={() => this.getButtons('success')}
                  />
                ) : (
                  <Result
                    status='error'
                    title={t('auth:verify.error.title')}
                    subTitle={t('auth:verify.error.subtitle')}
                    extra={() => this.getButtons('error')}
                  />
                )}
              </div>
            )}
          </Row>
        </Content>
        <FooterComponent />
      </Layout>
    );
  }
}

const mapStateToProps = (state: any) => ({
  app: state.app,
  auth: state.auth
});

export default connect(mapStateToProps)(withTranslation()(VerifyComponent));
