import { Button, Result } from 'antd';
import { Link } from 'react-router-dom';
import React from 'react';
import { ArrowLeftOutlined, RedoOutlined } from '@ant-design/icons';

export const statusDiv = (status: string | null, callback: () => void, t: any) => {
  switch (status) {
    case 'canceled':
      return (
        <div className={'info-box'}>
          <Result
            status='warning'
            title={t('pages:payment_status.status.canceled.title')}
            subTitle={t('pages:payment_status.status.canceled.description')}
            extra={
              <Link to={'/cart'}>
                <Button icon={<ArrowLeftOutlined />} size={'large'} type={'primary'}>
                  {t('pages:payment_status.status.canceled.button.try_again.text')}
                </Button>
              </Link>
            }
          />
        </div>
      );
    case 'declined':
      return (
        <div className={'info-box'}>
          <Result
            status='error'
            title={t('pages:payment_status.status.declined.title')}
            subTitle={t('pages:payment_status.status.declined.description')}
            extra={
              <Link to={'/cart'}>
                <Button icon={<ArrowLeftOutlined />} size={'large'} type={'primary'}>
                  {t('pages:payment_status.status.declined.button.try_again.text')}
                </Button>
              </Link>
            }
          />
        </div>
      );
    case 'failed':
      return (
        <div className={'info-box'}>
          <Result
            status='warning'
            title={t('pages:payment_status.status.failed.title')}
            subTitle={t('pages:payment_status.status.failed.description')}
            extra={
              <Link to={'/cart'}>
                <Button icon={<ArrowLeftOutlined />} size={'large'} type={'primary'}>
                  {t('pages:payment_status.status.failed.button.try_again.text')}
                </Button>
              </Link>
            }
          />
        </div>
      );
    case 'expired':
      return (
        <div className={'info-box'}>
          <Result
            status='warning'
            title={t('pages:payment_status.status.expired.title')}
            subTitle={t('pages:payment_status.status.expired.description')}
            extra={
              <Link to={'/cart'}>
                <Button icon={<ArrowLeftOutlined />} size={'large'} type={'primary'}>
                  {t('pages:payment_status.status.expired.button.try_again.text')}
                </Button>
              </Link>
            }
          />
        </div>
      );
    case 'pending':
      return (
        <div className={'info-box'}>
          <Result
            status='info'
            title={t('pages:payment_status.status.pending.title')}
            subTitle={t('pages:payment_status.status.pending.description')}
            extra={
              <Button
                icon={<RedoOutlined />}
                size={'large'}
                type={'primary'}
                onClick={() => callback()}
              >
                {t('pages:payment_status.status.pending.button.try_again.text')}
              </Button>
            }
          />
        </div>
      );
    default:
      return (
        <div className={'info-box'}>
          <Result
            status='warning'
            title={t('pages:payment_status.status.warning.title')}
            subTitle={t('pages:payment_status.status.warning.description')}
            extra={
              <Button
                icon={<RedoOutlined />}
                size={'large'}
                type={'primary'}
                onClick={() => callback()}
              >
                {t('pages:payment_status.status.pending.button.try_again.text')}
              </Button>
            }
          />
        </div>
      );
  }
};
