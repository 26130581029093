export function debounce(callback: any, wait: number, immediate = false) {
  let timeout: any = null;

  return function() {
    const callNow = immediate && !timeout;

    clearTimeout(timeout);
    timeout = setTimeout(callback, wait);

    if (callNow) {
      callback();
    }
  };
}
