import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { Col, Layout, Row, Button, Result } from 'antd';
import { withRouter, Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import NavbarComponent from '../../core-module/components/navigation/navbar.component';
import BreadcrumbsComponent from '../../core-module/components/navigation/breadcrumbs.component';
import FooterComponent from '../../core-module/components/navigation/footer.component';
import { LoopBack } from '../../core-module/redux/api';
import { BreadCrumbItem } from '../../core-module/types';
import { IAppState } from '../../core-module/redux/states/app';
import QueueAnim from 'rc-queue-anim';
import { WithTranslation, withTranslation } from 'react-i18next';
import { settings } from '../../settings';
import { ICartState } from '../../core-module/redux/states/cart';
import { Event } from '../../core-module/types';
import moment from 'moment';
import { Parallax } from 'react-parallax';
import '../styles/timeline.less';
import { LoadingOutlined } from '@ant-design/icons';

const { Content } = Layout;

interface Props extends WithTranslation {
  history: any;
  app: IAppState;
  cart: ICartState;
  dispatch: Dispatch;
}

interface State {
  events: Event[];
  loading: boolean;
}

class TimelineComponent extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      events: [],
      loading: true
    };

    window.scrollTo(0, 0);

    this.getEvents();
  }

  getEvents = () => {
    new LoopBack()
      .get(`/events`)
      .then((events: Event[]) => {
        this.setState({ events: events, loading: false });
      })
      .catch(err => {
        this.setState({ events: [], loading: false });
      });
  };

  render() {
    const t = this.props.i18n.t.bind(this.props.i18n);
    const voucher = this.props.cart.voucher;

    const breadcrumbs: BreadCrumbItem[] = [
      { title: t('app:breadcrumbs.home'), link: '/' },
      { title: t('app:breadcrumbs.events'), link: null }
    ];

    return (
      <Layout>
        <Helmet>
          <title>{t('pages:events.meta.title')}</title>
          <meta name='description' content={t('pages:events.meta.description')} />
          <meta name='robots' content='noindex,nofollow' />
          <link rel='canonical' href={window.location.origin + window.location.pathname} />
          <meta property='og:title' content={t('pages:faqs.meta.title')} />
          <meta property='og:description' content={t('pages:events.meta.description')} />
          <meta property='og:url' content={window.location.origin + window.location.pathname} />
          <meta name='twitter:title' content={t('pages:events.meta.title')} />
          <meta name='twitter:description' content={t('pages:events.meta.description')} />
        </Helmet>
        <NavbarComponent />
        <Content className={voucher && voucher.exists && voucher.voucher ? 'voucher-applied' : ''}>
          {settings.showDefaultHeader && (
            <div className={'content-header with-overlay default-header'}>
              <QueueAnim className={'queue-anim-container'} type={'scale'} duration={1500}>
                <h1 key={1}>{t('pages:events.page.title')}</h1>
              </QueueAnim>
              <BreadcrumbsComponent items={breadcrumbs} />
            </div>
          )}
          <Parallax
            bgImage={require('../../assets/bg-geometry.jpg').default}
            bgImageAlt=''
            strength={200}
          >
            <div className={'content-body page-timeline'}>
              <Row>
                <Col xs={24}>
                  <div className={`${this.state.loading && 'loading'} timeline-container`}>

                    {this.state.loading ?
                      <Result
                        icon={<LoadingOutlined/>}
                        status='success'
                        title={t('pages:events.loading')}
                      />
                    :
                      this.state.events.map((event: Event, idx) => (
                          <div key={idx} className='timeline-item'>
                            <div className='timeline-item-container'>
                              <div className={'timeline-item-container-header'}>
                                <time className={'timeline-item-container-header-date'}>
                                  <div className={'timeline-item-container-header-date-day'}>
                                    {moment(event.start_date).format('DD')}
                                    <br />
                                  </div>
                                  <div className={'timeline-item-container-header-date-month'}>
                                    {moment(event.start_date).format('MMM')}
                                  </div>
                                </time>
                                <div className={'timeline-item-container-header-title'}>
                                  <h4>ART WORKSHOP</h4>
                                  <h3>{event.name[this.props.app.language.code]}</h3>
                                </div>
                              </div>
                              <div className={'timeline-item-container-content'}>
                                <p
                                  dangerouslySetInnerHTML={{
                                    __html: event.description[this.props.app.language.code]
                                  }}
                                />

                                <img src={process.env.REACT_APP_API_URL + event.pictures[0]} />
                                <Link to={settings.events.rootUrl + event.slug}>
                                  <Button type={'primary'}>{t('pages:events.button.read_more')}</Button>
                                </Link>
                              </div>
                              <span className='circle' />
                            </div>
                          </div>
                        ))
                    }
                  </div>
                </Col>
              </Row>
            </div>
          </Parallax>
        </Content>
        <FooterComponent />
      </Layout>
    );
  }
}

const mapStateToProps = (state: any) => ({
  app: state.app,
  cart: state.cart
});

export default withRouter(connect(mapStateToProps)(withTranslation()(TimelineComponent)));
