import React, { Component } from 'react';
import { removeItemFromCart } from '../../redux/actions/cart';
import { connect } from 'react-redux';
import { AuthState } from '../../redux/states/user';
import { withRouter } from 'react-router-dom';
import { ICartState } from '../../redux/states/cart';
import { Currency } from '../../types';
import { logoutUser } from '../../redux/actions/auth';
import { IWishListState } from '../../redux/states/wishlist';
import { IAppState } from '../../redux/states/app';
import { setCurrency, setLanguage } from '../../redux/actions/app';
import { withTranslation, WithTranslation } from 'react-i18next';
import { getMenu } from './helpers/menu.helper';
import '../../styles/navbar-floating.less';

interface Props extends WithTranslation {
  history: any;
  auth: AuthState;
  cart: ICartState;
  app: IAppState;
  wishlist: IWishListState;
  logoutUser: typeof logoutUser;
  removeItemFromCart: typeof removeItemFromCart;
  setCurrency: typeof setCurrency;
  setLanguage: typeof setLanguage;
}

interface State {
  collapsed: boolean;
}

class NavbarFloatingComponent extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      collapsed: false
    };
  }

  render() {
    const apiSettings = this.props.app.settings;

    return (
      <div id={'navbar-floating'}>
        {apiSettings && getMenu(apiSettings, this.props.app.language.code, 'inline')}
      </div>
    );
  }
}

const mapStateToProps = (state: any) => ({
  app: state.app,
  auth: state.auth,
  cart: state.cart,
  wishlist: state.wishlist
});

const mapDispatchToProps = (dispatch: any) => ({
  logoutUser: () => dispatch(logoutUser()),
  removeItemFromCart: (id: string) => dispatch(removeItemFromCart(id)),
  setCurrency: (currency: Currency) => dispatch(setCurrency(currency)),
  setLanguage: (language: { code: string; name: string }) => dispatch(setLanguage(language))
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withTranslation()(NavbarFloatingComponent))
);
