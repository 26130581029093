import React from 'react';
import { Button, Form, Input } from 'antd';
import { connect } from 'react-redux';
import { ICartState } from '../../../redux/states/cart';
import { IAppState } from '../../../redux/states/app';
import { FormInstance } from 'antd/lib/form';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { AuthState } from '../../../redux/states/user';
import { LoopBack } from '../../../redux/api';
import Swal from 'sweetalert2';
import { WithTranslation, withTranslation } from 'react-i18next';

interface Props extends WithTranslation {
  history: any;
  auth: AuthState;
  app: IAppState;
  cart: ICartState;
  mode: string;
  updateMode: (mode: string) => void;
}

class ForgotPasswordComponent extends React.Component<Props> {
  formRef = React.createRef<FormInstance>();

  onFinishForgotPassword = (values: any) => {
    const t = this.props.i18n.t.bind(this.props.i18n);
    const data = {
      ...values,
      language: this.props.app.language.code
    };
    new LoopBack()
      .post('/users/reset-mail', data)
      .then(res => {
        Swal.fire({
          title: t('cart:forgot_password.popup.reset_mail.success.title'),
          text: t('cart:forgot_password.popup.reset_mail.success.description'),
          icon: 'success',
          confirmButtonText: t('cart:forgot_password.popup.reset_mail.success.button.ok')
        });
        this.props.updateMode('user');
      })
      .catch(err => {
        Swal.fire({
          title: t('cart:forgot_password.popup.reset_mail.error.title'),
          text: t('cart:forgot_password.popup.reset_mail.error.description'),
          icon: 'error',
          confirmButtonText: t('cart:forgot_password.popup.reset_mail.error.button.ok')
        });
      });
  };

  render() {
    const layout = {
      labelCol: { span: 6 },
      wrapperCol: { span: 18 }
    };
    const tailLayout = {
      wrapperCol: { xs: { offset: 0, span: 18 }, sm: { offset: 6, span: 18 } }
    };

    const titleLayout = { ...tailLayout.wrapperCol };
    titleLayout.xs = { offset: 0, span: 20 };
    const t = this.props.i18n.t.bind(this.props.i18n);

    return (
      <Form
        {...layout}
        name='basic'
        initialValues={{ remember: true }}
        onFinish={this.onFinishForgotPassword}
      >
        <div className={'form-section'}>
          <h2 style={{ marginTop: 20 }}>{t('cart:forgot_password.title')}</h2>
          <Button
            style={{ float: 'right', marginTop: 20 }}
            type={'default'}
            onClick={() => this.props.updateMode('guest')}
          >
            <ArrowLeftOutlined /> {t('cart:forgot_password.button.check_out_as_guest.text')}
          </Button>
          <hr />

          <Form.Item
            label={t('cart:forgot_password.form.email.label')}
            name='email'
            rules={[{ required: true, message: t('cart:forgot_password.form.email.warning') }]}
          >
            <Input size={'large'} placeholder={t('cart:forgot_password.form.email.placeholder')} />
          </Form.Item>

          <Form.Item {...tailLayout}>
            <Button
              block={true}
              size={'large'}
              loading={this.props.auth.loading}
              type='primary'
              htmlType='submit'
            >
              {this.props.auth.loading ? (
                <span>{t('cart:forgot_password.form.button.submit.loading.text')}</span>
              ) : (
                <span>{t('cart:forgot_password.form.button.submit.text')}</span>
              )}
            </Button>
            <Button
              type={'link'}
              style={{ marginTop: 20 }}
              onClick={() => this.props.updateMode('user')}
            >
              <ArrowLeftOutlined /> {t('cart:forgot_password.form.button.back_to_login.text')}
            </Button>
          </Form.Item>
        </div>
      </Form>
    );
  }
}

const mapStateToProps = (state: any) => ({
  app: state.app,
  auth: state.auth,
  cart: state.cart
});

export default connect(mapStateToProps)(withTranslation()(ForgotPasswordComponent));
