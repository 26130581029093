import React, { Component } from 'react';
import { removeItemFromCart } from '../../redux/actions/cart';
import { connect } from 'react-redux';
import { Badge, Button, Col, Dropdown, Layout, Menu, Row, Space } from 'antd';
import {
  DownOutlined,
  FlagOutlined,
  HeartOutlined,
  KeyOutlined,
  LoginOutlined,
  LogoutOutlined,
  UserOutlined
} from '@ant-design/icons';
import { AuthState } from '../../redux/states/user';
import { Link, withRouter } from 'react-router-dom';
import { ICartState } from '../../redux/states/cart';
import { Currency } from '../../types';
import { logoutUser } from '../../redux/actions/auth';
import { IWishListState } from '../../redux/states/wishlist';
import { IAppState } from '../../redux/states/app';
import { setCurrency, setLanguage } from '../../redux/actions/app';
import { settings } from '../../../settings';
import { withTranslation, WithTranslation } from 'react-i18next';
import ProductSearchComponent from './product-search.component';
import ShoppingCartComponent from './shopping-cart.component';
import { getMenu } from './helpers/menu.helper';
import WishlistModal from '../popups/wishlist.modal';
import AuthModal from '../popups/auth.modal';
import '../../styles/header.less';

const { Header } = Layout;

interface Props extends WithTranslation {
  history: any;
  match: any;
  auth: AuthState;
  cart: ICartState;
  app: IAppState;
  wishlist: IWishListState;
  logoutUser: typeof logoutUser;
  removeItemFromCart: typeof removeItemFromCart;
  setCurrency: typeof setCurrency;
  setLanguage: typeof setLanguage;
}

interface State {
  scrollTop: number;
  menuVisible: boolean;
  showWishlistModal: boolean;
  showUserAccountModal: boolean;
  path: string;
}

class NavbarDefaultComponent extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      scrollTop: 0,
      menuVisible: false,
      showWishlistModal: false,
      showUserAccountModal: false,
      path: window.location.href
    };

    this.onSuccess = this.onSuccess.bind(this);
  }

  componentDidMount(): void {
    window.addEventListener('scroll', this.saveScrollTopValue);
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any): void {
    if (window.location.href !== this.state.path) {
      this.setState({ path: window.location.href, menuVisible: false });
    }
  }

  saveScrollTopValue = () => {
    let supportPageOffset = window.pageXOffset !== undefined;
    let isCSS1Compat = (document.compatMode || '') === 'CSS1Compat';
    let scrollTop = supportPageOffset
      ? window.pageYOffset
      : isCSS1Compat
      ? document.documentElement.scrollTop
      : document.body.scrollTop;

    this.setState({ scrollTop: scrollTop });
  };

  componentWillUnmount() {
    window.removeEventListener('scroll', this.saveScrollTopValue);
  }

  loginMenu = () => {
    const t = this.props.i18n.t.bind(this.props.i18n);
    return (
      <Menu>
        <Menu.Item>
          <Link to={'/login'}>
            <LoginOutlined /> {t('app:navbar.menu.auth.go_to_login.label')}
          </Link>
        </Menu.Item>
        <Menu.Item>
          <Link to={'/forgot-password'}>
            <KeyOutlined /> {t('app:navbar.menu.auth.forgot_password.label')}
          </Link>
        </Menu.Item>
        <Menu.Divider />
        <Menu.Item>
          <Link to={'/register'}>
            <Button style={{ width: 400 }} type={'primary'}>
              {t('app:navbar.menu.auth.button.text')}
            </Button>
          </Link>
        </Menu.Item>
      </Menu>
    );
  };

  logoutMenu = () => {
    const t = this.props.i18n.t.bind(this.props.i18n);
    const customUserIcon = settings.navbar.userAccount.customIcon;

    return (
      <Menu>
        <Menu.Item>
          <Link to={'/profile'}>
            {customUserIcon ? (
              <img
                alt={'profile icon'}
                src={require(`../../../assets/${customUserIcon}`).default}
              />
            ) : (
              <UserOutlined />
            )}
            &nbsp;{t('app:navbar.menu.auth.my_account.label')}
          </Link>
        </Menu.Item>
        <Menu.Item>
          <Link to={'/change-password'}>
            <KeyOutlined /> {t('app:navbar.menu.auth.change_password.label')}
          </Link>
        </Menu.Item>
        <Menu.Divider />
        <Menu.Item onClick={() => this.props.logoutUser()}>
          <LogoutOutlined /> {t('app:navbar.menu.auth.logout.label')}
        </Menu.Item>
      </Menu>
    );
  };

  onSuccess = () => {
    this.props.logoutUser();
  };

  currency = () => {
    const t = this.props.i18n.t.bind(this.props.i18n);

    return (
      <Menu>
        {this.props.app.currencies.map((currency, index) => {
          return (
            <Menu.Item key={index} onClick={() => this.props.setCurrency(currency)}>
              {currency.symbol} {t(`app:navbar.menu.currency.${currency.code.toLowerCase()}.label`)}
            </Menu.Item>
          );
        })}
      </Menu>
    );
  };

  handleChangeLanguage = (language: { name: string; code: string }) => {
    this.props.i18n.changeLanguage(language.code.toLowerCase());
    this.props.setLanguage(language);
  };

  language = () => {
    const t = this.props.i18n.t.bind(this.props.i18n);

    return (
      <Menu>
        {settings.languages.map((language: { name: string; code: string }, index: number) => {
          return (
            <Menu.Item key={index} onClick={() => this.handleChangeLanguage(language)}>
              {t(`app:navbar.menu.language.${language.code.toLowerCase()}.label`)}
            </Menu.Item>
          );
        })}
      </Menu>
    );
  };

  userAccountIcon = () => {
    const userAccountStyle = settings.navbar.userAccount.style;
    const customUserIcon = settings.navbar.userAccount.customIcon;
    const t = this.props.i18n.t.bind(this.props.i18n);

    switch (userAccountStyle) {
      case 'popup':
        return (
          <span id={'login-menu'}>
            {customUserIcon ? (
              <img
                alt={'profile icon'}
                src={require(`../../../assets/${customUserIcon}`).default}
                onClick={() => this.setState({ showUserAccountModal: true })}
              />
            ) : (
              <UserOutlined onClick={() => this.setState({ showUserAccountModal: true })} />
            )}
            <AuthModal
              visible={this.state.showUserAccountModal}
              hidePopup={() => this.setState({ showUserAccountModal: false })}
            />
          </span>
        );
      default:
        if (this.props.auth.isLoggedIn) {
          return (
            <Dropdown overlay={this.logoutMenu()}>
              <span>
                {customUserIcon ? (
                  <img
                    alt={'profile icon'}
                    src={require(`../../../assets/${customUserIcon}`).default}
                  />
                ) : (
                  <UserOutlined />
                )}
                <span className={'dropdown-label'}>
                  &nbsp;
                  {this.props.auth.user.customer
                    ? this.props.auth.user.customer.first_name
                    : t('app:navbar.menu.auth.my_account.label')}
                  &nbsp;
                </span>
                <DownOutlined />
              </span>
            </Dropdown>
          );
        } else {
          return (
            <span id={'login-menu'}>
              <Dropdown overlay={this.loginMenu()}>
                <span>
                  {customUserIcon ? (
                    <img
                      alt={'profile icon'}
                      src={require(`../../../assets/${customUserIcon}`).default}
                    />
                  ) : (
                    <UserOutlined />
                  )}
                  <span className={'dropdown-label'}>
                    &nbsp;
                    {t('app:navbar.menu.auth.login.label')}
                  </span>{' '}
                  <DownOutlined />
                </span>
              </Dropdown>
            </span>
          );
        }
    }
  };

  wishlistIcon = () => {
    const wishlistStyle = settings.navbar.wishlist.style;
    const customIcon = settings.navbar.wishlist.customIcon;
    switch (wishlistStyle) {
      case 'popup':
        return (
          <>
            <span onClick={() => this.setState({ showWishlistModal: true })}>
              <Badge className={'header-cart-badge'} count={this.props.wishlist.items.length}>
                {customIcon ? (
                  <img
                    alt={'wishlist icon'}
                    src={require(`../../../assets/${customIcon}`).default}
                  />
                ) : (
                  <HeartOutlined />
                )}
              </Badge>
            </span>
            <WishlistModal
              visible={this.state.showWishlistModal}
              hidePopup={() => this.setState({ showWishlistModal: false })}
            />
          </>
        );
      default:
        return (
          <Link to={'/wishlist'}>
            <Badge className={'header-cart-badge'} count={this.props.wishlist.items.length}>
              {customIcon ? (
                <img alt={'wishlist icon'} src={require(`../../../assets/${customIcon}`).default} />
              ) : (
                <HeartOutlined />
              )}
            </Badge>
          </Link>
        );
    }
  };

  renderCurrencyLanguageDropdown = () => {
    const t = this.props.i18n.t.bind(this.props.i18n);

    return (
      <>
        {!this.props.app.currenciesLoading &&
        !this.props.app.countriesLoading &&
        !this.props.app.languagesLoading ? (
          <Space className={'currency-country-dropdown'}>
            {settings.languages.length > 1 && (
              <>
                <Dropdown overlay={this.language}>
                  {this.props.app.language ? (
                    <span>
                      <FlagOutlined />
                      &nbsp;
                      <span className={'dropdown-label'}>
                        {t(
                          `app:navbar.menu.language.${this.props.app.language.code.toLowerCase()}.label`
                        )}
                      </span>{' '}
                      <DownOutlined />
                    </span>
                  ) : (
                    <span>
                      <span className={'dropdown-label'}>
                        <FlagOutlined />
                      </span>{' '}
                      {t('app:navbar.menu.currency.no_language_set.label')} <DownOutlined />
                    </span>
                  )}
                </Dropdown>
                {this.props.app.currencies.length > 1 && <span>|</span>}
              </>
            )}
            {this.props.app.currencies.length > 1 && (
              <Dropdown overlay={this.currency}>
                {this.props.app.currency ? (
                  <span>
                    {this.props.app.currency.symbol}{' '}
                    <span className={'dropdown-label'}>
                      {t(
                        `app:navbar.menu.currency.${this.props.app.currency.code.toLowerCase()}.label`
                      )}{' '}
                    </span>
                    <DownOutlined />
                  </span>
                ) : (
                  <span>
                    {t('app:navbar.menu.currency.no_currency_set.label')} <DownOutlined />
                  </span>
                )}
              </Dropdown>
            )}
          </Space>
        ) : (
          <span id={'currency-loading'}>Loading...</span>
        )}
      </>
    );
  };

  render() {
    const apiSettings = this.props.app.settings;
    const t = this.props.i18n.t.bind(this.props.i18n);
    const categories = apiSettings && apiSettings.navbar_settings?.category_bar.links;
    const voucher = this.props.cart.voucher;
    let headerClassName = `navbar-${apiSettings?.navbar_settings?.type}`;
    if (this.state.scrollTop !== 0) {
      headerClassName += ' header-solid';
    }
    if (categories && categories.length > 0) {
      headerClassName += ' header-categories';
    }
    const dropdownContainer = document.getElementById('header-navbar');
    const languageIsNextToLogo = settings.navbar.languageCurrencyPlacement === 'next-to-logo';
    const customUserIcon = settings.navbar.userAccount.customIcon;

    return (
      <Header id={`header-navbar`} className={headerClassName}>
        {voucher && voucher.exists && (
          <Row id={'header-voucher'}>
            <Col xs={24} lg={0} className={'voucher-text'}>
              {t('app:navbar.voucher.title.mobile', { text: voucher.voucher.name })}
            </Col>
            <Col xs={0} lg={24} className={'voucher-text'}>
              {voucher.voucher.navbarText[this.props.app.language.code]}
            </Col>
          </Row>
        )}
        <Row id={'header-main'}>
          <Col xs={{ span: 24 }}>
            <Row>
              <Col xs={6} md={3} xl={4}>
                <Link id={'header-main-logo'} to={'/'}>
                  <img
                    alt={`Logo ${settings.appName}`}
                    src={require('../../../assets/logo-color.png').default}
                  />
                </Link>
              </Col>
              {languageIsNextToLogo && (
                <Col xs={8} md={5} lg={4}>
                  {this.renderCurrencyLanguageDropdown()}
                </Col>
              )}
              <Col xs={0} md={11} xl={8}>
                {apiSettings && settings.navbar.linksStyle !== 'floating' && (
                  <nav>{getMenu(apiSettings, this.props.app.language.code, 'horizontal')}</nav>
                )}
              </Col>
              <Col
                xs={!languageIsNextToLogo ? 18 : 10}
                md={!languageIsNextToLogo ? 10 : 5}
                xl={!languageIsNextToLogo ? 12 : 8}
              >
                <div id={'header-main-auth-menu'}>
                  {settings.navbar.languageCurrencyPlacement === 'next-to-cart' &&
                    this.renderCurrencyLanguageDropdown()}

                  {settings.navbar.search.enable && <ProductSearchComponent />}

                  {this.wishlistIcon()}

                  <ShoppingCartComponent />

                  {settings.navbar.userAccount.enable && this.userAccountIcon()}

                  {apiSettings &&
                    window.outerWidth < settings.navbar.hamburgerBreakpoint &&
                    apiSettings.navbar_settings &&
                    apiSettings.navbar_settings.bottom_bar &&
                    apiSettings.navbar_settings.bottom_bar.links &&
                    apiSettings.navbar_settings.bottom_bar.links.length > 0 && (
                      <Dropdown
                        getPopupContainer={() =>
                          dropdownContainer ? dropdownContainer : document.body
                        }
                        overlay={getMenu(apiSettings, this.props.app.language.code, 'horizontal')}
                        trigger={['click']}
                        onVisibleChange={e => this.setState({ menuVisible: e })}
                      >
                        <div id={'header-menu-mobile'}>
                          <div
                            id='header-main-hamburger'
                            className={this.state.menuVisible ? 'open' : ''}
                          >
                            <span />
                            <span />
                            <span />
                            <span />
                          </div>
                        </div>
                      </Dropdown>
                    )}
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
        {categories && this.props.app.showCategoriesBar && (
          <Row id={'header-categories'}>
            <Col xs={24}>
              <nav>
                <Menu selectedKeys={[window.location.pathname]} mode='horizontal'>
                  {categories.map(link => {
                    return (
                      <Menu.Item key={link.url}>
                        <Link to={link.url}>{link.name[this.props.app.language.code]}</Link>
                      </Menu.Item>
                    );
                  })}
                </Menu>
              </nav>
            </Col>
          </Row>
        )}
      </Header>
    );
  }
}

const mapStateToProps = (state: any) => ({
  app: state.app,
  auth: state.auth,
  cart: state.cart,
  wishlist: state.wishlist
});

const mapDispatchToProps = (dispatch: any) => ({
  logoutUser: () => dispatch(logoutUser()),
  removeItemFromCart: (id: string) => dispatch(removeItemFromCart(id)),
  setCurrency: (currency: Currency) => dispatch(setCurrency(currency)),
  setLanguage: (language: { code: string; name: string }) => dispatch(setLanguage(language))
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withTranslation()(NavbarDefaultComponent))
);
