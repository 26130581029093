import { Country, Currency } from '../types';

export const calculatePrice = (
  price: number,
  currency: Currency | null,
  country: Country | null
) => {
  if (currency) {
    price = country ? price * (1 + country.tax_rate / 100) * currency.rate : price * currency.rate;
  }
  return price;
};

export const formatPrice = (price: number, currency: Currency | null, country: Country | null) => {
  if (currency) {
    price = calculatePrice(price, currency, country);
    return `${currency.symbol} ${price.toFixed(2).replace('.', ',')}`;
  }
  return '-';
};
