import { CartItem, OrderPaymentStatus, Product } from '../types';
import { settings } from '../../settings';

export const gtagUpdateProductRemoval = (item: CartItem, language: string) => {
  // @ts-ignore
  window.gtag('event', 'remove_from_cart', {
    items: [
      {
        id: item.product.id,
        name: `${item.product.name[language]}${
          item.product.subtitle ? ` - ${item.product.subtitle[language]}` : ''
        }`,
        list_name: 'Cart',
        brand: settings.appName,
        category: item.product.product_tye_id,
        variant: item.size.name,
        list_position: 1,
        quantity: item.quantity,
        price: item.product.price + item.size.price
      }
    ]
  });
};

export const gtagUpdateEventRemoval = (item: CartItem, language: string) => {
  // @ts-ignore
  window.gtag('event', 'remove_from_cart', {
    items: [
      {
        id: item.event.id,
        name: `${item.event.name[language]}`,
        list_name: 'Cart',
        brand: settings.appName,
        category: item.event.event_type,
        list_position: 1,
        quantity: item.quantity,
        price: item.event.price
      }
    ]
  });
};

export const gtagProducts = (products: Product[], language: string) => {
  // @ts-ignore
  window.gtag('event', 'view_item_list', {
    items: products.map((product, index) => {
      return [
        {
          id: product.id,
          name: `${product.name[language]}${
            product.subtitle ? ` - ${product.subtitle[language]}` : ''
          }`,
          list_name: 'Webshop',
          brand: settings.appName,
          category: product.product_type_id,
          variant: product.color_id,
          list_position: index + 1,
          price: product.price
        }
      ];
    })
  });
};

export const processGoogleAnalytics = (order: OrderPaymentStatus, cart: any, language: string) => {
  // @ts-ignore
  window.gtag('event', 'purchase', {
    transaction_id: order.transaction_id,
    affiliation: settings.appName,
    value: order.total,
    currency: order.currency,
    tax: order.total - order.subtotal,
    shipping: 0,
    checkout_step: 2,
    coupon: cart.voucher?.voucher.code,
    items: cart.items.map((item: CartItem) => {
      return {
        id: item.product.id,
        name: `${item.product.name[language]}${
          item.product.subtitle ? ` - ${item.product.subtitle[language]}` : ''
        }`,
        list_name: 'Cart',
        brand: settings.appName,
        category: item.product.product_tye_id,
        variant: item.size.name,
        list_position: 1,
        quantity: item.quantity,
        price: item.product.price
      };
    })
  });
};
