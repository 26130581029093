import { Event, Product } from '../types';
import { settings } from '../../settings';
import moment from 'moment';

export const productSchema = (product: Product, currency: string, language: string) => {
  return {
    '@context': 'http://schema.org/',
    '@type': 'Product',
    name: product.name[language],
    category: product.product_type_id,
    productID: product.id,
    sku: product.id,
    image: product.pictures ? process.env.REACT_APP_API_URL + product.pictures[0] : null,
    description: product.description[language],
    brand: settings.appName,
    url: process.env.REACT_APP_URL + settings.products.shoppingPage + product.slug,
    offers: {
      '@type': 'Offer',
      url: process.env.REACT_APP_URL + settings.products.shoppingPage + product.slug,
      priceValidUntil: moment()
        .add(1, 'year')
        .format('YYYY-MM-DD'),
      priceCurrency: currency,
      price: product.price,
      availability:
        product.manage_stock && product.stock === 0
          ? 'http://schema.org/OuOfStock'
          : 'http://schema.org/InStock'
    }
  };
};

export const eventSchema = (event: Event, currency: string, language: string) => {
  return {
    '@context': 'http://schema.org/',
    '@type': 'Product',
    name: event.name[language],
    category: event.location[language],
    eventID: event.id,
    sku: event.id,
    image: event.pictures ? process.env.REACT_APP_API_URL + event.pictures[0] : null,
    description: event.description[language],
    brand: settings.appName,
    url: process.env.REACT_APP_URL + settings.events.rootUrl + event.slug,
    offers: {
      '@type': 'Offer',
      url: process.env.REACT_APP_URL + settings.events.rootUrl + event.slug,
      priceValidUntil: moment()
        .add(1, 'year')
        .format('YYYY-MM-DD'),
      priceCurrency: currency,
      price: event.price,
      availability: 'http://schema.org/InStock'
    }
  };
};
