import React from 'react';
import { Col, Layout, Row, Tabs } from 'antd';
import NavbarComponent from '../navigation/navbar.component';
import { Helmet } from 'react-helmet';
import FooterComponent from '../navigation/footer.component';
import { withRouter } from 'react-router-dom';
import { ICartState } from '../../redux/states/cart';
import { IAppState } from '../../redux/states/app';
import { AuthState, User } from '../../redux/states/user';
import { updateUser } from '../../redux/actions/auth';
import { connect } from 'react-redux';
import { Country, Order } from '../../types';
import { setCountry } from '../../redux/actions/app';
import ChangePasswordFormComponent from './change-password-form.component';
import { WithTranslation, withTranslation } from 'react-i18next';
import QueueAnim from 'rc-queue-anim';
import '../../styles/profile.less';
import { BannerModule } from '../pages/modules/banner.module';
import { settings } from '../../../settings';
import ProfileFormComponent from './profile-form.component';
import SettingsFormComponent from './settings-form.component';
import ProfileOrdersComponent from './profile-orders.component';

const { Content } = Layout;
const { TabPane } = Tabs;

interface Props extends WithTranslation {
  app: IAppState;
  auth: AuthState;
  cart: ICartState;
  history: any;
  updateUser: typeof updateUser;
  setCountry: typeof setCountry;
}

interface State {
  orders: Order[];
}

class ProfileComponent extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      orders: []
    };

    window.scrollTo(0, 0);
  }

  render() {
    const apiSettings = this.props.app.settings;
    const t = this.props.i18n.t.bind(this.props.i18n);

    return (
      <Layout>
        <Helmet>
          <title>{t('auth:profile.meta.title')}</title>
          <meta name='description' content={t('auth:profile.meta.description')} />
          <meta name='robots' content='noindex,nofollow' />
          <link rel='canonical' href={window.location.origin + window.location.pathname} />
          <meta property='og:title' content={t('auth:profile.meta.title')} />
          <meta property='og:description' content={t('auth:profile.meta.description')} />
          <meta property='og:url' content={window.location.origin + window.location.pathname} />
          <meta name='twitter:title' content={t('auth:profile.meta.title')} />
          <meta name='twitter:description' content={t('auth:profile.meta.description')} />
        </Helmet>
        <NavbarComponent />
        <Content
          className={`${apiSettings?.navbar_settings?.type === 'transparent' ? 'no-offset' : ''}`}
        >
          <div className={'content-header with-overlay default-header'}>
            <QueueAnim className={'queue-anim-container'} type={'scale'} duration={1500}>
              <h1>{t('auth:profile.page.title')}</h1>
            </QueueAnim>
          </div>
          <div className={'content-body'}>
            <Row gutter={30} className={'profile-container'}>
              <Col
                xs={{ span: 20, offset: 2, order: 1 }}
                xl={{ span: 10, offset: 4 }}
                xxl={{ span: 12, offset: 6 }}
                style={{ marginBottom: 50 }}
              >
                <Tabs defaultActiveKey='1' tabPosition={'left'}>
                  <TabPane tab={t('auth:profile.section.my_details.tab.title')} key={'1'}>
                    <ProfileFormComponent />
                  </TabPane>
                  <TabPane tab={t('auth:profile.section.order_history.tab.title')} key={'2'}>
                    <h2>{t('auth:profile.section.order_history.title')}</h2>
                    <hr />
                    <ProfileOrdersComponent />
                  </TabPane>
                  <TabPane tab={t('auth:profile.section.my_account.tab.title')} key={'3'}>
                    <h2>{t('auth:profile.section.my_account.title')}</h2>
                    <hr />
                    <ChangePasswordFormComponent history={this.props.history} />
                  </TabPane>
                  <TabPane tab={t('auth:profile.section.settings.tab.title')} key={'4'}>
                    <SettingsFormComponent />
                  </TabPane>
                </Tabs>
              </Col>
            </Row>
          </div>
          {settings.showCompanyBanner && <BannerModule />}
        </Content>
        <FooterComponent />
      </Layout>
    );
  }
}

const mapStateToProps = (state: any) => ({
  app: state.app,
  auth: state.auth,
  cart: state.cart
});

const mapDispatchToProps = (dispatch: any) => ({
  updateUser: (user: User, token: string) => dispatch(updateUser(user, token)),
  setCountry: (country: Country) => dispatch(setCountry(country))
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ProfileComponent))
);
