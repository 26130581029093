import React from 'react';
import { Route, Router, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import { AuthState } from './core-module/redux/states/user';
import { History } from 'history';
import FaqsComponent from './core-module/components/pages/faqs.component';
import BlogComponent from './core-module/components/pages/blog.component';
import ContactComponent from './core-module/components/pages/contact.component';
import DynamicComponent from './core-module/components/pages/dynamic.component';
import BlogArticleComponent from './core-module/components/pages/blog-article.component';
import CartComponent from './core-module/components/pages/cart-full.component';
import LoginComponent from './core-module/components/auth/login.component';
import ThankYouComponent from './core-module/components/pages/thank-you.component';
import WishListComponent from './core-module/components/pages/wishlist.component';
import ProductDetailComponent from './core-module/components/pages/product-detail.component';
import RegisterComponent from './core-module/components/auth/register.component';
import VerifyComponent from './core-module/components/auth/verify.component';
import RegisteredComponent from './core-module/components/auth/registered.component';
import { ConditionedRoute } from './conditioned-route';
import ProfileComponent from './core-module/components/auth/profile.component';
import ChangePasswordComponent from './core-module/components/auth/change-password.component';
import ForgotPasswordComponent from './core-module/components/auth/forgot-password.component';
import ForgotPasswordRequestedComponent from './core-module/components/auth/forgot-password-requested.component';
import ResetPasswordComponent from './core-module/components/auth/reset-password.component';
import NotFoundComponent from './core-module/components/app/404.component';
import PaymentStatusComponent from './core-module/components/pages/payment-status.component';
import ProductsBoxedComponent from './core-module/components/pages/products-boxed.component';
import TimelineComponent from '../src/custom/components/timeline.component';
import EventDetailDefaultComponent from './core-module/components/pages/event-detail-default.component';
import PortfolioComponent from '../src/custom/components/portfolio.component';
import UnsubscribeComponent from './core-module/components/pages/unsubscribe.component';

interface Props {
  history: History<any>;
  auth: AuthState;
}

const PublicRoutes = (props: Props) => {
  return (
    <Router history={props.history}>
      <Switch>
        <Route exact={true} path='/products' component={ProductsBoxedComponent} />
        <Route exact={true} path='/products/:slug' component={ProductDetailComponent} />
        <ConditionedRoute
          exact={true}
          path='/login'
          condition={!props.auth.isLoggedIn}
          alternatePath={'/products'}
          component={LoginComponent}
        />
        <ConditionedRoute
          exact={true}
          path='/forgot-password'
          condition={!props.auth.isLoggedIn}
          alternatePath={'/change-password'}
          component={ForgotPasswordComponent}
        />
        <ConditionedRoute
          exact={true}
          path='/profile'
          condition={props.auth.isLoggedIn}
          alternatePath={'/'}
          component={ProfileComponent}
        />
        <Route
          exact={true}
          path='/forgot-password/requested'
          component={ForgotPasswordRequestedComponent}
        />
        <ConditionedRoute
          exact={true}
          path='/change-password'
          condition={props.auth.isLoggedIn}
          alternatePath={'/reset-password'}
          component={ChangePasswordComponent}
        />
        <Route exact={true} path='/portfolio' component={PortfolioComponent} />
        <Route exact={true} path='/upcoming-events/:slug' component={EventDetailDefaultComponent} />
        <Route exact={true} path='/reset-password' component={ResetPasswordComponent} />
        <Route exact={true} path='/register' component={RegisterComponent} />
        <Route exact={true} path='/upcoming-events' component={TimelineComponent} />
        <Route exact={true} path='/registered' component={RegisteredComponent} />
        <Route exact={true} path='/verify' component={VerifyComponent} />
        <Route exact={true} path='/unsubscribe' component={UnsubscribeComponent} />
        <Route exact={true} path='/faqs' component={FaqsComponent} />
        <Route exact={true} path='/blog' component={BlogComponent} />
        <Route exact={true} path='/contact' component={ContactComponent} />
        <Route exact={true} path='/blog' component={BlogComponent} />
        <Route exact={true} path='/blog/:slug' component={BlogArticleComponent} />
        <Route exact={true} path='/cart' component={CartComponent} />
        <Route exact={true} path='/wishlist' component={WishListComponent} />
        <Route exact={true} path='/payment-status' component={PaymentStatusComponent} />
        <Route exact={true} path='/thank-you' component={ThankYouComponent} />
        <Route exact={true} path='/404' component={NotFoundComponent} status={404} />
        <Route key={window.location.pathname} exact={true} path='*' component={DynamicComponent} />
      </Switch>
    </Router>
  );
};

const mapStateToProps = (state: any) => ({
  auth: state.auth
});

export default connect(mapStateToProps)(PublicRoutes);
