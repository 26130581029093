import { Anchor, Button, Carousel, Col, Row } from 'antd';
import React from 'react';
import { PageSection } from '../../../types';
import { Link } from 'react-router-dom';
import { ArrowRightOutlined } from '@ant-design/icons';
import '../../../styles/modules/slider.less';

interface SliderBlock {
  image: { url: string };
  title: { [key: string]: string };
  button: { text: { [key: string]: string }; url: string };
  subtitle: { [key: string]: string };
  language: string;
}

export const SliderModule = (section: PageSection) => {
  return (
    <Row
      id={section.uid ? section.uid : undefined}
      key={section.id}
      className={'cms-module-slider'}
    >
      <Col xs={24} lg={{ span: 18, offset: 3 }}>
        <Carousel autoplay={true} adaptiveHeight={false} autoplaySpeed={5000}>
          {section.settings.images.map((slide: SliderBlock, index: number) => {
            return (
              <div key={index} className={'slider-slide'}>
                <img
                  alt={`${slide.title[section.language]}`}
                  src={process.env.REACT_APP_API_URL + slide.image.url}
                />
                <div className={'slider-content'}>
                  <Row justify={'center'} align={'middle'}>
                    <Col xs={24} lg={8} className={'slider-title'}>
                      <h2>{slide.title[section.language]}</h2>
                    </Col>
                    <Col xs={12} lg={8} className={'slider-subtitle'}>
                      <p>{slide.subtitle[section.language]}</p>
                    </Col>
                    <Col xs={12} lg={8} className={'slider-button'}>
                      {slide.button && slide.button.url && slide.button.url.includes('#') ? (
                        <Anchor affix={false} offsetTop={50}>
                          <Anchor.Link
                            href={slide.button.url}
                            title={
                              <span>
                                {slide.button.text[section.language]} <ArrowRightOutlined />
                              </span>
                            }
                          />
                        </Anchor>
                      ) : (
                        <Link to={slide.button.url} title={slide.button.text[section.language]}>
                          <Button type={'primary'}>
                            {slide.button.text[section.language]} <ArrowRightOutlined />
                          </Button>
                        </Link>
                      )}
                    </Col>
                  </Row>
                </div>
              </div>
            );
          })}
        </Carousel>
      </Col>
    </Row>
  );
};
