import React from 'react';
import { Button, Col, Form, Input, Layout, Row } from 'antd';
import NavbarComponent from '../navigation/navbar.component';
import { Helmet } from 'react-helmet';
import FooterComponent from '../navigation/footer.component';
import { connect } from 'react-redux';
import { AuthState } from '../../redux/states/user';
import { LoopBack } from '../../redux/api';
import Swal from 'sweetalert2';
import { FormInstance } from 'antd/es/form';
import { IAppState } from '../../redux/states/app';
import QueueAnim from 'rc-queue-anim';
import { WithTranslation, withTranslation } from 'react-i18next';
import '../../styles/register.less';

const { Content } = Layout;

interface Props extends WithTranslation {
  app: IAppState;
  auth: AuthState;
  history: any;
}

interface State {
  loading: boolean;
  showPasswordError: boolean;
}

class ForgotPasswordComponent extends React.Component<Props, State> {
  formRef = React.createRef<FormInstance>();

  constructor(props: Props) {
    super(props);

    this.state = {
      loading: false,
      showPasswordError: false
    };
  }

  componentDidMount(): void {
    window.scrollTo(0, 0);
  }

  onFinish = (values: any) => {
    const t = this.props.i18n.t.bind(this.props.i18n);

    const data = {
      ...values,
      language: this.props.app.language.code
    };

    new LoopBack(this.props.auth.token)
      .post('/users/reset-mail', data)
      .then(res => {
        this.props.history.push('/forgot-password/requested');
      })
      .catch(err => {
        Swal.fire({
          title: t('auth:forgot_password.popup.error.title'),
          html: t('auth:forgot_password.popup.error.description'),
          icon: 'error',
          confirmButtonText: t('auth:forgot_password.popup.error.button.ok')
        });
      });
  };

  render() {
    const settings = this.props.app.settings;
    const t = this.props.i18n.t.bind(this.props.i18n);

    return (
      <Layout>
        <Helmet>
          <title>{t('auth:forgot_password.meta.title')}</title>
          <meta name='description' content={t('auth:forgot_password.meta.description')} />
          <meta name='robots' content='noindex,nofollow' />
          <link rel='canonical' href={window.location.origin + window.location.pathname} />
          <meta property='og:title' content={t('auth:forgot_password.meta.title')} />
          <meta property='og:description' content={t('auth:forgot_password.meta.description')} />
          <meta property='og:url' content={window.location.origin + window.location.pathname} />
          <meta name='twitter:title' content={t('auth:forgot_password.meta.title')} />
          <meta name='twitter:description' content={t('auth:forgot_password.meta.description')} />
        </Helmet>
        <NavbarComponent />
        <Content
          className={`${settings?.navbar_settings?.type === 'transparent' ? 'no-offset' : ''}`}
        >
          <div className={'content-header with-overlay default-header'}>
            <QueueAnim className={'queue-anim-container'} type={'scale'} duration={1500}>
              <h1 key={1}>{t('auth:forgot_password.page.title')}</h1>
            </QueueAnim>
          </div>
          <Row className={'register-container'}>
            <Col xs={{ span: 20, offset: 2 }} xl={{ span: 12, offset: 6 }}>
              <Form
                layout={'vertical'}
                name='register'
                ref={this.formRef}
                onFinish={this.onFinish}
                scrollToFirstError
              >
                <h2>{t('auth:forgot_password.block.title')}</h2>
                <hr />
                <div className={'form-section'}>
                  <Row gutter={10}>
                    <Col xs={24}>
                      <Form.Item
                        name='email'
                        extra={t('auth:forgot_password.form.email.extra')}
                        rules={[
                          { required: true, message: t('auth:forgot_password.form.email.warning') }
                        ]}
                      >
                        <Input
                          type={'email'}
                          placeholder={t('auth:forgot_password.form.email.placeholder')}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </div>

                <Row gutter={10}>
                  <Col xs={24}>
                    <Form.Item>
                      <Button block={true} size={'large'} type='primary' htmlType='submit'>
                        {t('auth:forgot_password.button.text')}
                      </Button>
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>
        </Content>
        <FooterComponent />
      </Layout>
    );
  }
}

const mapStateToProps = (state: any) => ({
  app: state.app,
  auth: state.auth
});

export default connect(mapStateToProps)(withTranslation()(ForgotPasswordComponent));
