export class LoopBack {
  apiURL = process.env.REACT_APP_API_URL;
  token: string | null = null;

  constructor(token?: string) {
    if (token) {
      this.token = token;
    }
  }

  buildRequest(method: string, data?: any) {
    let headers: HeadersInit = {
      'Content-Type': 'application/json',
      Accept: 'application/json'
    };

    if (this.token) {
      headers['Authorization'] = `Bearer ${this.token}`;
    }

    const options: RequestInit = { method: method, headers: headers };
    if (data) {
      options.body = JSON.stringify(data);
    }

    return options;
  }

  doDownload(url: string, options: any) {
    const requestURL = this.apiURL + url;
    return fetch(requestURL, options)
      .then(response => {
        if (response.status && response.status !== 200) {
          throw response;
        }
        return response.blob();
      })
      .then(blob => {
        return blob;
      });
  }

  get(url: string) {
    const options = this.buildRequest('GET');
    const requestURL = this.apiURL + url;
    return fetch(requestURL, options).then((resp: any) => {
      if (resp.ok === false) {
        throw resp;
      }
      return resp.json();
    });
  }

  post(url: string, data: any) {
    const options = this.buildRequest('POST', data);
    const requestURL = this.apiURL + url;
    return fetch(requestURL, options).then((resp: any) => {
      if (resp.ok === false) {
        throw resp;
      }
      return resp.json();
    });
  }

  download(url: string) {
    const options = this.buildRequest('GET');
    return this.doDownload(url, options);
  }

  patch(url: string, data: any) {
    const options = this.buildRequest('PATCH', data);
    const requestURL = this.apiURL + url;
    return fetch(requestURL, options).then((resp: any) => resp.json());
  }

  delete(url: string) {
    const options = this.buildRequest('DELETE');
    const requestURL = this.apiURL + url;
    return fetch(requestURL, options).then((resp: any) => resp);
  }
}
