import React, { Component } from 'react';
import { Button, Col, Modal, Result, Row } from 'antd';
import { HeartOutlined } from '@ant-design/icons';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { WithTranslation, withTranslation } from 'react-i18next';
import { AuthState } from '../../redux/states/user';
import { IAppState } from '../../redux/states/app';
import { removeItemFromWishList } from '../../redux/actions/wishlist';
import { ICartState } from '../../redux/states/cart';
import { IWishListState } from '../../redux/states/wishlist';
import { Product, ProductCategory, ProductType } from '../../types';
import Swal from 'sweetalert2';
import { formatPrice } from '../../helpers/price.helper';
import { settings } from '../../../settings';
import { LoopBack } from '../../redux/api';

interface Props extends WithTranslation {
  history: any;
  app: IAppState;
  auth: AuthState;
  cart: ICartState;
  removeItemFromWishList: typeof removeItemFromWishList;
  wishlist: IWishListState;
  hidePopup: () => void;
  visible: boolean;
}

interface State {
  productTypes: ProductType[];
  productCategories: ProductCategory[];
}

class WishlistModal extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      productTypes: [],
      productCategories: []
    };

    new LoopBack()
      .get('/product-categories')
      .then((res: ProductCategory[]) => {
        this.setState({ productCategories: res });
      })
      .catch(err => {
        this.setState({ productCategories: [] });
      });

    new LoopBack()
      .get('/product-types')
      .then(res => {
        this.setState({ productTypes: res });
      })
      .catch(err => {
        this.setState({ productTypes: [] });
      });
  }

  handleRemoveItemClick = (product: Product) => {
    const t = this.props.i18n.t.bind(this.props.i18n);
    this.props.removeItemFromWishList(product.id);
    Swal.fire({
      title: t('pages:wishlist.popup.success.title'),
      html: t('pages:wishlist.popup.success.description', {
        text: `<strong>${product.name[this.props.app.language.code]}</strong>`
      }),
      icon: 'success'
    });
  };

  getProductCategory = (product: Product) => {
    const categoryIndex = this.state.productCategories.findIndex(
      type => product.product_category_id === type.id
    );
    if (categoryIndex > -1) {
      return this.state.productCategories[categoryIndex].name[this.props.app.language.code];
    }
    return '-';
  };

  getProductType = (product: Product) => {
    const typeIndex = this.state.productTypes.findIndex(
      type => product.product_type_id === type.id
    );
    if (typeIndex > -1) {
      return this.state.productTypes[typeIndex].name[this.props.app.language.code];
    }
    return '-';
  };

  onProductClick = (product: Product) => {
    this.props.hidePopup();
    this.props.history.push(`${settings.products.shoppingPage}${product.slug}`);
  };

  render() {
    const wishListItems = this.props.wishlist.items;
    const t = this.props.i18n.t.bind(this.props.i18n);
    const productType = settings.products.style;

    return (
      <Modal
        visible={this.props.visible}
        onCancel={() => {
          this.props.hidePopup();
        }}
        onOk={() => {
          this.props.hidePopup();
        }}
        width={window.outerWidth < 1420 ? '90%' : '70%'}
        footer={[
          <Button
            key='Close'
            onClick={() => {
              this.props.hidePopup();
            }}
          >
            Close
          </Button>
        ]}
      >
        <Row id={'wishlist-modal'} className={'products-container'}>
          <Col xs={24}>
            {wishListItems.length > 0 ? (
              <Row>
                <h2>{t('pages:wishlist.title')}</h2>
                <hr />
                <Col xs={24}>
                  <Row gutter={[20, 20]}>
                    {wishListItems.map((product, pindex) => {
                      const image =
                        product.pictures && product.pictures.length > 0
                          ? product.pictures[0]
                          : null;
                      return (
                        <Col
                          key={pindex}
                          xs={24}
                          sm={12}
                          lg={8}
                          className={productType === 'boxed' ? 'product-boxed-col' : 'product-col'}
                        >
                          <div className={'product'}>
                            <Row className={'product-image'}>
                              <Col xs={24}>
                                <Link to={`${settings.products.shoppingPage}${product.slug}`}>
                                  {image && (
                                    <img
                                      alt={product.summary[this.props.app.language.code]}
                                      src={process.env.REACT_APP_API_URL + image}
                                    />
                                  )}
                                </Link>
                              </Col>
                            </Row>
                            <div className={'product-details-container'}>
                              <Row>
                                <Col xs={24}>
                                  <div className={'product-details'}>
                                    <div style={{ float: 'left' }}>
                                      {product.name[this.props.app.language.code]}
                                    </div>

                                    <div
                                      className={'product-price'}
                                      style={{ float: 'right' }}
                                      dangerouslySetInnerHTML={{
                                        __html: formatPrice(
                                          product.price,
                                          this.props.app.currency,
                                          this.props.app.country
                                        )
                                      }}
                                    />
                                  </div>
                                </Col>
                              </Row>
                              <Row>
                                <Col xs={24} className={'product-actions'}>
                                  <span className={'product-actions-category-label'}>
                                    {this.getProductCategory(product)} -&nbsp;
                                  </span>
                                  {this.getProductType(product)}
                                  <div>
                                    <Button
                                      onClick={() => this.onProductClick(product)}
                                      block={window.innerWidth < 968}
                                      style={{ float: 'left' }}
                                      type={'primary'}
                                    >
                                      {t('pages:wishlist.button.view.text')}
                                    </Button>
                                    <Button
                                      block={window.innerWidth < 968}
                                      onClick={() => this.handleRemoveItemClick(product)}
                                      type={'dashed'}
                                    >
                                      {t('pages:wishlist.button.remove.text')}
                                    </Button>
                                  </div>
                                </Col>
                              </Row>
                            </div>
                          </div>
                        </Col>
                      );
                    })}
                  </Row>
                </Col>
              </Row>
            ) : (
              <Row align={'middle'} justify={'center'} className={'no-result-container'}>
                <Result
                  icon={<HeartOutlined />}
                  status='info'
                  title={t('pages:wishlist.no_items.title')}
                  subTitle={t('pages:wishlist.no_items.subtitle')}
                  extra={
                    settings.navbar.wishlist.style !== 'popup' && (
                      <Link to={settings.products.shoppingPage}>
                        <Button size={'large'} type='primary' key='console'>
                          {t('pages:wishlist.no_items.button.go_shopping.text')}
                        </Button>
                      </Link>
                    )
                  }
                />
              </Row>
            )}
          </Col>
        </Row>
      </Modal>
    );
  }
}

const mapStateToProps = (state: any) => ({
  app: state.app,
  auth: state.auth,
  wishlist: state.wishlist,
  cart: state.cart
});

const mapDispatchToProps = (dispatch: any) => ({
  removeItemFromWishList: (id: number) => dispatch(removeItemFromWishList(id))
});

export default withRouter(
  connect<any, any, any>(mapStateToProps, mapDispatchToProps)(withTranslation()(WishlistModal))
);
