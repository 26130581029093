import { Button, Col, Row } from 'antd';
import React from 'react';
import { PageSection } from '../../../types';
import { Link } from 'react-router-dom';
import { RightOutlined } from '@ant-design/icons';
import { Parallax } from 'react-parallax';
import '../../../styles/modules/column-layout.less';

interface Column {
  width: number;
  content: string;
  background: { image: string; color: string };
  content_position: string;
}

export const ColumnLayoutModule = (section: PageSection) => {
  const getResponsive = (column: Column, length: number) => {
    const hasThreeColumns = length === 3;

    switch (column.width) {
      case 16:
        return { xs: 24, sm: hasThreeColumns ? 8 : 24, md: 4 };
      case 20:
        return { xs: 24, sm: hasThreeColumns ? 8 : 24, md: 5 };
      case 25:
        return { xs: 24, sm: hasThreeColumns ? 8 : 24, md: 6 };
      case 33:
        return { xs: 24, sm: hasThreeColumns ? 8 : 24, md: 8 };
      case 50:
        return { xs: 24, sm: hasThreeColumns ? 8 : 24, md: 12 };
      case 66:
        return { xs: 24, sm: hasThreeColumns ? 8 : 24, md: 16 };
      case 75:
        return { xs: 24, sm: hasThreeColumns ? 8 : 24, md: 18 };
    }
    return { xs: 24, sm: hasThreeColumns ? 8 : 24 };
  };

  const getFluid = (fluidType: string, position: string) => {
    let fluidImage = 'fluid-decoration-small-br-t-tl.png';
    if (fluidType === 'br-t-tl' || (window.innerWidth < 768 && position === 'top')) {
      fluidImage = 'fluid-decoration-small-br-t-tl.png';
    } else if (fluidType === 'bl-t-tr') {
      fluidImage = 'fluid-decoration-small-bl-t-tr.png';
    } else if (fluidType === 'tl-t-br') {
      fluidImage = 'fluid-decoration-small-tl-t-br.png';
    } else if (fluidType === 'tr-t-bl') {
      fluidImage = 'fluid-decoration-small-tr-t-bl.png';
    }

    return (
      <img
        className={`fluid-decoration-element ${position}`}
        alt={'Fluid decorative design element'}
        src={require(`../../../../assets/${fluidImage}`).default}
      />
    );
  };

  let style: any = {};
  let bgClass = null;
  if (section.settings.background_image) {
    bgClass = ' has-bg-image';
  }
  if (section.settings.background_color) {
    Object.assign(style, { backgroundColor: section.settings.background_color });
  }

  const getRow = (bgClass: string | null) => {
    return (
      <Row
        align={section.settings.align_to_top ? 'top' : 'middle'}
        id={section.uid ? section.uid : undefined}
        key={section.id}
        justify={'center'}
        className={`cms-module-column-layout spacing-${section.settings.spacing}${
          bgClass ? bgClass : ''
        }`}
        style={style}
      >
        {section.settings.top_fluid && getFluid(section.settings.top_fluid_type, 'top')}

        {section.settings.title &&
          Object.keys(section.settings.title).length > 0 &&
          section.settings.title[section.language] !== '' && (
            <Col xs={24}>
              {section.settings.title && (
                <>
                  {section.settings.title &&
                    Object.keys(section.settings.title).length > 0 &&
                    section.settings.title[section.language] !== '' && (
                      <h2 className={'cms-module-column-layout-title'}>
                        {section.settings.title[section.language]}
                      </h2>
                    )}
                  {section.settings.subtitle &&
                    Object.keys(section.settings.subtitle).length > 0 &&
                    section.settings.subtitle[section.language] !== '' && (
                      <p
                        className={'cms-module-column-layout-subtitle'}
                        dangerouslySetInnerHTML={{
                          __html: section.settings.subtitle[section.language]
                        }}
                      />
                    )}
                </>
              )}
            </Col>
          )}

        {section.settings.columns.map((column: any, index: number) => {
          return (
            <Col
              key={index}
              order={
                window.innerWidth < 991 && column.column.switch_order_on_mobile ? index + 1 : 0
              }
              {...getResponsive(column.column, section.settings.columns.length)}
              style={{
                backgroundColor: column.column.background_color
              }}
            >
              {column.column.background && column.column.background.image && (
                <img
                  alt={column.column?.title}
                  src={`${process.env.REACT_APP_API_URL + column.column.background.image}`}
                />
              )}
              <div
                className={'cms-module-column-layout-content'}
                dangerouslySetInnerHTML={{
                  __html: column.column.content ? column.column.content[section.language] : ''
                }}
              />

              {column.column.button && column.column.button.url && (
                <Link to={column.column.button.url}>
                  <Button size={'large'} type={'primary'}>
                    {column.column.button.text[section.language]} <RightOutlined />
                  </Button>
                </Link>
              )}
            </Col>
          );
        })}

        {section.settings.bottom_fluid && getFluid(section.settings.bottom_fluid_type, 'bottom')}
      </Row>
    );
  };

  return (
    <>
      {section.settings.background_image ? (
        <Parallax
          bgImage={`${process.env.REACT_APP_API_URL + section.settings.background_image}`}
          bgImageAlt=''
          strength={200}
        >
          {getRow(bgClass)}
        </Parallax>
      ) : (
        getRow(bgClass)
      )}
    </>
  );
};
