import { settingsType } from './core-module/settings';

export const settings: settingsType = {
  cookies: { cookiePolicyUrl: '/cookie-policy', enableLearnMoreButton: true }, parallaxStrength: 200,
  parallaxInHeader: true,
  showBreadcrumbs: false,
  enableWishlist: true,
  paymentMethod: 'paypal',
  appName: 'Blank Canvas Community',
  reduxStoreKey: 'bcc_v1.1',
  languages: [
    {
      name: 'English',
      code: 'en',
      flag: 'english.png'
    }
  ],
  navbar: {
    type: 'default',
    languageCurrencyPlacement: 'next-to-logo',
    linksStyle: 'default',
    shoppingBag: {
      popupStyle: 'hover',
      listStyle: 'default',
      icon: 'bag',
      customIcon: false
    },
    wishlist: {
      style: 'page',
      customIcon: false
    },
    userAccount: {
      style: 'page',
      enable: false,
      customIcon: false
    },
    hamburgerBreakpoint: 500,
    search: {
      enable: false,
      customIcon: false
    },
  },
  cart: {
    checkoutFields: ['first_name', 'last_name', 'country', 'email'],
    showVoucherInShoppinglist: true,
    showBanner: false,
    style: 'full',
    enableDifferentDeliveryAddress: false,
    showGoHomeShoppingCartButton: true,
    paymentURL: '/orders',
    enableVoucher: true,
    showHelpText: true,
    showTax: false,
    enableDonationOption: false,
  },
  contact: {
    mapCenter: [24.461530, 54.357120],
    mapLocation: [24.461530, 54.357120],
    style: 'default',
    showMap: true,
    showDecoration: false
  },
  faqs: {
    showDecoration: false
  },
  products: {
    fewLeftLimit: 5,
    style: 'boxed',
    shoppingPage: '/upcoming-events',
    layoutOffset: false,
    navbarAnimated: false,
    filter: {
      showCategories: true,
      showUnitSwitch: true,
    },
    customWishlistIcon: false
  },
  events: {
    sessions: {
      enable: false,
    },
    rootUrl: '/upcoming-events',
    partialPartipation: {
      enable: true,
      donation: {
        enable: true,
        condition: {
          type: 'sessions',
          quantity: 3,
        },
        donationOptions: [50,100,150,200],
        donationOptionsCurrency: 'AED',
      }
    }
  },
  thankYou: {
    showFollowUsBanner: false,
  },
  productCategories: {
    rootUrl: '/product-categories'
  },
  footer: 'default',
  showDefaultHeader: true,
  showCompanyBanner: false,
  addClassToHeaderOnScroll: 50,
  expandAllFAQS: false,
  useImageBackground: false
};
