import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Dropdown, Input } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { AuthState } from '../../redux/states/user';
import { Link, withRouter } from 'react-router-dom';
import { ICartState } from '../../redux/states/cart';
import { IAppState } from '../../redux/states/app';
import { withTranslation, WithTranslation } from 'react-i18next';
import { settings } from '../../../settings';
import { LoopBack } from '../../redux/api';
import { Product } from '../../types';
import { formatPrice } from '../../helpers/price.helper';

interface Props extends WithTranslation {
  history: any;
  auth: AuthState;
  cart: ICartState;
  app: IAppState;
}

interface State {
  data: Product[];
  value: any;
  visible: boolean;
  searching: boolean;
}

class ProductSearchComponent extends Component<Props, State> {
  timeout: any;
  currentValue: any;

  constructor(props: Props) {
    super(props);

    this.state = {
      data: [],
      value: undefined,
      visible: false,
      searching: false
    };
  }

  getProducts = (value: string) => {
    const lang = this.props.app.language.code;
    new LoopBack()
      .get(`/products`)
      .then(res => {
        const filtered = res.filter((product: Product) =>
          product.name[lang].toLowerCase().includes(value)
        );
        this.setState({ data: filtered, searching: false });
      })
      .catch(res => {
        this.setState({ searching: false, data: [], value: undefined });
      });
  };

  fetch(value: any) {
    if (this.timeout) {
      clearTimeout(this.timeout);
      this.timeout = null;
    }
    this.currentValue = value;
    this.timeout = setTimeout(() => this.getProducts(value.toLowerCase()), 300);
  }
  //
  // handleChange = (value: any) => {
  //   this.setState({ value: value });
  // };

  handleSearch = (value: string) => {
    if (value !== this.state.value) {
      this.setState({ value: value, searching: true });
      if (value) {
        this.fetch(value);
      } else {
        this.setState({ data: [], searching: false, value: undefined });
      }
    }
  };

  getContent = () => {
    const t = this.props.i18n.t.bind(this.props.i18n);

    if (this.state.searching) {
      return (
        <span>
          <em>{t('app:navbar.products_search.searching')}</em>
        </span>
      );
    } else if (this.state.value === '' || this.state.value === undefined) {
      return (
        <span>
          <em>{t('app:navbar.products_search.enter_value')}</em>
        </span>
      );
    } else {
      if (this.state.data.length > 0) {
        return this.state.data.map((product, key) => {
          return (
            <div key={key} className={'product-search-box-product'}>
              <img
                alt={product.name[this.props.app.language.code]}
                src={product.pictures ? process.env.REACT_APP_API_URL + product.pictures[0] : ''}
              />
              <div
                className={'product-search-box-name'}
                onClick={() => this.setState({ visible: false })}
              >
                <Link to={settings.products.shoppingPage + product.slug}>
                  {product.name[this.props.app.language.code]}
                </Link>

                <div
                  className={'product-search-box-price'}
                  dangerouslySetInnerHTML={{
                    __html: formatPrice(
                      product.price,
                      this.props.app.currency,
                      this.props.app.country
                    )
                  }}
                />
              </div>
            </div>
          );
        });
      } else {
        return <span>{t('app:navbar.products_search.no_results')}</span>;
      }
    }
  };

  searchBox = () => {
    return (
      <div id={'product-search-box'}>
        <Input autoFocus={true} onChange={value => this.handleSearch(value.target.value)} />
        <hr />
        <div id={'product-search-box-list'}>{this.getContent()}</div>
      </div>
    );
  };

  handleVisibleChange = (flag: boolean) => {
    this.setState({ visible: flag });
  };

  render() {
    const customIcon = settings.navbar.search.customIcon;

    return window.innerWidth < 768 ? (
      <span id={'shopping-cart'}>
        <Dropdown
          onVisibleChange={this.handleVisibleChange}
          trigger={['click']}
          visible={this.state.visible}
          overlay={this.searchBox}
        >
          {customIcon ? (
            <img
              alt={'shopping cart icon'}
              src={require(`../../../assets/${customIcon}`).default}
            />
          ) : (
            <SearchOutlined />
          )}
        </Dropdown>
      </span>
    ) : (
      <Dropdown
        onVisibleChange={this.handleVisibleChange}
        trigger={['click']}
        visible={this.state.visible}
        overlay={this.searchBox}
      >
        {customIcon ? (
          <img alt={'shopping cart icon'} src={require(`../../../assets/${customIcon}`).default} />
        ) : (
          <SearchOutlined />
        )}
      </Dropdown>
    );
  }
}

const mapDispatchToProps = (dispatch: any) => ({});

const mapStateToProps = (state: any) => ({
  app: state.app,
  auth: state.auth,
  cart: state.cart,
  wishlist: state.wishlist
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ProductSearchComponent))
);
