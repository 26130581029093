import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { withRouter } from 'react-router-dom';
import { Button, Col, Form, Input, Layout, Row, Select } from 'antd';
import NavbarComponent from '../navigation/navbar.component';
import FooterComponent from '../navigation/footer.component';
import { FormInstance } from 'antd/lib/form';
import { IAppState } from '../../redux/states/app';
import { connect } from 'react-redux';
import { LoopBack } from '../../redux/api';
import Swal from 'sweetalert2';
import { RightOutlined } from '@ant-design/icons';
import { WithTranslation, withTranslation } from 'react-i18next';
import '../../styles/contact-basic.less';
import { ICartState } from '../../redux/states/cart';
import { settings } from '../../../settings';
import HeaderModule from './modules/header.module';

const { TextArea } = Input;
const Option = Select.Option;
const { Content } = Layout;

interface Props extends WithTranslation {
  history: any;
  cart: ICartState;
  app: IAppState;
}

const ContactBasicComponent = (props: Props) => {
  const formRef = React.createRef<FormInstance>();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleSubmit = () => {
    const t = props.i18n.t.bind(props.i18n);
    if (formRef && formRef.current) {
      const formFields = formRef.current;
      const data = {
        ...formFields.getFieldsValue(),
        language: props.app.language.code
      };
      formFields.validateFields().then(res => {
        new LoopBack()
          .post('/contact', data)
          .then(res => {
            Swal.fire({
              title: t('pages:contact.popup.success.title'),
              html: t('pages:contact.popup.success.description'),
              icon: 'success'
            });
            formFields.resetFields();
          })
          .catch(err => {
            Swal.fire({
              title: t('pages:contact.popup.error.title'),
              html: t('pages:contact.popup.error.description'),
              icon: 'error'
            });
          });
      });
    }
  };

  const t = props.i18n.t.bind(props.i18n);
  const voucher = props.cart.voucher;

  return (
    <Layout>
      <Helmet>
        <title>{t('pages:contact.meta.title')}</title>
        <meta name='description' content={t('pages:contact.meta.description')} />
        <link rel='canonical' href={window.location.origin + window.location.pathname} />
        <meta property='og:title' content={t('pages:contact.meta.title')} />
        <meta property='og:description' content={t('pages:contact.meta.description')} />
        <meta property='og:url' content={window.location.origin + window.location.pathname} />
        <meta name='twitter:title' content={t('pages:contact.meta.title')} />
        <meta name='twitter:description' content={t('pages:contact.meta.description')} />
      </Helmet>
      <NavbarComponent />
      <Content className={voucher && voucher.exists && voucher.voucher ? 'voucher-applied' : ''}>
        <HeaderModule
          section={{
            settings: {
              title: { en: t('pages:contact.page.title') },
              subtitle: {},
              size: 'small',
              parallax: settings.parallaxInHeader,
              images: [require('../../../assets/header-main.jpg').default],
              background_image: { non_api: true }
            },
            type: 'header',
            id: 'header',
            language: props.app.language.code,
            uid: 'header'
          }}
        />
        <div className={'content-body'}>
          <Row className={'contact-basic-container'}>
            <Col xs={{ span: 22, offset: 1 }} sm={{ span: 18, offset: 3 }}>
              <Row>
                <Col xs={24}>
                  <Form name='basic' ref={formRef} onFinish={handleSubmit}>
                    <Row gutter={20}>
                      <Col xs={0} md={12}>
                        {!props.app.loading ? (
                          <Row gutter={[20, 20]}>
                            {props.app.settings &&
                              props.app.settings.addresses.map((address, aindex) => {
                                return (
                                  <Col key={aindex} xs={24}>
                                    <div className={'contact-address'}>
                                      <p>
                                        <strong>{address.title}</strong>
                                      </p>
                                      <p>
                                        {address.address}
                                        <br />
                                        {`${address.postal_code} ${address.city}, ${address.country}`}
                                        <br />
                                        <br />
                                        {address.phone && (
                                          <>
                                            <span className='theme-color'>
                                              {t('pages:contact.contact_details.phone_symbol')}
                                            </span>{' '}
                                            {address.phone}
                                            <br />
                                          </>
                                        )}
                                        <span className='theme-color'>
                                          {t('pages:contact.contact_details.email_symbol')}
                                        </span>
                                        &nbsp;
                                        <a href={`mailto:${address.email}`}>{address.email}</a>
                                      </p>
                                      <p>
                                        <span className='theme-color'>
                                          {address.business_registration.label}
                                        </span>{' '}
                                        {address.business_registration.value}
                                        <br />
                                        <span className='theme-color'>
                                          {address.tax_details.label}
                                        </span>{' '}
                                        {address.tax_details.value}
                                      </p>
                                    </div>
                                  </Col>
                                );
                              })}
                          </Row>
                        ) : (
                          <Row>
                            <Col xs={24} sm={8} md={12}>
                              <p>{t('pages:contact.contact_details.loading.text')}</p>
                            </Col>
                          </Row>
                        )}
                      </Col>

                      <Col xs={24} md={12}>
                        <h2>{t('pages:contact.block.title')}</h2>

                        <Form.Item
                          name={'name'}
                          rules={[
                            { required: true, message: t('pages:contact.form.name.warning') }
                          ]}
                          extra={t('pages:contact.form.name.extra')}
                        >
                          <Input placeholder={t('pages:contact.form.name.placeholder')} />
                        </Form.Item>
                        <Form.Item
                          name={'email'}
                          rules={[
                            { required: true, message: t('pages:contact.form.email.warning') }
                          ]}
                          extra={t('pages:contact.form.email.extra')}
                        >
                          <Input
                            type='email'
                            placeholder={t('pages:contact.form.email.placeholder')}
                          />
                        </Form.Item>
                        <Form.Item
                          name={'subject'}
                          rules={[
                            { required: true, message: t('pages:contact.form.subject.warning') }
                          ]}
                        >
                          <Select placeholder={t('pages:contact.form.subject.placeholder')}>
                            <Option value={'Placing an order'}>
                              {t('pages:contact.form.subject.option.placing_order')}
                            </Option>
                            <Option value={'Payment'}>
                              {t('pages:contact.form.subject.option.payment')}
                            </Option>
                            <Option value={'Enquiry'}>
                              {t('pages:contact.form.subject.option.enquiry')}
                            </Option>
                            <Option value={'Other'}>
                              {t('pages:contact.form.subject.option.other')}
                            </Option>
                          </Select>
                        </Form.Item>
                        <Form.Item
                          name={'content'}
                          rules={[
                            { required: true, message: t('pages:contact.form.content.warning') }
                          ]}
                        >
                          <TextArea
                            rows={7}
                            placeholder={t('pages:contact.form.content.placeholder')}
                          />
                        </Form.Item>

                        <Button
                          icon={<RightOutlined />}
                          size={'large'}
                          type='primary'
                          htmlType='submit'
                        >
                          <span>{t('pages:contact.form.button.submit.text')}</span>
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </Content>
      <FooterComponent />
    </Layout>
  );
};

const mapStateToProps = (state: any) => ({
  app: state.app,
  cart: state.cart
});

export default withRouter(connect(mapStateToProps)(withTranslation()(ContactBasicComponent)));
