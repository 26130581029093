import React from 'react';
import { Button, Col, Layout, Result, Row } from 'antd';
import NavbarComponent from '../navigation/navbar.component';
import { Helmet } from 'react-helmet';
import FooterComponent from '../navigation/footer.component';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { ICartState } from '../../redux/states/cart';
import { ShoppingCartOutlined } from '@ant-design/icons';
import { IAppState } from '../../redux/states/app';
import { AuthState } from '../../redux/states/user';
import LoginComponent from './cart/login.component';
import ForgotPasswordComponent from './cart/forgot-password.component';
import ShoppingListComponent from './cart/shopping-list.component';
import { WithTranslation, withTranslation } from 'react-i18next';
import { BreadCrumbItem } from '../../types';
import { settings } from '../../../settings';
import CheckoutComponent from './cart/checkout.component';
import { BannerModule } from './modules/banner.module';
import '../../styles/cart.less';
import HeaderModule from './modules/header.module';

const { Content } = Layout;

interface Props extends WithTranslation {
  app: IAppState;
  auth: AuthState;
  cart: ICartState;
  history: any;
}

interface State {
  mode: string;
  deliveryType: string;
}

class CartFullComponent extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      mode: 'guest',
      deliveryType: 'standard'
    };

    // @ts-ignore
    window.gtag('event', 'begin_checkout', {
      items: this.props.cart.items.map(item => {
        return {
          id: item.product.id,
          name: `${item.product.name[this.props.app.language.code]}${
            item.product.subtitle ? ` - ${item.product.subtitle[this.props.app.language.code]}` : ''
          }`,
          list_name: 'Cart',
          brand: settings.appName,
          category: item.product.product_tye_id,
          variant: item.size.name,
          list_position: 1,
          quantity: item.quantity,
          price: item.product.price
        };
      }),
      coupon: this.props.cart.voucher?.voucher.code
    });

    // @ts-ignore
    window.gtag('event', 'set_checkout_option', {
      checkout_step: 1,
      checkout_option: 'My Details'
    });

    window.scrollTo(0, 0);
  }

  updateMode = (mode: string) => {
    this.setState({ mode: mode });
  };

  setDeliveryType = (type: string) => {
    this.setState({ deliveryType: type });
  };

  cartContainsItems = () => {
    return (
      this.props.cart.items.length > 0 ||
      this.props.cart.events.length > 0 ||
      (this.props.cart.giftCards && this.props.cart.giftCards.length > 0)
    );
  };

  switchForm = () => {
    if (this.state.mode === 'user' && !this.props.auth.isLoggedIn) {
      return (
        <LoginComponent
          history={this.props.history}
          mode={this.state.mode}
          updateMode={this.updateMode}
        />
      );
    } else if (this.state.mode === 'forgot_password') {
      return (
        <ForgotPasswordComponent
          history={this.props.history}
          mode={this.state.mode}
          updateMode={this.updateMode}
        />
      );
    }
    return (
      <CheckoutComponent
        history={this.props.history}
        mode={this.state.mode}
        updateMode={this.updateMode}
        deliveryType={this.state.deliveryType}
      />
    );
  };

  render() {
    const tailLayout = {
      wrapperCol: { xs: { offset: 0, span: 18 }, sm: { offset: 6, span: 18 } }
    };

    const titleLayout = { ...tailLayout.wrapperCol };
    titleLayout.xs = { offset: 0, span: 20 };
    const apiSettings = this.props.app.settings;
    const t = this.props.i18n.t.bind(this.props.i18n);

    const breadcrumbs: BreadCrumbItem[] = [
      { title: t('app:breadcrumbs.home'), link: '/' },
      { title: t('app:breadcrumbs.cart'), link: null }
    ];

    return (
      <Layout>
        <Helmet>
          <title>{t('pages:cart.meta.title')}</title>
          <meta name='robots' content='noindex,nofollow' />
          <meta name='description' content={t('pages:cart.meta.description')} />
          <script>window.prerenderReady = false;</script>
          <link rel='canonical' href={window.location.origin + window.location.pathname} />
          <meta property='og:title' content={t('pages:cart.meta.title')} />
          <meta property='og:description' content={t('pages:cart.meta.description')} />
          <meta property='og:url' content={window.location.origin + window.location.pathname} />
          <meta name='twitter:title' content={t('pages:cart.meta.title')} />
          <meta name='twitter:description' content={t('pages:cart.meta.description')} />
        </Helmet>
        <NavbarComponent />
        <Content
          className={`${apiSettings?.navbar_settings?.type === 'transparent' ? 'no-offset' : ''}`}
        >
          {settings.showDefaultHeader && (
            <HeaderModule
              section={{
                settings: {
                  title: { en: t('pages:cart.page.title') },
                  subtitle: {},
                  size: 'small',
                  parallax: settings.parallaxInHeader,
                  images: [require('../../../assets/header-main.jpg').default],
                  background_image: { non_api: true }
                },
                type: 'header',
                id: 'header',
                language: this.props.app.language.code,
                uid: 'header'
              }}
            />
          )}

          <div className={'content-body'}>
            {this.cartContainsItems() ? (
              <Row gutter={{ xs: 0, md: 30 }} className={'cart-container'}>
                <Col
                  xs={{ span: 20, offset: 2, order: 1 }}
                  md={{ span: 10, offset: 2, order: 0 }}
                  xl={{ span: 10, offset: 4 }}
                  xxl={{ span: 10, offset: 4 }}
                  style={{ marginBottom: 50 }}
                >
                  {this.switchForm()}
                </Col>
                <Col
                  xs={{ span: 20, offset: 2, order: 0 }}
                  md={{ span: 10, offset: 0, order: 1 }}
                  xl={{ span: 8, offset: 0 }}
                  xxl={{ span: 6, offset: 0 }}
                  style={{ marginBottom: 50 }}
                >
                  <ShoppingListComponent
                    forceVoucherView={false}
                    deliveryType={this.state.deliveryType}
                    onDeliveryTypeChange={this.setDeliveryType}
                  />
                </Col>
              </Row>
            ) : (
              <Row className={'cart-container'} align={'middle'} justify={'center'}>
                <Result
                  style={{ marginBottom: 50 }}
                  icon={<ShoppingCartOutlined />}
                  status='info'
                  title={t('pages:cart.no_products.title')}
                  extra={
                    <Link to={settings.products.shoppingPage}>
                      <Button size={'large'} type='primary' key='console'>
                        {t('pages:cart.no_products.button.go_shopping.text')}
                      </Button>
                    </Link>
                  }
                />
              </Row>
            )}
          </div>

          {settings.showCompanyBanner && !this.cartContainsItems() && <BannerModule />}
        </Content>
        <FooterComponent />
      </Layout>
    );
  }
}

const mapStateToProps = (state: any) => ({
  app: state.app,
  auth: state.auth,
  cart: state.cart
});

export default withRouter(connect(mapStateToProps)(withTranslation()(CartFullComponent)));
