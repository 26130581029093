import React from 'react';
import { Button, Checkbox, Col, Form, Input, Row } from 'antd';
import { connect } from 'react-redux';
import { ICartState } from '../../../redux/states/cart';
import { IAppState } from '../../../redux/states/app';
import { FormInstance } from 'antd/lib/form';
import { ArrowLeftOutlined, ArrowRightOutlined, LoginOutlined } from '@ant-design/icons';
import { userLoginFetch } from '../../../redux/actions/auth';
import { AuthState } from '../../../redux/states/user';
import { Country, Currency } from '../../../types';
import { WithTranslation, withTranslation } from 'react-i18next';
import { TFunction } from 'i18next';

interface Props extends WithTranslation {
  history: any;
  auth: AuthState;
  userLoginFetch: typeof userLoginFetch;
  app: IAppState;
  cart: ICartState;
  updateMode: (mode: string) => void;
  mode: string;
}

class LoginComponent extends React.Component<Props> {
  formRef = React.createRef<FormInstance>();

  onFinish = (values: any) => {
    const t = this.props.i18n.t.bind(this.props.i18n);
    this.props.userLoginFetch(values, this.props.app.countries, this.props.app.currencies, t);
  };

  render() {
    const layout = {
      labelCol: { span: 6 },
      wrapperCol: { span: 18 }
    };
    const tailLayout = {
      wrapperCol: { xs: { offset: 0, span: 18 }, sm: { offset: 6, span: 18 } }
    };

    const titleLayout = { ...tailLayout.wrapperCol };
    titleLayout.xs = { offset: 0, span: 20 };
    const t = this.props.i18n.t.bind(this.props.i18n);

    return (
      <Row>
        <Col xs={24}>
          <Form
            {...layout}
            name='basic'
            initialValues={{ remember: true }}
            onFinish={this.onFinish}
          >
            <div className={'form-section'}>
              <h2 style={{ marginTop: 20 }}>{t('cart:login.title')}</h2>
              <Button
                type={'link'}
                style={{ float: 'right', marginTop: 20 }}
                onClick={() => this.props.updateMode('guest')}
              >
                <ArrowLeftOutlined /> {t('cart:login.button.checkout_as_guest.text')}
              </Button>
              <hr />

              <Form.Item
                label={t('cart:login.form.email.label')}
                name='email'
                rules={[{ required: true, message: t('cart:login.form.email.warning') }]}
              >
                <Input size={'large'} placeholder={t('cart:login.form.email.placeholder')} />
              </Form.Item>

              <Form.Item
                label={t('cart:login.form.password.label')}
                name='password'
                rules={[{ required: true, message: t('cart:login.form.password.warning') }]}
              >
                <Input.Password
                  size={'large'}
                  placeholder={t('cart:login.form.password.placeholder')}
                />
              </Form.Item>

              <Form.Item {...tailLayout} name='remember' valuePropName='checked'>
                <Checkbox>{t('cart:login.button.remember_me.text')}</Checkbox>
                <Button
                  type={'link'}
                  style={{ float: 'right' }}
                  onClick={() => this.props.updateMode('forgot_password')}
                >
                  {t('cart:login.form.button.forgot_password.label')} <ArrowRightOutlined />
                </Button>
              </Form.Item>

              <Form.Item {...tailLayout}>
                <Button
                  icon={<LoginOutlined />}
                  block={true}
                  size={'large'}
                  loading={this.props.auth.loading}
                  type='primary'
                  htmlType='submit'
                >
                  {this.props.auth.loading ? (
                    <span>{t('cart:login.form.button.submit.loading.text')}</span>
                  ) : (
                    <span>{t('cart:login.form.button.submit.text')}</span>
                  )}
                </Button>
              </Form.Item>
            </div>
          </Form>
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = (state: any) => ({
  app: state.app,
  auth: state.auth,
  cart: state.cart
});

const mapDispatchToProps = (dispatch: any) => ({
  userLoginFetch: (userInfo: any, countries: Country[], currencies: Currency[], t: TFunction) =>
    dispatch(userLoginFetch(userInfo, countries, currencies, t))
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(LoginComponent));
