import { Col, Row } from 'antd';
import { Link } from 'react-router-dom';
import React from 'react';
import { PageSection } from '../../../types';
import '../../../styles/modules/gender.less';
import { settings } from '../../../../settings';

export const GenderModule = (section: PageSection) => {
  return (
    <Row key={section.id} className={'cms-module-gender-block'}>
      <Col xs={{ span: 20, offset: 2 }}>
        {section.settings.title && (
          <>
            <h2>{section.settings.title}</h2>
            <em>{section.settings.subtitle}</em>
          </>
        )}
        <Row gutter={[30, 30]}>
          {section.settings.genders.map((gender: string, index: number) => {
            return (
              <Col key={index} xs={{ span: 12 }} sm={12} lg={6}>
                <Link to={`${settings.products.shoppingPage}?gender=${gender}`}>
                  <div className={'gender-block'}>
                    <img
                      alt={`Products for ${gender.toLowerCase()}`}
                      src={require(`../../../../assets/image-gender-${gender}.jpg`)}
                    />
                    <div className={'gender-block-title'}>
                      <span>Collections</span>
                      <h3>{gender}</h3>
                    </div>
                  </div>
                </Link>
              </Col>
            );
          })}
        </Row>
      </Col>
    </Row>
  );
};
