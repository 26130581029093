import React from 'react';
import { Button, Col, Layout, Result, Row } from 'antd';
import NavbarComponent from '../navigation/navbar.component';
import { Helmet } from 'react-helmet';
import FooterComponent from '../navigation/footer.component';
import { BlogArticle, BreadCrumbItem } from '../../types';
import { LoopBack } from '../../redux/api';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { ArrowRightOutlined, EditOutlined } from '@ant-design/icons';
import { prerenderIsReady } from '../../helpers/prerender-ready.helper';
import moment from 'moment';
import { IAppState } from '../../redux/states/app';
import { WithTranslation, withTranslation } from 'react-i18next';
import { BannerModule } from './modules/banner.module';
import { settings } from '../../../settings';
import { ICartState } from '../../redux/states/cart';
import '../../styles/blog.less';
import HeaderModule from './modules/header.module';

const { Content } = Layout;

interface Props extends WithTranslation {
  app: IAppState;
  cart: ICartState;
  history: any;
}

interface State {
  articles: BlogArticle[];
}

class BlogComponent extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      articles: []
    };
    window.scrollTo(0, 0);
  }

  componentDidMount(): void {
    let filter: any = {};
    new LoopBack()
      .get(`/blogs?filter=${JSON.stringify(filter)}`)
      .then(res => {
        this.setState({ articles: res }, () => {
          prerenderIsReady();
        });
      })
      .catch(err => {
        prerenderIsReady();
      });
  }

  render() {
    const t = this.props.i18n.t.bind(this.props.i18n);
    const voucher = this.props.cart.voucher;

    const breadcrumbs: BreadCrumbItem[] = [
      { title: t('app:breadcrumbs.home'), link: '/' },
      { title: t('app:breadcrumbs.blog'), link: null }
    ];

    return (
      <Layout>
        <Helmet>
          <title>{t('pages:blog.meta.title')}</title>
          <meta name='description' content={t('pages:blog.meta.description')} />
          <script>window.prerenderReady = false;</script>
          <link rel='canonical' href={window.location.origin + window.location.pathname} />
          <meta property='og:title' content={t('pages:blog.meta.title')} />
          <meta property='og:description' content={t('pages:blog.meta.description')} />
          <meta property='og:url' content={window.location.origin + window.location.pathname} />
          <meta name='twitter:title' content={t('pages:blog.meta.title')} />
          <meta name='twitter:description' content={t('pages:blog.meta.description')} />
        </Helmet>
        <NavbarComponent />
        <Content className={voucher && voucher.exists && voucher.voucher ? 'voucher-applied' : ''}>
          {settings.showDefaultHeader && (
            <HeaderModule
              section={{
                settings: {
                  title: { en: t('pages:blog.page.title') },
                  subtitle: {},
                  size: 'small',
                  parallax: settings.parallaxInHeader,
                  images: [require('../../../assets/header-main.jpg').default],
                  background_image: { non_api: true }
                },
                type: 'header',
                id: 'header',
                language: this.props.app.language.code,
                uid: 'header'
              }}
            />
          )}

          <div className={'content-body'}>
            <Row className={'blog-container'}>
              <Col
                xs={{ span: 22, offset: 1 }}
                sm={{ span: 18, offset: 3 }}
                xl={{ span: 12, offset: 6 }}
              >
                {this.state.articles.length > 0 ? (
                  <Row>
                    <h2>{t('pages:blog.block.title')}</h2>
                    {this.state.articles.map((article, index) => {
                      return (
                        <Col key={index} xs={24} className={'blog-col'}>
                          <div className={'blog'}>
                            <Row className={'blog-image'}>
                              <Col xs={24}>
                                <Link to={`/blog${article.slug}`}>
                                  <img
                                    alt={article.title[this.props.app.language.code]}
                                    src={process.env.REACT_APP_API_URL + article.image}
                                  />
                                </Link>
                              </Col>
                            </Row>
                            <Row className={'blog-details'}>
                              <Col xs={24}>
                                <div>
                                  <h3>{article.title[this.props.app.language.code]}</h3>
                                  <p
                                    dangerouslySetInnerHTML={{
                                      __html: article.summary[this.props.app.language.code]
                                    }}
                                  />
                                </div>
                              </Col>
                            </Row>
                            <Row className={'blog-analytics'}>
                              <Col xs={8}>
                                <span>
                                  <strong>{t('pages:blog.label.author')}</strong> {article.author}
                                </span>
                              </Col>
                              <Col xs={8}>
                                <p>
                                  <span>
                                    <strong>{t('pages:blog.label.reading_time')}</strong>{' '}
                                    {article.reading_time}{' '}
                                    {article.reading_time === 1
                                      ? t('pages:blog.label.minute')
                                      : t('pages:blog.label.minutes')}
                                  </span>
                                </p>
                              </Col>
                              <Col xs={8}>
                                <span>
                                  <strong>{t('pages:blog.label.written_on')}</strong>{' '}
                                  {moment(article.created).format('dd DD MMM YYYY')}
                                </span>
                              </Col>
                            </Row>
                          </div>
                        </Col>
                      );
                    })}
                  </Row>
                ) : (
                  <Row align={'middle'} justify={'center'} className={'no-result-container'}>
                    <Result
                      icon={<EditOutlined />}
                      status='info'
                      title={t('pages:blog.no_blogs.title')}
                      subTitle={t('pages:blog.no_blogs.description')}
                      extra={
                        <Link to={settings.products.shoppingPage}>
                          <Button size={'large'} type='primary' key='console'>
                            {t('pages:blog.no_blogs.button.go_shopping.text')}{' '}
                            <ArrowRightOutlined />
                          </Button>
                        </Link>
                      }
                    />
                  </Row>
                )}
              </Col>
            </Row>
          </div>
          {settings.showCompanyBanner && this.state.articles.length === 0 && <BannerModule />}
        </Content>
        <FooterComponent />
      </Layout>
    );
  }
}

const mapStateToProps = (state: any) => ({
  app: state.app,
  cart: state.cart
});

export default withRouter(connect(mapStateToProps)(withTranslation()(BlogComponent)));
