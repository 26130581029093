import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import * as qs from 'query-string';
import { Col, Layout, Row, Spin } from 'antd';
import { withRouter } from 'react-router-dom';
import { ICartState } from '../../redux/states/cart';
import { Helmet } from 'react-helmet';
import NavbarComponent from '../navigation/navbar.component';
import FooterComponent from '../navigation/footer.component';
import { LoopBack } from '../../redux/api';
import { BreadCrumbItem, OrderPaymentStatus } from '../../types';
import { IAppState } from '../../redux/states/app';
import { WithTranslation, withTranslation } from 'react-i18next';
import { settings } from '../../../settings';
import NavbarFloatingComponent from '../navigation/navbar-floating.component';
import { processGoogleAnalytics } from '../../helpers/gtag';
import { statusDiv } from '../../helpers/payment-status';
import '../../styles/payment-status.less';
import HeaderModule from './modules/header.module';

const { Content } = Layout;

interface Props extends WithTranslation {
  history: any;
  app: IAppState;
  cart: ICartState;
  dispatch: Dispatch;
}

interface State {
  checking_status: boolean;
  status: string | null;
  order_id: number | null;
  payment_status: string | null;
}

class PaymentStatusComponent extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    let orderId: any = null;
    let queryParams = qs.parse(window.location.search);
    if (Object.keys(queryParams).indexOf('order-id') > -1) {
      orderId = queryParams['order-id'];
    }

    this.state = {
      checking_status: true,
      status: null,
      order_id: orderId,
      payment_status: null
    };

    this.checkStatus = this.checkStatus.bind(this);
  }

  requestStatus = () => {
    new LoopBack()
      .get(`/payment-status/${this.state.order_id}`)
      .then((order: OrderPaymentStatus) => {
        if (order.payment_status === 'paid') {
          processGoogleAnalytics(order, this.props.cart, this.props.app.language.code);
          this.props.history.push('/thank-you', {
            payment_status: order.payment_status,
            order_number: order.order_number,
            currency: order.currency
          });
        } else {
          this.setState({ payment_status: order.payment_status, checking_status: false });
        }
      })
      .catch(err => {
        this.setState({ checking_status: false, payment_status: null });
      });
  };

  checkStatus() {
    this.setState({
      checking_status: true,
      status: null
    });

    setTimeout(this.requestStatus, 5000);
  }

  componentDidMount() {
    this.checkStatus();
  }

  render() {
    const t = this.props.i18n.t.bind(this.props.i18n);
    const voucher = this.props.cart.voucher;

    const breadcrumbs: BreadCrumbItem[] = [
      { title: t('app:breadcrumbs.home'), link: '/' },
      { title: t('app:breadcrumbs.cart'), link: '/cart' },
      { title: t('app:breadcrumbs.payment_status'), link: null }
    ];

    return (
      <Layout>
        <Helmet>
          <title>{t('pages:payment_status.meta.title')}</title>
          <meta name='description' content={t('pages:payment_status.meta.description')} />
          <meta name='robots' content='noindex,nofollow' />
          <link rel='canonical' href={window.location.origin + window.location.pathname} />
          <meta property='og:title' content={t('pages:faqs.meta.title')} />
          <meta property='og:description' content={t('pages:payment_status.meta.description')} />
          <meta property='og:url' content={window.location.origin + window.location.pathname} />
          <meta name='twitter:title' content={t('pages:payment_status.meta.title')} />
          <meta name='twitter:description' content={t('pages:payment_status.meta.description')} />
        </Helmet>
        <NavbarComponent />
        <Content
          className={
            voucher && voucher.exists && voucher.voucher
              ? 'voucher-applied payment-status-container'
              : 'payment-status-container'
          }
        >
          {settings.showDefaultHeader && (
            <HeaderModule
              section={{
                settings: {
                  title: { en: t('pages:payment_status.page.title') },
                  subtitle: {},
                  size: 'small',
                  parallax: settings.parallaxInHeader,
                  images: [require('../../../assets/header-main.jpg').default],
                  background_image: { non_api: true }
                },
                type: 'header',
                id: 'header',
                language: this.props.app.language.code,
                uid: 'header'
              }}
            />
          )}

          {settings.navbar.linksStyle === 'floating' && <NavbarFloatingComponent />}

          <div className={'content-body'}>
            <Row>
              <Col xs={{ span: 22, offset: 1 }} md={{ span: 18, offset: 3 }}>
                {this.state.checking_status ? (
                  <div className={'info-box'}>
                    <h2>{t('pages:payment_status.block.title')}</h2>
                    <Spin />
                    <em style={{ marginLeft: 5, lineHeight: '1rem' }}>
                      {t('pages:payment_status.loading.text')}
                    </em>
                  </div>
                ) : (
                  statusDiv(this.state.payment_status, this.checkStatus, t)
                )}
              </Col>
            </Row>
          </div>
        </Content>
        <FooterComponent />
      </Layout>
    );
  }
}

const mapStateToProps = (state: any) => ({
  app: state.app,
  cart: state.cart
});

export default withRouter(connect(mapStateToProps)(withTranslation()(PaymentStatusComponent)));
