import React from 'react';
import { Button, Col, Layout, Result, Row } from 'antd';
import NavbarComponent from '../navigation/navbar.component';
import { Helmet } from 'react-helmet';
import { TwitterOutlined } from '@ant-design/icons';
import FooterComponent from '../navigation/footer.component';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { ICartState } from '../../redux/states/cart';
import { clearVoucher, emptyCart } from '../../redux/actions/cart';
import { IAppState } from '../../redux/states/app';
import { BreadCrumbItem, OrderPaymentStatus } from '../../types';
import { WithTranslation, withTranslation } from 'react-i18next';
import { ReactComponent as FaceBookIcon } from '../../../assets/icon-facebook.svg';
import { ReactComponent as YoutubeIcon } from '../../../assets/icon-youtube.svg';
import { ReactComponent as InstagramIcon } from '../../../assets/icon-instagram.svg';
import { settings } from '../../../settings';
import '../../styles/thank-you.less';
import NavbarFloatingComponent from '../navigation/navbar-floating.component';
import HeaderModule from './modules/header.module';

const { Content } = Layout;

interface Props extends WithTranslation {
  location: any;
  app: IAppState;
  cart: ICartState;
  history: any;
  emptyCart: typeof emptyCart;
  clearVoucher: typeof clearVoucher;
}

interface State {
  orderId: number | null;
  order: OrderPaymentStatus | null;
}

class ThankYouComponent extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    window.scrollTo(0, 0);
  }

  componentDidMount(): void {
    // @ts-ignore
    window.gtag('event', 'set_checkout_option', {
      checkout_step: 3,
      checkout_option: 'Thank You'
    });
    this.props.emptyCart();
    this.props.clearVoucher();
  }

  render() {
    const nextSteps = this.props.app.settings?.thank_you.next_steps;
    const order = this.props.location.state;
    const apiSettings = this.props.app.settings;
    const t = this.props.i18n.t.bind(this.props.i18n);
    const voucher = this.props.cart.voucher;

    const breadcrumbs: BreadCrumbItem[] = [
      { title: t('app:breadcrumbs.home'), link: '/' },
      { title: t('app:breadcrumbs.cart'), link: '/cart' },
      { title: t('app:breadcrumbs.thank_you'), link: null }
    ];

    return (
      <Layout>
        <Helmet>
          <title>{t('pages:thank_you.meta.title')}</title>
          <meta name='description' content={t('pages:thank_you.meta.description')} />
          <meta name='robots' content='noindex,nofollow' />
          <link rel='canonical' href={window.location.origin + window.location.pathname} />
          <link
            href='https://fonts.googleapis.com/css2?family=Dancing+Script:wght@400;700&display=swap'
            rel='stylesheet'
          />
          <meta property='og:title' content={t('pages:thank_you.meta.title')} />
          <meta property='og:description' content={t('pages:thank_you.meta.description')} />
          <meta property='og:url' content={window.location.origin + window.location.pathname} />
          <meta name='twitter:title' content={t('pages:thank_you.meta.title')} />
          <meta name='twitter:description' content={t('pages:thank_you.meta.description')} />
        </Helmet>
        <NavbarComponent />
        <Content className={voucher && voucher.exists && voucher.voucher ? 'voucher-applied' : ''}>
          {settings.showDefaultHeader && (
            <HeaderModule
              section={{
                settings: {
                  title: { en: t('pages:thank_you.page.title') },
                  subtitle: {},
                  size: 'small',
                  parallax: settings.parallaxInHeader,
                  images: [require('../../../assets/header-main.jpg').default],
                  background_image: { non_api: true }
                },
                type: 'header',
                id: 'header',
                language: this.props.app.language.code,
                uid: 'header'
              }}
            />
          )}

          {settings.navbar.linksStyle === 'floating' && <NavbarFloatingComponent />}

          <div className={'content-body'}>
            <Row className={'thank-you-container'}>
              <Col xs={{ span: 22, offset: 1 }} md={{ span: 18, offset: 3 }}>
                <Result
                  status='success'
                  title={t('pages:thank_you.success.title')}
                  subTitle={
                    order
                      ? t('pages:thank_you.success.description.with_order_number', {
                          text: order.order_number
                        })
                      : t('pages:thank_you.success.description.default')
                  }
                />

                <Row justify={'center'}>
                  <Col xs={24} lg={16}>
                    <h2 id={'whats-next-title'}>{t('pages:thank_you.block.title')}</h2>
                  </Col>
                </Row>
                {nextSteps &&
                  nextSteps.map((step, index) => {
                    if (index % 2) {
                      return (
                        <Row key={index} justify={'center'} className={'next-step'}>
                          <Col xs={16} lg={12}>
                            <div className={'next-step-content'}>
                              <h3>{step.title[this.props.app.language.code.toLowerCase()]}</h3>
                              <p
                                dangerouslySetInnerHTML={{
                                  __html:
                                    step.description[this.props.app.language.code.toLowerCase()]
                                }}
                              />
                            </div>
                          </Col>
                          <Col xs={8} lg={4}>
                            <div className={'next-step-graphic'}>
                              <img
                                alt={step.title[this.props.app.language.code.toLowerCase()]}
                                src={process.env.REACT_APP_API_URL + step.image}
                              />
                            </div>
                          </Col>
                        </Row>
                      );
                    } else {
                      return (
                        <Row key={index} justify={'center'} className={'next-step'}>
                          <Col xs={8} lg={4}>
                            <div className={'next-step-graphic'}>
                              <img
                                alt={step.title[this.props.app.language.code.toLowerCase()]}
                                src={process.env.REACT_APP_API_URL + step.image}
                              />
                            </div>
                          </Col>
                          <Col xs={16} lg={12}>
                            <div className={'next-step-content'}>
                              <h3>{step.title[this.props.app.language.code.toLowerCase()]}</h3>
                              <p
                                dangerouslySetInnerHTML={{
                                  __html:
                                    step.description[this.props.app.language.code.toLowerCase()]
                                }}
                              />
                            </div>
                          </Col>
                        </Row>
                      );
                    }
                  })}

                {apiSettings && apiSettings.thank_you && settings.thankYou.showFollowUsBanner && (
                  <Row justify={'center'}>
                    <Col
                      xs={24}
                      lg={16}
                      id={'follow-us-container'}
                      style={{
                        backgroundImage: `url(${process.env.REACT_APP_API_URL}${apiSettings.thank_you.follow_us.image})`
                      }}
                    >
                      <Row>
                        <Col md={16} xl={12} id={'follow-us-text'}>
                          <h2>
                            {apiSettings.thank_you.follow_us.title[this.props.app.language.code]}
                          </h2>
                          <p
                            dangerouslySetInnerHTML={{
                              __html:
                                apiSettings.thank_you.follow_us.description[
                                  this.props.app.language.code
                                ]
                            }}
                          />

                          {apiSettings?.footer_settings?.social_media_links && (
                            <div id={'footer-social-media-icons'}>
                              {apiSettings?.footer_settings?.social_media_links.instagram && (
                                <a
                                  rel={'noopener noreferrer'}
                                  target={'_blank'}
                                  href={apiSettings?.footer_settings?.social_media_links.instagram}
                                >
                                  <InstagramIcon />
                                </a>
                              )}
                              {apiSettings?.footer_settings?.social_media_links.twitter && (
                                <a
                                  rel={'noopener noreferrer'}
                                  target={'_blank'}
                                  href={apiSettings?.footer_settings?.social_media_links.twitter}
                                >
                                  <TwitterOutlined />
                                </a>
                              )}
                              {apiSettings?.footer_settings?.social_media_links.facebook && (
                                <a
                                  rel={'noopener noreferrer'}
                                  target={'_blank'}
                                  href={apiSettings?.footer_settings?.social_media_links.facebook}
                                >
                                  <FaceBookIcon />
                                </a>
                              )}
                              {apiSettings?.footer_settings?.social_media_links.youtube && (
                                <a
                                  rel={'noopener noreferrer'}
                                  target={'_blank'}
                                  href={apiSettings?.footer_settings?.social_media_links.youtube}
                                >
                                  <YoutubeIcon />
                                </a>
                              )}
                            </div>
                          )}
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                )}

                <Row justify={'center'}>
                  <Col xs={24} lg={16} className={'next-steps-thank-you'}>
                    <h3>{t('pages:thank_you.footer.title')}</h3>
                  </Col>
                </Row>
                <Row justify={'center'}>
                  <Link to={'/'}>
                    <Button size={'large'} type='primary' key='console'>
                      {t('pages:thank_you.footer.button.go_home.text')}
                    </Button>
                  </Link>
                </Row>
              </Col>
            </Row>
          </div>
        </Content>
        <FooterComponent />
      </Layout>
    );
  }
}

const mapStateToProps = (state: any) => ({
  app: state.app,
  cart: state.cart
});

const mapDispatchToProps = (dispatch: any) => ({
  emptyCart: () => dispatch(emptyCart()),
  clearVoucher: () => dispatch(clearVoucher())
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ThankYouComponent))
);
