import React from 'react';
import { Alert, Button, Checkbox, Col, Form, Input, Layout, Row } from 'antd';
import NavbarComponent from '../navigation/navbar.component';
import { Helmet } from 'react-helmet';
import FooterComponent from '../navigation/footer.component';
import { connect } from 'react-redux';
import { AuthState } from '../../redux/states/user';
import { Link } from 'react-router-dom';
import { LoopBack } from '../../redux/api';
import Swal from 'sweetalert2';
import { debounce } from '../../helpers/debounce.helper';
import { FormInstance } from 'antd/es/form';
import { IAppState } from '../../redux/states/app';
import QueueAnim from 'rc-queue-anim';
import { WithTranslation, withTranslation } from 'react-i18next';
import '../../styles/register.less';
import { ArrowRightOutlined } from '@ant-design/icons';

const { Content } = Layout;

interface Props extends WithTranslation {
  app: IAppState;
  auth: AuthState;
  history: any;
}

interface State {
  loading: boolean;
  showPasswordError: boolean;
}

class RegisterComponent extends React.Component<Props, State> {
  formRef = React.createRef<FormInstance>();

  constructor(props: Props) {
    super(props);

    this.state = {
      loading: false,
      showPasswordError: false
    };
  }

  componentDidMount(): void {
    window.scrollTo(0, 0);
  }

  onFinish = (values: any) => {
    const t = this.props.i18n.t.bind(this.props.i18n);
    const data = {
      ...values,
      language: this.props.app.language.code
    };
    new LoopBack()
      .post('/users/register', data)
      .then(res => {
        Swal.fire({
          title: t('auth:register.popup.success.title'),
          html: t('auth:register.popup.success.description'),
          icon: 'success',
          confirmButtonText: t('auth:register.popup.success.button.ok')
        });
        this.props.history.push('/registered');
      })
      .catch(err => {
        Swal.fire({
          title: t('auth:register.popup.error.title'),
          html: t('auth:register.popup.error.description'),
          icon: 'error',
          confirmButtonText: t('auth:register.popup.error.button.ok')
        });
      });
  };

  onKeyUp = () => {
    if (this.formRef && this.formRef.current) {
      const values = this.formRef.current.getFieldsValue(['password', 'password_repeat']);
      if (values.password !== values.password_repeat) {
        this.setState({ showPasswordError: true });
      } else {
        this.setState({ showPasswordError: false });
      }
    }
  };

  render() {
    const settings = this.props.app.settings;
    const t = this.props.i18n.t.bind(this.props.i18n);
    const linkText = t('auth:register.form.agreement.link.text');
    return (
      <Layout>
        <Helmet>
          <title>{t('auth:register.meta.title')}</title>
          <meta name='description' content={t('auth:register.meta.description')} />
          <link rel='canonical' href={window.location.origin + window.location.pathname} />
          <meta property='og:title' content={t('auth:register.meta.title')} />
          <meta property='og:description' content={t('auth:register.meta.description')} />
          <meta property='og:url' content={window.location.origin + window.location.pathname} />
          <meta name='twitter:title' content={t('auth:register.meta.title')} />
          <meta name='twitter:description' content={t('auth:register.meta.description')} />
        </Helmet>
        <NavbarComponent />
        <Content
          className={`${
            settings?.navbar_settings?.type === 'transparent'
              ? 'no-offset page-register'
              : 'page-register'
          }`}
        >
          <div className={'content-header with-overlay default-header'}>
            <QueueAnim className={'queue-anim-container'} type={'scale'} duration={1500}>
              <h1 key={1}>{t('auth:register.page.title')}</h1>
            </QueueAnim>
          </div>
          <Row className={'register-container'}>
            <Col xs={{ span: 20, offset: 2 }} xl={{ span: 12, offset: 6 }}>
              <Form
                layout={'vertical'}
                name='register'
                ref={this.formRef}
                onFinish={this.onFinish}
                scrollToFirstError
              >
                <div className={'form-section'}>
                  <h2>{t('auth:register.block.title')}</h2>
                  <hr />
                  <Row gutter={10}>
                    <Col xs={24}>
                      {this.state.showPasswordError && (
                        <Alert
                          message={t('auth:register.alert.title')}
                          description={t('auth:register.alert.description')}
                          type={'warning'}
                          showIcon={true}
                          closable={false}
                        />
                      )}

                      <Form.Item
                        label={t('auth:register.form.email.label')}
                        name={'email'}
                        rules={[
                          {
                            type: 'email',
                            message: t('auth:register.form.email.warning.not_a_valid_email')
                          },
                          {
                            required: true,
                            message: t('auth:register.form.email.warning')
                          }
                        ]}
                      >
                        <Input
                          size={'large'}
                          placeholder={t('auth:register.form.email.placeholder')}
                        />
                      </Form.Item>

                      <Form.Item
                        label={t('auth:register.form.new_password.label')}
                        name='password'
                        rules={[
                          { required: true, message: t('auth:register.form.new_password.warning') }
                        ]}
                      >
                        <Input
                          type={'password'}
                          placeholder={t('auth:register.form.new_password.placeholder')}
                        />
                      </Form.Item>

                      <Form.Item
                        label={t('auth:register.form.new_password_repeat.label')}
                        name='password_repeat'
                        rules={[
                          {
                            required: true,
                            message: t('auth:register.form.new_password_repeat.warning')
                          }
                        ]}
                      >
                        <Input
                          type={'password'}
                          placeholder={t('auth:register.form.new_password.placeholder')}
                          onKeyUp={debounce(this.onKeyUp, 800)}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </div>

                <Row gutter={10}>
                  <Col xs={24}>
                    <Form.Item
                      style={{ marginBottom: 0 }}
                      name='news_letter'
                      valuePropName='checked'
                    >
                      <Checkbox>{t('auth:register.form.news_letter.label')}</Checkbox>
                    </Form.Item>
                    <Form.Item
                      name='agreement'
                      valuePropName='checked'
                      rules={[
                        {
                          validator: (_, value) =>
                            value
                              ? Promise.resolve()
                              : Promise.reject(t('auth:register.form.agreement.warning'))
                        }
                      ]}
                    >
                      <Checkbox>
                        <span
                          dangerouslySetInnerHTML={{
                            __html: t('auth:register.form.agreement.label', {
                              link: `<a href="/terms-and-conditions" rel='noopener noreferrer' target="_blank">${linkText}</a>`,
                              interpolation: { escapeValue: false }
                            })
                          }}
                        />
                      </Checkbox>
                    </Form.Item>
                  </Col>
                </Row>

                <Row gutter={10}>
                  <Col xs={24}>
                    <Form.Item>
                      <Button block={true} size={'large'} type='primary' htmlType='submit'>
                        {t('auth:register.form.button.register.text')}
                      </Button>
                      <Link style={{ float: 'right', marginTop: 10 }} to={'/login'}>
                        {t('auth:register.form.button.already_have_an_account_label')}{' '}
                        <ArrowRightOutlined />
                      </Link>
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>
        </Content>
        <FooterComponent />
      </Layout>
    );
  }
}

const mapStateToProps = (state: any) => ({
  app: state.app,
  auth: state.auth
});

export default connect(mapStateToProps)(withTranslation()(RegisterComponent));
