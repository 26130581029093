import React from 'react';
import { Helmet } from 'react-helmet';
import { Link, withRouter } from 'react-router-dom';
import { Button, Col, Form, Input, Layout, Row, Select } from 'antd';
import NavbarComponent from '../navigation/navbar.component';
import FooterComponent from '../navigation/footer.component';
import { FormInstance } from 'antd/lib/form';
import { IAppState } from '../../redux/states/app';
import { connect } from 'react-redux';
import { LoopBack } from '../../redux/api';
import Swal from 'sweetalert2';
import FAQ from './faq-item.component';
import { Map, Marker } from 'react-leaflet';
import ReactLeafletGoogleLayer from 'react-leaflet-google-layer';
import { mapsStyles } from '../../../custom/styles/google-maps.js';
import { SendOutlined } from '@ant-design/icons';
import { WithTranslation, withTranslation } from 'react-i18next';
import { settings } from '../../../settings';
import { BreadCrumbItem } from '../../types';
import { ICartState } from '../../redux/states/cart';
import '../../styles/contact.less';
import NavbarFloatingComponent from '../navigation/navbar-floating.component';
import HeaderModule from './modules/header.module';

const { TextArea } = Input;
const Option = Select.Option;
const { Content } = Layout;

interface Props extends WithTranslation {
  history: any;
  cart: ICartState;
  app: IAppState;
}

interface State {
  error: any;
}

class ContactDefaultComponent extends React.Component<Props, State> {
  formRef = React.createRef<FormInstance>();

  constructor(props: Props) {
    super(props);
    window.scrollTo(0, 0);

    this.state = {
      error: null
    };
  }

  handleSubmit = () => {
    const t = this.props.i18n.t.bind(this.props.i18n);
    if (this.formRef && this.formRef.current) {
      const formFields = this.formRef.current;
      const data = {
        ...formFields.getFieldsValue(),
        language: this.props.app.language.code
      };
      formFields.validateFields().then(res => {
        new LoopBack()
          .post('/contact', data)
          .then(res => {
            Swal.fire({
              title: t('pages:contact.popup.success.title'),
              html: t('pages:contact.popup.success.description'),
              icon: 'success'
            });
            formFields.resetFields();
          })
          .catch(err => {
            Swal.fire({
              title: t('pages:contact.popup.error.title'),
              html: t('pages:contact.popup.error.description'),
              icon: 'error'
            });
          });
      });
    }
  };

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
    // by switching to another page quickly, leaflet gives an error
    // therefor catch the error and dont render the map
    this.setState({
      error: error
    });
  }

  render() {
    const webSettings = settings;
    const t = this.props.i18n.t.bind(this.props.i18n);
    const voucher = this.props.cart.voucher;

    return (
      <Layout>
        <Helmet>
          <title>{t('pages:contact.meta.title')}</title>
          <meta name='description' content={t('pages:contact.meta.description')} />
          <script>window.prerenderReady = false;</script>
          <link rel='canonical' href={window.location.origin + window.location.pathname} />
          <meta property='og:title' content={t('pages:contact.meta.title')} />
          <meta property='og:description' content={t('pages:contact.meta.description')} />
          <meta property='og:url' content={window.location.origin + window.location.pathname} />
          <meta name='twitter:title' content={t('pages:contact.meta.title')} />
          <meta name='twitter:description' content={t('pages:contact.meta.description')} />
        </Helmet>
        <NavbarComponent />
        <Content
          className={
            voucher && voucher.exists && voucher.voucher
              ? 'voucher-applied page-contact'
              : 'page-contact'
          }
        >
          {settings.navbar.linksStyle === 'floating' && <NavbarFloatingComponent />}

          <HeaderModule
            section={{
              settings: {
                title: { en: t('pages:contact.page.title') },
                subtitle: {},
                size: 'small',
                parallax: settings.parallaxInHeader,
                images: [require('../../../assets/header-main.jpg').default],
                background_image: { non_api: true }
              },
              type: 'header',
              id: 'header',
              language: this.props.app.language.code,
              uid: 'header'
            }}
          />

          <div className={'content-body'}>
            <Row className={'contact-container'}>
              {settings.contact.showDecoration && (
                <img
                  alt={`Decorative element of ${settings.appName}`}
                  className={'decoration decoration-top-left'}
                  src={require('../../../assets/decoration-1.png').default}
                />
              )}
              <Col
                xs={{ span: 20, offset: 2 }}
                sm={{ span: 20, offset: 2 }}
                lg={{ span: 18, offset: 3 }}
              >
                <Row>
                  <Col xs={24}>
                    <Row gutter={20}>
                      <Col xs={24} md={12}>
                        <h3>{t('pages:contact.more_faqs.title')}</h3>
                        <FAQ max={5} />
                        <div className='faq-link-container'>
                          <Link to={'/faqs'}>
                            <Button size={'middle'} type={'primary'}>
                              {t('pages:contact.more_faqs.button.text')}
                            </Button>
                          </Link>
                        </div>
                      </Col>
                      <Col xs={24} md={12}>
                        <h3 id={'contact-form-title'}>{t('pages:contact.block.title')}</h3>
                        <Form name='basic' ref={this.formRef} onFinish={this.handleSubmit}>
                          <Form.Item
                            name={'name'}
                            rules={[
                              { required: true, message: t('pages:contact.form.name.warning') }
                            ]}
                            extra={t('pages:contact.form.name.extra')}
                          >
                            <Input placeholder={t('pages:contact.form.name.placeholder')} />
                          </Form.Item>
                          <Form.Item
                            name={'email'}
                            rules={[
                              { required: true, message: t('pages:contact.form.email.warning') }
                            ]}
                            extra={t('pages:contact.form.email.extra')}
                          >
                            <Input
                              type='email'
                              placeholder={t('pages:contact.form.email.placeholder')}
                            />
                          </Form.Item>
                          <Form.Item
                            name={'subject'}
                            rules={[
                              { required: true, message: t('pages:contact.form.subject.warning') }
                            ]}
                          >
                            <Select placeholder={t('pages:contact.form.subject.placeholder')}>
                              <Option value={'Placing an order'}>
                                {t('pages:contact.form.subject.option.placing_order')}
                              </Option>
                              <Option value={'Payment'}>
                                {t('pages:contact.form.subject.option.payment')}
                              </Option>
                              <Option value={'Enquiry'}>
                                {t('pages:contact.form.subject.option.enquiry')}
                              </Option>
                              <Option value={'Other'}>
                                {t('pages:contact.form.subject.option.other')}
                              </Option>
                            </Select>
                          </Form.Item>
                          <Form.Item name={'company'}>
                            <Input placeholder={t('pages:contact.form.company.placeholder')} />
                          </Form.Item>
                          <Form.Item
                            name={'content'}
                            rules={[
                              { required: true, message: t('pages:contact.form.content.warning') }
                            ]}
                          >
                            <TextArea
                              rows={7}
                              placeholder={t('pages:contact.form.content.placeholder')}
                            />
                          </Form.Item>

                          <Button
                            block={window.outerWidth < 768}
                            icon={<SendOutlined />}
                            size={'large'}
                            type='primary'
                            htmlType='submit'
                          >
                            <span>{t('pages:contact.form.button.submit.text')}</span>
                          </Button>
                        </Form>
                      </Col>
                    </Row>
                  </Col>
                </Row>

                {settings.contact.showMap && (
                  <>
                    <hr />
                    <Row gutter={[20, 20]}>
                      <Col xs={24} lg={12}>
                        {!this.state.error && (
                          <Map center={webSettings.contact.mapCenter} zoom={12}>
                            <ReactLeafletGoogleLayer
                              styles={mapsStyles}
                              googleMapsLoaderConf={{
                                KEY: process.env.REACT_APP_GOOGLE_MAPS_API_KEY
                              }}
                              type={'roadmap'}
                            />
                            <Marker position={webSettings.contact.mapLocation} />
                          </Map>
                        )}
                      </Col>

                      <Col xs={24} lg={12} className='contact-container'>
                        {!this.props.app.loading ? (
                          <Row gutter={[20, 20]}>
                            {this.props.app.settings &&
                              this.props.app.settings.addresses.map((address, aindex) => {
                                return (
                                  <Col key={aindex} xs={24} sm={12} md={24} xl={12}>
                                    <div className={'contact-address'}>
                                      <p>
                                        <strong>{address.title}</strong>
                                      </p>
                                      <p>
                                        {address.address}
                                        <br />
                                        {`${address.postal_code} ${address.city}, ${address.country}`}
                                        <br />
                                        <br />
                                        <span className='theme-color'>
                                          {t('pages:contact.contact_details.phone_symbol')}
                                        </span>{' '}
                                        {address.phone}
                                        <br />
                                        <span className='theme-color'>
                                          {t('pages:contact.contact_details.email_symbol')}
                                        </span>
                                        &nbsp;
                                        <a href={`mailto:${address.email}`}>{address.email}</a>
                                      </p>
                                      <p>
                                        <span className='theme-color'>
                                          {address.business_registration.label}
                                        </span>{' '}
                                        {address.business_registration.value}
                                        <br />
                                        <span className='theme-color'>
                                          {address.tax_details.label}
                                        </span>{' '}
                                        {address.tax_details.value}
                                      </p>
                                    </div>
                                  </Col>
                                );
                              })}
                          </Row>
                        ) : (
                          <Row>
                            <Col xs={24} sm={8} md={12}>
                              <p>{t('pages:contact.contact_details.loading.text')}</p>
                            </Col>
                          </Row>
                        )}
                      </Col>
                    </Row>

                    <hr />
                  </>
                )}
              </Col>
              {settings.contact.showDecoration && (
                <img
                  alt={`Decorative element of ${settings.appName}`}
                  className={'decoration decoration-bottom-right'}
                  src={require('../../../assets/decoration-3.png').default}
                />
              )}
            </Row>
          </div>
        </Content>
        <FooterComponent />
      </Layout>
    );
  }
}

const mapStateToProps = (state: any) => ({
  app: state.app,
  cart: state.cart
});

export default withRouter(connect(mapStateToProps)(withTranslation()(ContactDefaultComponent)));
