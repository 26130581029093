import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import {  Layout, Button, Select } from 'antd';
import { withRouter } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import NavbarComponent from '../../core-module/components/navigation/navbar.component';
import FooterComponent from '../../core-module/components/navigation/footer.component';
import { IAppState } from '../../core-module/redux/states/app';
import { WithTranslation, withTranslation } from 'react-i18next';
import { ICartState } from '../../core-module/redux/states/cart';
import '../styles/portfolio.less';

const { Content } = Layout;

interface Props extends WithTranslation {
  history: any;
  app: IAppState;
  cart: ICartState;
  dispatch: Dispatch;
}

interface State {
  selectedIndex: number;
}

class PortfolioComponent extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      selectedIndex: 1
    };

    window.scrollTo(0, 0);
  }

  render() {
    const t = this.props.i18n.t.bind(this.props.i18n);
    const voucher = this.props.cart.voucher;

    // const breadcrumbs: BreadCrumbItem[] = [
    //   { title: t('app:breadcrumbs.home'), link: '/' },
    //   { title: t('app:breadcrumbs.events'), link: null }
    // ];

    return (
      <Layout>
        <Helmet>
          <title>{t('pages:events.meta.title')}</title>
          <meta name='description' content={t('pages:events.meta.description')} />
          <meta name='robots' content='noindex,nofollow' />
          <link rel='canonical' href={window.location.origin + window.location.pathname} />
          <meta property='og:title' content={t('pages:faqs.meta.title')} />
          <meta property='og:description' content={t('pages:events.meta.description')} />
          <meta property='og:url' content={window.location.origin + window.location.pathname} />
          <meta name='twitter:title' content={t('pages:events.meta.title')} />
          <meta name='twitter:description' content={t('pages:events.meta.description')} />
        </Helmet>
        <NavbarComponent />
        <Content className={voucher && voucher.exists && voucher.voucher ? 'voucher-applied' : ''}>
          {/*{settings.showDefaultHeader && (*/}
          {/*  <div className={'content-header with-overlay default-header'}>*/}
          {/*    <QueueAnim className={'queue-anim-container'} type={'scale'} duration={1500}>*/}
          {/*      <h2 key={1}>{t('pages:events.page.title')}</h2>*/}
          {/*    </QueueAnim>*/}
          {/*    <BreadcrumbsComponent items={breadcrumbs} />*/}
          {/*  </div>*/}
          {/*)}*/}

          <div className={'content-body page-portfolio'}>
            {/*<Row>*/}
            {/*  <Col xs={24}>*/}
            <fieldset className='slideshow'>
              <input type='radio' id='slideCheckbox1' name='slide' checked={this.state.selectedIndex === 1} autoFocus />
              <div id={'slide-1'} className='slide'>
                <div className='slide__html' />

                <div className='slide__content'>
                  <h2>Collab Mosaic Sculpture</h2>
                  <p>
                    Mosaic pieces will be pre- painted, the activity will include people gluing each
                    piece to the correct location.
                  </p>
                </div>
              </div>

              <input type='radio' id='slideCheckbox2' name='slide' checked={this.state.selectedIndex === 2} />
              <div id={'slide-2'} className='slide'>
                <div className='slide__content'>
                  <h2>Canvas Walls</h2>
                  <p>
                    Consists of vertical surfaces for collaborative painting along with customized
                    themes and topics to help spark the imagination. (Other artistic mediums can be
                    used such as collage making, paper quilling, paint markers, etc)
                  </p>
                </div>
              </div>

              <input type='radio' id='slideCheckbox3' name='slide' checked={this.state.selectedIndex === 3} />
              <div id={'slide-3'} className='slide'>
                <div className='slide__content'>
                  <h2>Community Events</h2>
                  <p>
                    Hosts and creators of... - Expression Sessions (Collab Art, Live Music, Poetry
                    and Food) - Movement Meditation and Creation (Yoga, Meditation, Mindfulness Art
                    and Live Music) - 432Hz (Open Mic)
                  </p>
                </div>
              </div>

              <input type='radio' id='slideCheckbox4' name='slide' checked={this.state.selectedIndex === 4} />
              <div id={'slide-4'} className='slide'>
                <div className='slide__content'>
                  <h2>Canvas Puzzles</h2>
                  <p>
                    Consists of a printed guide, multiple pieces of printed canvas with a custom
                    design that are painted by a group then assembled to create the final picture.
                    (Other artistic mediums can be used such as collage making, paper quilling,
                    paint markers, etc)
                  </p>
                </div>
              </div>

              <input type='radio' id='slideCheckbox5' name='slide' checked={this.state.selectedIndex === 5} />
              <div id={'slide-5'} className='slide'>
                <div className='slide__content'>
                  <h2>3D Cut-outs</h2>
                  <p>
                    We can customize any logo, shape and form create it into a 3D sculpture and
                    could either have it for live painters or as a community art installation.
                  </p>
                </div>
              </div>

              <input type='radio' id='slideCheckbox6' name='slide' checked={this.state.selectedIndex === 6} />
              <div id={'slide-6'} className='slide'>
                <div className='slide__content'>
                  <h2>Canvas Tables</h2>
                  <p>
                    Consists of horizontal surfaces for collaborative painting along with customized
                    themes and topics to help spark the imagination. (Other artistic mediums can be
                    used such as collage making, paper quilling, paint markers, etc)
                  </p>
                </div>
              </div>

              <input type='radio' id='slideCheckbox7' name='slide' checked={this.state.selectedIndex === 7} />
              <div id={'slide-7'} className='slide'>
                <div className='slide__content'>
                  <h2>Pin the Globe</h2>
                  <p>
                    This activation will include a 3D globe where participants will be asked to pin
                    their country of origin on. This will be a wonderful way to physically see the
                    diversity we have in the UAE.
                  </p>
                </div>
              </div>

              <input type='radio' id='slideCheckbox8' name='slide' checked={this.state.selectedIndex === 8} />
              <div id={'slide-8'} className='slide'>
                <div className='slide__content'>
                  <h2>3D Spheres</h2>
                  <p>
                    Painting spheres that could be planets, or stars, or whatever your imagination
                    desires. We can create a UV light show using this installation or just painting
                    on spheres as an ongoing installation.
                  </p>
                </div>
              </div>

              <input type='radio' id='slideCheckbox9' name='slide' checked={this.state.selectedIndex === 9} />
              <div id={'slide-9'} className='slide'>
                <div className='slide__content'>
                  <h2>Giant Story Book</h2>
                  <p>
                    A huge book that is used for Collective Story telling. The community is posed a
                    question and is then gathered by our team to formulate a joint story.
                  </p>
                </div>
              </div>

              <input type='radio' id='slideCheckbox10' name='slide' checked={this.state.selectedIndex === 10} />
              <div id={'slide-10'} className='slide'>
                <div className='slide__content'>
                  <h2>Art Maze</h2>
                  <p>
                    A human sized maze that consists of consistent size canvas covered boxes
                    arranged to create an entire space of blank canvases open for creative dialogue.
                  </p>
                </div>
              </div>

              <input type='radio' id='slideCheckbox11' name='slide' checked={this.state.selectedIndex === 11} />
              <div id={'slide-11'} className='slide'>
                <div className='slide__content'>
                  <h2>Past Events</h2>
                  <p>
                    A. Expression Sessions B. Masdar Employee Day C. Glass Qube Creative Launch D.
                    Metronome at the Westin Hotel E. Wasla Festival F. Al Raha International School
                    Anniversary
                  </p>
                </div>
              </div>

              <input type='radio' id='slideCheckbox12' name='slide' checked={this.state.selectedIndex === 12} />
              <div id={'slide-12'} className='slide'>
                <div className='slide__content'>
                  <h2>Canvas Cubes</h2>
                  <p>
                    Consists of different sized cubes that are suspended from the ceiling or a truss
                    to provide a surface for painting with customized themes and topics to help
                    spark the imagination.
                  </p>
                </div>
              </div>

              <input type='radio' id='slideCheckbox13' name='slide' checked={this.state.selectedIndex === 13} />
              <div id={'slide-13'} className='slide'>
                <div className='slide__content'>
                  <h2>Paper Sculptures</h2>
                  <p>
                    Consists of printed guides, where people can colour their geometrical shape,
                    cut, glue it together then add it to a growing sculpture.
                  </p>
                </div>
              </div>

              <input type='radio' id='slideCheckbox14' name='slide' checked={this.state.selectedIndex === 14} />
              <div id={'slide-14'} className='slide'>
                <div className='slide__content'>
                  <h2>Upcycled Art</h2>
                  <p>
                    <strong>Cork planter magnets</strong> – Upcycled corks that could be turned into
                    planters then placed on your fridge. Teaching adults and kids how to turn waste
                    that ends up in landfill into Art.
                  </p>
                </div>
              </div>

              <input type='radio' id='slideCheckbox16' name='slide' checked={this.state.selectedIndex === 15} />
              <div id={'slide-16'} className='slide'>
                <div className='slide__content'>
                  <h2>Upcycled Art</h2>
                  <p>
                    <strong>Plastic bottle planters and Art</strong> – Upcycled plastic bottles can
                    be turned into planters or penny banks for kids and adults to learn the
                    wonderful art of upcycling, to decrease the load on landfill and a more
                    sustainable way of life.
                  </p>
                </div>
              </div>

              <input type='radio' id='slideCheckbox15' name='slide' checked={this.state.selectedIndex === 16} />
              <div id={'slide-15'} className='slide'>
                <div className='slide__content'>
                  <h2>Cube of Unity</h2>
                  <p>
                    Collaborative painting on a large cube to create a unified art piece. Custom
                    graphic design can be created based on the theme.
                  </p>
                </div>
              </div>

              <input type='radio' id='slideCheckbox17' name='slide' checked={this.state.selectedIndex === 17} />
              <div id={'slide-17'} className='slide'>
                <div className='slide__content'>
                  <h2>Canvas Boxes</h2>
                  <p>
                    Consists of customized shapes with multiple surfaces for collaborative painting
                    with customized themes and topics to help spark the imagination.
                  </p>
                </div>
              </div>

              <nav>
                <Button
                  disabled={this.state.selectedIndex === 1}
                  type={'primary'}
                  onClick={() => this.setState({ selectedIndex: this.state.selectedIndex - 1 })}
                >
                  Prev
                </Button>

                <Button style={{float: 'right'}} disabled={this.state.selectedIndex === 17} type={'primary'}
                        onClick={() => this.setState({ selectedIndex: this.state.selectedIndex + 1 })}
                >
                  Next
                </Button>
                <Select style={{float: 'right', marginRight: 10}} value={this.state.selectedIndex} onChange={(e) => this.setState({selectedIndex: parseInt(e.toString())})}>
                  <Select.Option value={1}>1</Select.Option>
                  <Select.Option value={2}>2</Select.Option>
                </Select>
              </nav>

              {/*<nav onClick={(e) => console.log(e.target)}>*/}
              {/*  <label className='slide-button' htmlFor='slideCheckbox1'>*/}
              {/*    1*/}
              {/*  </label>*/}
              {/*  <label className='slide-button' htmlFor='slideCheckbox2'>*/}
              {/*    2*/}
              {/*  </label>*/}
              {/*  <label className='slide-button' htmlFor='slideCheckbox3'>*/}
              {/*    3*/}
              {/*  </label>*/}
              {/*  <label className='slide-button' htmlFor='slideCheckbox4'>*/}
              {/*    4*/}
              {/*  </label>*/}
              {/*  <label className='slide-button' htmlFor='slideCheckbox5'>*/}
              {/*    5*/}
              {/*  </label>*/}
              {/*  <label className='slide-button' htmlFor='slideCheckbox6'>*/}
              {/*    6*/}
              {/*  </label>*/}
              {/*  <label className='slide-button' htmlFor='slideCheckbox7'>*/}
              {/*    7*/}
              {/*  </label>*/}
              {/*  <label className='slide-button' htmlFor='slideCheckbox8'>*/}
              {/*    8*/}
              {/*  </label>*/}
              {/*  <label className='slide-button' htmlFor='slideCheckbox9'>*/}
              {/*    9*/}
              {/*  </label>*/}
              {/*  <label className='slide-button' htmlFor='slideCheckbox10'>*/}
              {/*    10*/}
              {/*  </label>*/}
              {/*  <label className='slide-button' htmlFor='slideCheckbox11'>*/}
              {/*    11*/}
              {/*  </label>*/}
              {/*  <label className='slide-button' htmlFor='slideCheckbox12'>*/}
              {/*    12*/}
              {/*  </label>*/}
              {/*  <label className='slide-button' htmlFor='slideCheckbox13'>*/}
              {/*    13*/}
              {/*  </label>*/}
              {/*  <label className='slide-button' htmlFor='slideCheckbox14'>*/}
              {/*    14*/}
              {/*  </label>*/}
              {/*  <label className='slide-button' htmlFor='slideCheckbox15'>*/}
              {/*    15*/}
              {/*  </label>*/}
              {/*  <label className='slide-button' htmlFor='slideCheckbox16'>*/}
              {/*    16*/}
              {/*  </label>*/}
              {/*  <label className='slide-button' htmlFor='slideCheckbox17'>*/}
              {/*    17*/}
              {/*  </label>*/}
              {/*</nav>*/}
            </fieldset>
            {/*</Col>*/}
            {/*</Row>*/}
          </div>
        </Content>
        <FooterComponent />
      </Layout>
    );
  }
}

const mapStateToProps = (state: any) => ({
  app: state.app,
  cart: state.cart
});

export default withRouter(connect(mapStateToProps)(withTranslation()(PortfolioComponent)));
