import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { WithTranslation, withTranslation } from 'react-i18next';
import { settings } from '../../../settings';
import ContactBasicComponent from './contact-basic.component';
import ContactExtendedComponent from './contact-default.component';

interface Props extends WithTranslation {
  history: any;
}

class ContactComponent extends React.Component<Props> {
  render() {
    if (settings.contact.style === 'basic') {
      return <ContactBasicComponent />;
    }
    return <ContactExtendedComponent />;
  }
}

export default withRouter(connect()(withTranslation()(ContactComponent)));
