import React, { Component } from 'react';
import PublicRoutes from './router';
import { createBrowserHistory } from 'history';
import { ConfigProvider } from 'antd';
import enGB from 'antd/es/locale/en_GB';
import configureStore from './core-module/redux/store';
import { unregister } from './serviceWorker';
import { logoutUser } from './core-module/redux/actions/auth';
import { AuthState } from './core-module/redux/states/user';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { errorBoundry } from './error-boundary';
import jwtDecode from 'jwt-decode';
import { Token } from './core-module/types';
import {
  fetchSettings,
  hideCategoriesBar,
  initializeLocalization
} from './core-module/redux/actions/app';
import { IAppState } from './core-module/redux/states/app';
import Tranlation from './i18n';
import { withTranslation, WithTranslation } from 'react-i18next';
import SkeletonComponent from './core-module/components/app/skeleton.component';

const Redux = configureStore();
Redux.store.subscribe(listener);

const history = createBrowserHistory({ basename: process.env.PUBLIC_URL });
unregister();

function listener() {}

interface Props extends WithTranslation {
  auth: AuthState;
  dispatch: Dispatch;
  app: IAppState;
  fetchSettings: typeof fetchSettings;
  initializeLocalization: typeof initializeLocalization;
  hideCategoriesBar: typeof hideCategoriesBar;
  logoutUser: typeof logoutUser;
}

class App extends Component<Props> {
  async componentWillMount() {
    if (this.props.auth.isLoggedIn) {
      if (this.makeLogoutAfterExpiredToken()) {
        this.doLogoutAfterExpiredToken();
      }
    }
    this.props.hideCategoriesBar();
    const doSetLanguage = !this.props.app.languageInit;
    this.props.fetchSettings();
    const i18 = await Tranlation(
      this.props.app.language ? this.props.app.language.code.toLowerCase() : 'en'
    );
    this.props.initializeLocalization(this.props, doSetLanguage, i18);
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<{}>, snapshot?: any): void {
    if (this.makeLogoutAfterExpiredToken()) {
      this.doLogoutAfterExpiredToken();
    }
  }

  makeLogoutAfterExpiredToken() {
    const { auth } = this.props;
    const containsInvalidUserObject = auth.user && Object.keys(auth.user).length === 0;

    let isExpired = false;

    if (auth.isLoggedIn && !containsInvalidUserObject) {
      const token: Token = jwtDecode(auth.token);
      isExpired = token.exp < new Date().getTime() / 1000;
    }

    return (auth.isLoggedIn && isExpired) || containsInvalidUserObject;
  }

  doLogoutAfterExpiredToken() {
    this.props.logoutUser();
  }

  render = () => {
    return (
      <ConfigProvider locale={enGB}>
        {this.props.app.currency === null || this.props.app.country === null ? (
          <SkeletonComponent />
        ) : (
          <PublicRoutes history={history} />
        )}
      </ConfigProvider>
    );
  };
}

const mapStateToProps = (state: any) => ({
  app: state.app,
  auth: state.auth
});

const mapDispatchToProps = (dispatch: any) => ({
  fetchSettings: () => dispatch(fetchSettings()),
  initializeLocalization: (props: any, doSetLanguage: boolean, i18: any) =>
    dispatch(initializeLocalization(props, doSetLanguage, i18)),
  logoutUser: () => dispatch(logoutUser()),
  hideCategoriesBar: () => dispatch(hideCategoriesBar())
});

export default connect(mapStateToProps, mapDispatchToProps)(errorBoundry(withTranslation()(App)));
