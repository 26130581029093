import { Button, Col, Result, Row } from 'antd';
import * as React from 'react';
import { Component } from 'react';
import Layout from 'antd/lib/layout';
import { Helmet } from 'react-helmet';
import { HomeOutlined } from '@ant-design/icons';
import { WithTranslation, withTranslation } from 'react-i18next';

interface Props extends WithTranslation {}

class ErrorComponent extends Component<Props> {
  componentDidMount(): void {
    window.scrollTo(0, 0);
  }

  render() {
    const t = this.props.i18n.t.bind(this.props.i18n);

    return (
      <Layout>
        <Helmet>
          <title>{t('app:500.meta.title')}</title>
          <meta name='description' content={t('app:500.meta.description')} />
          <meta name='robots' content='noindex,nofollow' />
          <meta name='prerender-status-code' content='500' />
          <link rel='canonical' href={window.location.origin + window.location.pathname} />
        </Helmet>
        <Layout.Content style={{ paddingTop: 0 }}>
          <div>
            <Row style={{ height: '80vh' }} justify={'space-around'} align={'middle'}>
              <Col xs={24} style={{ textAlign: 'center' }}>
                <Result
                  status='500'
                  title={t('app:500.message.title')}
                  subTitle={t('app:500.message.subtitle')}
                  extra={
                    <a href={'/'}>
                      <Button icon={<HomeOutlined />} type={'primary'} size={'large'}>
                        {t('app:500.button.text')}
                      </Button>
                    </a>
                  }
                />
              </Col>
            </Row>
          </div>
        </Layout.Content>
      </Layout>
    );
  }
}

export default withTranslation()(ErrorComponent);
