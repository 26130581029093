import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Col, Dropdown, Layout, Row } from 'antd';
import { AuthState } from '../../redux/states/user';
import { Link, withRouter } from 'react-router-dom';
import { ICartState } from '../../redux/states/cart';
import { IAppState } from '../../redux/states/app';
import { settings } from '../../../settings';
import { withTranslation, WithTranslation } from 'react-i18next';
import ShoppingCartComponent from './shopping-cart.component';
import { getMenu } from './helpers/menu.helper';
import '../../styles/header.less';

const { Header } = Layout;

interface Props extends WithTranslation {
  history: any;
  match: any;
  auth: AuthState;
  cart: ICartState;
  app: IAppState;
}

interface State {
  scrollTop: number;
  menuVisible: boolean;
}

class NavbarComponent extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      scrollTop: 0,
      menuVisible: false
    };
  }

  componentDidMount(): void {
    window.addEventListener('scroll', this.saveScrollTopValue);
  }

  saveScrollTopValue = () => {
    let supportPageOffset = window.pageXOffset !== undefined;
    let isCSS1Compat = (document.compatMode || '') === 'CSS1Compat';
    let scrollTop = supportPageOffset
      ? window.pageYOffset
      : isCSS1Compat
      ? document.documentElement.scrollTop
      : document.body.scrollTop;

    this.setState({ scrollTop: scrollTop });
  };

  componentWillUnmount() {
    window.removeEventListener('scroll', this.saveScrollTopValue);
  }

  render() {
    const apiSettings = this.props.app.settings;
    const t = this.props.i18n.t.bind(this.props.i18n);
    const voucher = this.props.cart.voucher;
    let headerClassName = `header-${apiSettings?.navbar_settings?.type}`;

    if (this.state.scrollTop >= settings.addClassToHeaderOnScroll) {
      headerClassName += ' header-scrolled';
    }
    const dropdownContainer = document.getElementById('header-navbar');

    return (
      <Header id={`header-navbar`} className={headerClassName}>
        {voucher && voucher.exists && (
          <Row id={'header-voucher'}>
            <Col xs={24} lg={0} className={'voucher-text'}>
              {t('app:navbar.voucher.title.mobile', { text: voucher.voucher.name })}
            </Col>
            <Col xs={0} lg={24} className={'voucher-text'}>
              {voucher.voucher.navbarText[this.props.app.language.code]}
            </Col>
          </Row>
        )}
        <Row id={'header-main'}>
          <Col xs={24} xl={{ span: 18, offset: 3 }}>
            <Row>
              <Col xs={4}>
                <Link id={'header-main-logo'} to={'/'}>
                  <img
                    alt={`Logo ${settings.appName}`}
                    src={require('../../../assets/logo-color.png').default}
                  />
                </Link>
              </Col>
              {apiSettings && (
                <Col xs={20}>
                  {window.outerWidth > 930 ? (
                    <span>
                      <ShoppingCartComponent />
                      <nav>{getMenu(apiSettings, this.props.app.language.code, 'horizontal')}</nav>
                    </span>
                  ) : (
                    <span>
                      <Dropdown
                        getPopupContainer={() =>
                          dropdownContainer ? dropdownContainer : document.body
                        }
                        overlay={getMenu(apiSettings, this.props.app.language.code, 'horizontal')}
                        trigger={['click']}
                        onVisibleChange={e => this.setState({ menuVisible: e })}
                      >
                        <div id={'header-menu-mobile'}>
                          <div id='nav-hamburger' className={this.state.menuVisible ? 'open' : ''}>
                            <span />
                            <span />
                            <span />
                            <span />
                          </div>
                        </div>
                      </Dropdown>
                      <ShoppingCartComponent />
                    </span>
                  )}
                </Col>
              )}
            </Row>
          </Col>
        </Row>
      </Header>
    );
  }
}

const mapStateToProps = (state: any) => ({
  app: state.app,
  auth: state.auth,
  cart: state.cart
});

export default withRouter(connect(mapStateToProps)(withTranslation()(NavbarComponent)));
