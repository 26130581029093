import * as React from 'react';
import { Redirect, Route } from 'react-router-dom';

export const ConditionedRoute = ({ Component, condition, alternatePath, ...rest }: any) => {
  const render = (props: any) =>
    condition ? <Component {...props} /> : <Redirect to={alternatePath} />;

  if (!condition) {
    return <Route path={rest.path} exact={rest.exact} render={render} />;
  }

  return <Route {...rest} render={render} />;
};
