import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import * as qs from 'query-string';
import { Button, Col, Layout, Result, Row } from 'antd';
import { withRouter } from 'react-router-dom';
import { ICartState } from '../../redux/states/cart';
import { Helmet } from 'react-helmet';
import NavbarComponent from '../navigation/navbar.component';
import FooterComponent from '../navigation/footer.component';
import { LoopBack } from '../../redux/api';
import { LoadingOutlined, RedoOutlined } from '@ant-design/icons';
import { BreadCrumbItem } from '../../types';
import { IAppState } from '../../redux/states/app';
import { WithTranslation, withTranslation } from 'react-i18next';
import { settings } from '../../../settings';
import HeaderModule from './modules/header.module';

const { Content } = Layout;

interface Props extends WithTranslation {
  history: any;
  app: IAppState;
  cart: ICartState;
  dispatch: Dispatch;
}

interface State {
  uid: string;
  checking_status: boolean;
  status: string;
}

class UnsubscribeComponent extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    let uid: any = null;
    let queryParams = qs.parse(window.location.search);
    if (Object.keys(queryParams).indexOf('uid') > -1) {
      uid = queryParams['uid'];
    }

    this.state = {
      uid: uid,
      checking_status: true,
      status: ''
    };
  }

  requestStatus = () => {
    new LoopBack()
      .post(`/unsubscribe`, { uid: this.state.uid })
      .then((res: any) => {
        this.setState({ checking_status: false, status: 'success' });
      })
      .catch(err => {
        this.setState({ checking_status: false, status: 'error' });
      });
  };

  checkStatus() {
    this.setState({
      checking_status: true
    });

    setTimeout(this.requestStatus, 1000);
  }

  componentDidMount() {
    this.checkStatus();
  }

  render() {
    const t = this.props.i18n.t.bind(this.props.i18n);
    const voucher = this.props.cart.voucher;

    const breadcrumbs: BreadCrumbItem[] = [
      { title: t('app:breadcrumbs.home'), link: '/' },
      { title: t('app:breadcrumbs.unsubscribe'), link: null }
    ];

    return (
      <Layout>
        <Helmet>
          <title>{t('pages:unsubscribe.meta.title')}</title>
          <meta name='description' content={t('pages:unsubscribe.meta.description')} />
          <meta name='robots' content='noindex,nofollow' />
          <link rel='canonical' href={window.location.origin + window.location.pathname} />
          <meta property='og:title' content={t('pages:faqs.meta.title')} />
          <meta property='og:description' content={t('pages:unsubscribe.meta.description')} />
          <meta property='og:url' content={window.location.origin + window.location.pathname} />
          <meta name='twitter:title' content={t('pages:unsubscribe.meta.title')} />
          <meta name='twitter:description' content={t('pages:unsubscribe.meta.description')} />
        </Helmet>
        <NavbarComponent />
        <Content className={voucher && voucher.exists && voucher.voucher ? 'voucher-applied' : ''}>
          {settings.showDefaultHeader && (
            <HeaderModule
              section={{
                settings: {
                  title: { en: t('pages:unsubscribe.page.title') },
                  subtitle: {},
                  size: 'small',
                  parallax: settings.parallaxInHeader,
                  images: [require('../../../assets/header-main.jpg').default],
                  background_image: { non_api: true }
                },
                type: 'header',
                id: 'header',
                language: this.props.app.language.code,
                uid: 'header'
              }}
            />
          )}

          <div className={'content-body'}>
            <Row>
              <Col xs={{ span: 22, offset: 1 }} md={{ span: 18, offset: 3 }}>
                <div style={{ minHeight: '500px', marginBottom: '150px' }} className={'bottom-cta'}>
                  {this.state.checking_status && (
                    <div className={'info-box'}>
                      <Result
                        icon={<LoadingOutlined />}
                        status='info'
                        title={t('pages:unsubscribe.status.loading.title')}
                        subTitle={t('pages:unsubscribe.status.loading.description')}
                      />
                    </div>
                  )}
                  {!this.state.checking_status && this.state.status === 'success' && (
                    <div className={'info-box'}>
                      <Result
                        status='success'
                        title={t('pages:unsubscribe.status.success.title')}
                        subTitle={t('pages:unsubscribe.status.success.description')}
                      />
                    </div>
                  )}
                  {!this.state.checking_status && this.state.status === 'error' && (
                    <div className={'info-box'}>
                      <Result
                        status='warning'
                        title={t('pages:unsubscribe.status.error.title')}
                        subTitle={t('pages:unsubscribe.status.error.description')}
                        extra={
                          <Button
                            icon={<RedoOutlined />}
                            size={'large'}
                            type={'primary'}
                            onClick={() => this.checkStatus()}
                          >
                            {t('pages:unsubscribe.status.button.retry')}
                          </Button>
                        }
                      />
                    </div>
                  )}
                </div>
              </Col>
            </Row>
          </div>
        </Content>
        <FooterComponent />
      </Layout>
    );
  }
}

const mapStateToProps = (state: any) => ({
  app: state.app,
  cart: state.cart
});

export default withRouter(connect(mapStateToProps)(withTranslation()(UnsubscribeComponent)));
