import React from 'react';
import { Button, Collapse, Result, Row, Spin } from 'antd';
import { LoopBack } from '../../redux/api';
import { FAQ } from '../../types';
import { groupBy } from '../../helpers/group-by.helpder';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Link, withRouter } from 'react-router-dom';
import { ArrowRightOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { connect } from 'react-redux';
import { IAppState } from '../../redux/states/app';
import { settings } from '../../../settings';
import { ICartState } from '../../redux/states/cart';
import { prerenderIsReady } from '../../helpers/prerender-ready.helper';

const { Panel } = Collapse;

interface Props extends WithTranslation {
  app: IAppState;
  max: number;
  cart: ICartState;
  history: any;
}

interface State {
  faqs: any[];
  loading: boolean;
}

class FAQItemComponent extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      faqs: [],
      loading: true
    };
  }

  public componentDidMount() {
    new LoopBack().get('/faqs').then(res => {
      this.setState({ faqs: res, loading: false });
      prerenderIsReady();
    });
  }

  public getFAQS() {
    if (!this.props.max) {
      return this.state.faqs;
    }
    return this.state.faqs.slice(0, this.props.max);
  }

  displayFlatList = () => {
    return (
      <Collapse
        accordion={true}
        bordered={false}
        defaultActiveKey={['0']}
        className={'faq-container'}
      >
        {this.getFAQS().map((item, index) => (
          <Panel header={item.title[this.props.app.language.code]} key={`${index}`}>
            <p
              style={{ paddingLeft: 24 }}
              dangerouslySetInnerHTML={{ __html: item.content[this.props.app.language.code] }}
            />
          </Panel>
        ))}
      </Collapse>
    );
  };

  displayCategorisedList = () => {
    const faqs: any[] = [];
    const currentFaqs = this.state.faqs;
    const groupedFaqs = groupBy(currentFaqs, 'category');
    Object.keys(groupedFaqs).forEach(item => {
      const faqItems = groupedFaqs[item];
      faqs.push({ key: item, items: faqItems });
    });
    const t = this.props.i18n.t.bind(this.props.i18n);
    return (
      <>
        {faqs.length > 0 ? (
          faqs.map((item: { key: string; items: FAQ[] }, index: number) => {
            const keys = item.items.map((i, x) => `${x}_${index}`);

            return (
              <div key={index}>
                <h3>{item.key}</h3>
                <hr />
                <Collapse
                  accordion={false}
                  bordered={false}
                  defaultActiveKey={settings.expandAllFAQS ? keys : []}
                  className={'faq-container'}
                >
                  {item.items.map((item: FAQ, _index: number) => (
                    <Panel
                      header={item.title[this.props.app.language.code]}
                      key={`${_index}_${index}`}
                    >
                      <p
                        style={{ paddingLeft: 24 }}
                        dangerouslySetInnerHTML={{
                          __html: item.content[this.props.app.language.code]
                        }}
                      />
                    </Panel>
                  ))}
                </Collapse>
              </div>
            );
          })
        ) : (
          <Row align={'middle'} justify={'center'} className={'no-result-container'}>
            <Result
              icon={<QuestionCircleOutlined />}
              status='info'
              title={t('pages:faqs.no_faqs.title')}
              subTitle={t('pages:faqs.no_faqs.description')}
              extra={
                <Link to={settings.products.shoppingPage}>
                  <Button size={'large'} type='primary' key='console'>
                    {t('pages:faqs.no_faqs.button.go_shopping.text')} <ArrowRightOutlined />
                  </Button>
                </Link>
              }
            />
          </Row>
        )}
      </>
    );
  };

  render() {
    const t = this.props.i18n.t.bind(this.props.i18n);

    return (
      <div>
        {!this.state.loading ? (
          this.props.max ? (
            this.displayFlatList()
          ) : (
            this.displayCategorisedList()
          )
        ) : (
          <div>
            <Spin /> {t('pages:faq_item.loading.text')}
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state: any) => ({
  app: state.app
});

export default withRouter(
  connect<any, Props, any>(mapStateToProps)(withTranslation()(FAQItemComponent))
);
