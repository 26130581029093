import { Carousel, Col, Row } from 'antd';
import { Link, withRouter } from 'react-router-dom';
import React from 'react';
import { PageSection, Product } from '../../../types';
import { LoopBack } from '../../../redux/api';
import { connect } from 'react-redux';
import { WithTranslation, withTranslation } from 'react-i18next';
import { IAppState } from '../../../redux/states/app';
import { gtagProducts } from '../../../helpers/gtag';
import { productSchema } from '../../../helpers/structured-data.helper';
import { settings } from '../../../../settings';
import { ReactComponent as ArrowRight } from '../../../../assets/icon-arrow-right.svg';
import { ReactComponent as ArrowLeft } from '../../../../assets/icon-arrow-left.svg';
import '../../../styles/modules/highlighted-products.less';
import { CarouselRef } from 'antd/lib/carousel';

interface Props extends WithTranslation {
  app: IAppState;
  section: PageSection;
  history: any;
}

interface State {
  products: Product[];
}

class HighlightedProductsModule extends React.Component<Props, State> {
  carouselRef = React.createRef<CarouselRef>();

  constructor(props: Props) {
    super(props);

    this.state = {
      products: []
    };
  }

  componentDidMount(): void {
    const filter: any = {};
    filter.where = { id: { inq: this.props.section?.settings.products } };
    new LoopBack().get(`/products?filter=${JSON.stringify(filter)}`).then(res => {
      this.setState({ products: res });
      gtagProducts(res, this.props.app.language.code);
    });
  }

  onPrev = () => {
    if (this.carouselRef && this.carouselRef.current) {
      this.carouselRef.current.prev();
    }
  };

  onNext = () => {
    if (this.carouselRef && this.carouselRef.current) {
      this.carouselRef.current.next();
    }
  };

  render() {
    const section = this.props.section;

    return (
      <Row key={section?.id} className={'cms-module-highlighted-products'} align={'middle'}>
        <Col xs={24} md={{ span: 22, offset: 1 }} xl={{ span: 18, offset: 3 }}>
          {section?.settings.title && (
            <>
              {section.settings.make_h1 ? (
                <h1>{section.settings.title}</h1>
              ) : (
                <h2>{section.settings.title}</h2>
              )}
              <em>{section.settings.subtitle}</em>
            </>
          )}
          <Row>
            <Col xs={24}>
              <Carousel
                ref={this.carouselRef}
                responsive={[
                  {
                    breakpoint: 425,
                    settings: {
                      slidesToShow: 1,
                      variableWidth: true,
                      slidesPerRow: 1,
                      slidesToScroll: 1
                    }
                  },
                  {
                    breakpoint: 769,
                    settings: {
                      slidesToShow: 1,
                      variableWidth: true,
                      slidesPerRow: 2,
                      slidesToScroll: 1
                    }
                  },
                  {
                    breakpoint: 992,
                    settings: { slidesToShow: 1, slidesPerRow: 3, slidesToScroll: 1 }
                  },
                  {
                    breakpoint: 1400,
                    settings: { slidesToShow: 1, slidesPerRow: 4, slidesToScroll: 1 }
                  }
                ]}
                autoplay={section.settings.auto_play}
                autoplaySpeed={section.settings.auto_play_speed}
                slidesPerRow={4}
                slidesToShow={1}
                dots={false}
                slidesToScroll={1}
              >
                {this.state.products.map((product, index) => {
                  return (
                    <div className={'product-slide'} key={index}>
                      <script type='application/ld+json'>
                        {this.props.app.currency &&
                          JSON.stringify(
                            productSchema(
                              product,
                              this.props.app.currency.code,
                              this.props.app.language.code
                            )
                          )}
                      </script>
                      {product.custom_url ? (
                        <a href={product.slug} target='_blank' rel={'noopener noreferrer'}>
                          <div className={'highlighted-product'}>
                            {product.pictures && (
                              <img
                                alt={`${product.name[this.props.app.language.code]}`}
                                src={process.env.REACT_APP_API_URL + product.pictures[0]}
                              />
                            )}
                          </div>
                        </a>
                      ) : (
                        <Link to={`${settings.products.shoppingPage}${product.slug}`}>
                          <div className={'highlighted-product'}>
                            {product.pictures && (
                              <img
                                alt={`${product.name[this.props.app.language.code]}`}
                                src={process.env.REACT_APP_API_URL + product.pictures[0]}
                              />
                            )}
                          </div>
                        </Link>
                      )}
                      <div className={'highlighted-product-title'}>
                        <h3>{product.name[this.props.app.language.code]}</h3>
                      </div>
                    </div>
                  );
                })}
              </Carousel>
              <div className={'highlighted-products-navigation'} onClick={this.onNext}>
                <div
                  onClick={this.onPrev}
                  className={'highlighted-products-navigation-arrow arrow-left'}
                >
                  <ArrowLeft />
                </div>
                <div
                  onClick={this.onNext}
                  className={'highlighted-products-navigation-arrow arrow-right'}
                >
                  <ArrowRight />
                </div>
              </div>
            </Col>
          </Row>
          {section && section.settings.button && section.settings.button.url !== '' && (
            <Row className={'highlighted-products-link'}>
              <Col xs={24}>
                <p>
                  <Link to={section.settings.button.url}>
                    {section.settings.button.text[this.props.app.language.code]}
                  </Link>
                </p>
              </Col>
            </Row>
          )}
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = (state: any) => ({
  app: state.app
});

export default withRouter(
  connect<any, Props, any>(mapStateToProps)(withTranslation()(HighlightedProductsModule))
);
