import React from 'react';
import { Col, Layout, Row } from 'antd';
import NavbarComponent from '../navigation/navbar.component';
import { Helmet } from 'react-helmet';
import FooterComponent from '../navigation/footer.component';
import { BlogArticle, BreadCrumbItem } from '../../types';
import { LoopBack } from '../../redux/api';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { prerenderIsReady } from '../../helpers/prerender-ready.helper';
import { IAppState } from '../../redux/states/app';
import { WithTranslation, withTranslation } from 'react-i18next';
import '../../styles/blog.less';
import { ICartState } from '../../redux/states/cart';
import { settings } from '../../../settings';
import HeaderModule from './modules/header.module';

const { Content } = Layout;

interface Props extends WithTranslation {
  app: IAppState;
  cart: ICartState;
  history: any;
}

interface State {
  article: BlogArticle | null;
  loading: boolean;
}

class BlogArticleComponent extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      article: null,
      loading: false
    };

    window.scrollTo(0, 0);
  }

  componentDidMount(): void {
    this.setState({ loading: true });
    new LoopBack()
      .get(`/blogs/%2F${window.location.pathname.split('/').pop()}`)
      .then(res => {
        if (res === null) {
          this.props.history.push('/404');
        } else {
          this.setState({ article: res, loading: false }, () => {
            prerenderIsReady();
          });
        }
      })
      .catch(err => {
        prerenderIsReady();
        this.setState({ loading: false });
      });
  }

  render() {
    const t = this.props.i18n.t.bind(this.props.i18n);
    const voucher = this.props.cart.voucher;

    const breadcrumbs: BreadCrumbItem[] = [
      { title: t('app:breadcrumbs.home'), link: '/' },
      { title: t('app:breadcrumbs.blog'), link: '/blog' }
    ];

    if (this.state.article) {
      breadcrumbs.push({
        title: this.state.article.title[this.props.app.language.code],
        link: null
      });
    }

    return (
      <Layout>
        {this.state.article && (
          <Helmet>
            <title>{this.state.article?.title[this.props.app.language.code]}</title>
            <meta
              name='description'
              content={this.state.article?.summary[this.props.app.language.code]}
            />
            <script>window.prerenderReady = false;</script>
            <link rel='canonical' href={window.location.origin + window.location.pathname} />
            <meta
              property='og:title'
              content={this.state.article?.title[this.props.app.language.code]}
            />
            <meta
              property='og:description'
              content={this.state.article?.summary[this.props.app.language.code]}
            />
            <meta property='og:url' content={window.location.origin + window.location.pathname} />
            <meta
              name='twitter:title'
              content={this.state.article?.title[this.props.app.language.code]}
            />
            <meta
              name='twitter:description'
              content={this.state.article?.summary[this.props.app.language.code]}
            />
          </Helmet>
        )}
        <NavbarComponent />
        <Content className={voucher && voucher.exists && voucher.voucher ? 'voucher-applied' : ''}>
          {settings.showDefaultHeader && this.state.article && (
            <HeaderModule
              section={{
                settings: {
                  title: this.state.article.title,
                  subtitle: {},
                  size: 'small',
                  parallax: settings.parallaxInHeader,
                  images: [require('../../../assets/header-main.jpg').default],
                  background_image: { non_api: true }
                },
                type: 'header',
                id: 'header',
                language: this.props.app.language.code,
                uid: 'header'
              }}
            />
          )}

          <div className={'content-body'}>
            {this.state.article ? (
              <Row className={'blog-container'}>
                <Col xs={{ span: 12, offset: 6 }}>
                  <h2>{t('pages:blog_article.block.title')}</h2>
                  <Row>
                    <Col xs={24} className={'blog-col'}>
                      <Link to={'/blog'}>{t('pages:blog_article.button.go_back.text')}</Link>
                      <div className={'blog'}>
                        <Row className={'blog-image'}>
                          <Col xs={24}>
                            <img
                              alt={this.state.article.summary[this.props.app.language.code]}
                              src={process.env.REACT_APP_API_URL + this.state.article.image}
                            />
                          </Col>
                        </Row>
                        <Row className={'blog-details'}>
                          <Col xs={24}>
                            <div>
                              <strong>
                                {this.state.article.title[this.props.app.language.code]}
                              </strong>
                            </div>
                            <div
                              dangerouslySetInnerHTML={{
                                __html: this.state.article.content[this.props.app.language.code]
                              }}
                            />
                          </Col>
                        </Row>
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            ) : (
              this.state.loading && <span>{t('pages:blog_article.loading')}</span>
            )}
          </div>
        </Content>
        <FooterComponent />
      </Layout>
    );
  }
}

const mapStateToProps = (state: any) => ({
  app: state.app,
  cart: state.cart
});

export default withRouter(connect(mapStateToProps)(withTranslation()(BlogArticleComponent)));
