import React from 'react';
import {
  Alert,
  Button,
  Checkbox,
  Col,
  Form,
  Input,
  Radio,
  Row,
  Select,
  Space,
  Spin,
  Switch
} from 'antd';
import { connect } from 'react-redux';
import { AuthState } from '../../../redux/states/user';
import { ArrowLeftOutlined, ArrowRightOutlined, LoadingOutlined } from '@ant-design/icons';
import { FormInstance } from 'antd/lib/form';
import { LoopBack } from '../../../redux/api';
import Swal from 'sweetalert2';
import { ICartState } from '../../../redux/states/cart';
import { Link } from 'react-router-dom';
import { Country, PaymentMethod } from '../../../types';
import { IAppState } from '../../../redux/states/app';
import { SelectValue } from 'antd/es/select';
import { setCountry } from '../../../redux/actions/app';
import { debounce } from '../../../helpers/debounce.helper';
import { WithTranslation, withTranslation } from 'react-i18next';
import { settings } from '../../../../settings';
import { calculateAllCosts } from '../../../helpers/calculate-all-costs';
import { loadStripe } from '@stripe/stripe-js';

const { Option } = Select;
const antIcon = <LoadingOutlined spin />;

interface Props extends WithTranslation {
  app: IAppState;
  history: any;
  cart: ICartState;
  auth: AuthState;
  mode: string;
  updateMode: (mode: string) => void;
  setCountry: typeof setCountry;
  deliveryType: string;
}

interface State {
  different_delivery_address: boolean;
  different_invoice_address: boolean;
  paymentMethods: PaymentMethod[];
  selectedPaymentMethod: string | null;
  showPasswordError: boolean;
  createAccount: boolean;
  loading: boolean;
}

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 6 }
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 24 }
  }
};

class CheckoutComponent extends React.Component<Props, State> {
  formRef = React.createRef<FormInstance>();
  stripePromise: any;

  constructor(props: Props) {
    super(props);

    this.state = {
      different_delivery_address: this.props.auth.user
        ? this.props.auth.user.different_delivery_address
        : false,
      different_invoice_address: this.props.auth.user
        ? this.props.auth.user.different_invoice_address
        : false,
      paymentMethods: [],
      selectedPaymentMethod: null,
      showPasswordError: false,
      createAccount: false,
      loading: false
    };

    if (process.env.REACT_APP_STRIPE_API_KEY) {
      this.stripePromise = loadStripe(process.env.REACT_APP_STRIPE_API_KEY);
    }

    new LoopBack().get('/payment-methods').then(res => {
      this.setState({ paymentMethods: res });
    });
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any): void {
    if (!prevProps.auth.isLoggedIn && this.props.auth.isLoggedIn) {
      const customer = this.props.auth.user.customer;

      if (this.formRef && this.formRef.current) {
        this.formRef.current.setFieldsValue({ ...this.props.auth.user });
        if (customer === undefined || customer === null) {
          this.formRef.current.setFieldsValue({ customer: { email: this.props.auth.user.email } });
        }
      }
      this.setState({
        different_invoice_address: this.props.auth.user.different_invoice_address,
        different_delivery_address: this.props.auth.user.different_delivery_address
      });

      this.props.updateMode('user');
    } else if (prevProps.auth.isLoggedIn && !this.props.auth.isLoggedIn) {
      this.setState({
        different_invoice_address: false,
        different_delivery_address: false
      });
      this.props.updateMode('guest');
      if (this.formRef && this.formRef.current) {
        this.formRef.current.setFieldsValue({
          different_invoice_address: false,
          different_delivery_address: false
        });
      }
    }
  }

  switchDifferentDeliveryAddress = (e: boolean) => {
    this.setState({ different_delivery_address: e });
    if (e && this.formRef && this.formRef.current) {
      const currentCustomerDetails = this.formRef.current.getFieldValue('customer');
      const deliveryDetails = {
        ...currentCustomerDetails,
        street_name: null,
        door_number: null,
        postal_code: null
      };
      this.formRef.current.setFieldsValue({ delivery: deliveryDetails });
    }
  };

  switchCreateAccount = (e: boolean) => {
    this.setState({ createAccount: e });
  };

  switchDifferentInvoiceAddress = (e: boolean) => {
    this.setState({ different_invoice_address: e });
    if (e && this.formRef && this.formRef.current) {
      const currentCustomerDetails = this.formRef.current.getFieldValue('customer');
      const invoiceDetails = {
        ...currentCustomerDetails,
        street_name: null,
        door_number: null,
        postal_code: null
      };
      this.formRef.current.setFieldsValue({ invoice: invoiceDetails });
    }
  };

  componentDidMount(): void {
    if (this.props.auth.isLoggedIn) {
      if (this.formRef && this.formRef.current) {
        this.formRef.current.setFieldsValue({ ...this.props.auth.user });
        if (this.props.auth.user.customer === undefined || this.props.auth.user.customer === null) {
          this.formRef.current.setFieldsValue({ customer: { email: this.props.auth.user.email } });
        }
      }
    } else {
      if (this.formRef && this.formRef.current && this.props.app.country) {
        this.formRef.current.setFieldsValue({
          customer: { country: this.props.app.country.id }
        });
      }
    }
  }

  onFinish = (values: any) => {
    const t = this.props.i18n.t.bind(this.props.i18n);
    const costs = calculateAllCosts(this.props);

    this.setState({ loading: true });
    if (this.props.app.currency) {
      if (this.props.app.country) {
        let orderData: any = {
          customer: values.customer,
          products: this.props.cart.items,
          events: this.props.cart.events,
          gift_cards: this.props.cart.giftCards,
          payment_method: costs.totalTotalWithVoucher === 0 ? 'creditcard' :values.payment_method,
          payment_issuer: values.payment_issuer,
          currency: this.props.app.currency.code,
          language: this.props.app.language.code,
          delivery_type: this.props.deliveryType
        };

        if (this.props.cart.voucher && this.props.cart.voucher.exists) {
          orderData.voucher = this.props.cart.voucher.voucher.code;
        }

        if (this.state.createAccount) {
          orderData.news_letter = values.news_letter;
          orderData.create_account = true;
          orderData.password = values.password;
          orderData.password_repeat = values.password_repeat;
        }

        new LoopBack()
          .post(settings.cart.paymentURL, orderData)
          .then(async res => {
            // @ts-ignore
            window.gtag('event', 'set_checkout_option', {
              checkout_step: 2,
              checkout_option: 'Payment'
            });

            if (settings.paymentMethod === 'stripe' && this.stripePromise) {
              const stripe = await this.stripePromise;
              await stripe.redirectToCheckout({
                sessionId: res.url
              });
            } else if (settings.paymentMethod === 'payu') {
              console.log(res);
            } else {
              window.location.href = res.url;
            }

            // window.location.href = res.url;
          })
          .catch(err => {
            Swal.fire({
              title: t('cart:checkout.popup.order_placed.warning.title'),
              html: t('cart:checkout.popup.order_placed.warning.description'),
              icon: 'warning'
            });
            this.setState({ loading: false });
          });
      } else {
        Swal.fire({
          title: t('cart:checkout.popup.no_country.title'),
          html: t('cart:checkout.popup.no_country.description'),
          icon: 'warning'
        });
        this.setState({ loading: false });
      }
    } else {
      Swal.fire({
        title: t('cart:checkout.popup.no_currency.title'),
        html: t('cart:checkout.popup.no_currency.description'),
        icon: 'warning'
      });
      this.setState({ loading: false });
    }
  };

  onPaymentMethodChange = (e: any) => {
    this.setState({
      selectedPaymentMethod: e.target.value
    });
  };

  onCountryChange = (e: SelectValue) => {
    if (!this.state.different_delivery_address) {
      const country = this.props.app.countries.filter(
        country => country.id.toString() === e.toString()
      );
      this.props.setCountry(country[0]);
    }
  };

  onDeliveryCountryChange = (e: SelectValue) => {
    const country = this.props.app.countries.filter(
      country => country.id.toString() === e.toString()
    );
    this.props.setCountry(country[0]);
  };

  allowApplePay = () => {
    let allowApplePay = false;
    try {
      // @ts-ignore
      if (window.ApplePaySession && window.ApplePaySession.canMakePayments()) {
        allowApplePay = true;
      }
      if (!allowApplePay) {
        return false;
      }
    } catch (err) {
      console.warn(err.message);
      return false;
    }
    console.info('allow apple pay -b: ', allowApplePay);
    return allowApplePay;
  };

  getSupportedPaymentMethods = () => {
    let methods: PaymentMethod[] = [];
    const selectedCountry = this.props.app.country;
    if (selectedCountry) {
      methods = this.state.paymentMethods.filter(method =>
        selectedCountry.payment_methods.some(
          paymentMethodTypes => paymentMethodTypes === method.type
        )
      );
    }
    return methods;
  };

  onKeyUp = () => {
    if (this.formRef && this.formRef.current) {
      const values = this.formRef.current.getFieldsValue(['password', 'password_repeat']);
      if (values.password !== values.password_repeat) {
        this.setState({ showPasswordError: true });
      } else {
        this.setState({ showPasswordError: false });
      }
    }
  };

  render() {
    const tailLayout = {
      wrapperCol: { xs: { offset: 0, span: 18 }, sm: { offset: 6, span: 18 } }
    };

    const costs = calculateAllCosts(this.props);

    const titleLayout = { ...tailLayout.wrapperCol };
    titleLayout.xs = { offset: 0, span: 20 };
    const t = this.props.i18n.t.bind(this.props.i18n);
    const linkText = t('cart:checkout.form.terms_and_conditions.link');

    return (
      <Form
        {...formItemLayout}
        ref={this.formRef}
        name='register'
        onFinish={this.onFinish}
        scrollToFirstError
      >
        <div className={'form-section'} style={{ marginBottom: 0 }}>
          <h2>{t('cart:checkout.form.my_details.title')}</h2>
          {settings.navbar.userAccount.enable && !this.props.auth.isLoggedIn && (
            <Button
              type={'link'}
              style={{ float: 'right', marginTop: 20 }}
              onClick={() => this.props.updateMode('user')}
            >
              {t('cart:checkout.form.button.already_have_an_account')} <ArrowRightOutlined />
            </Button>
          )}
          <hr />
          <Row gutter={10}>
            {settings.cart.checkoutFields.includes('first_name') &&
              <Col xs={24} lg={12}>
                <Form.Item
                  name={['customer', 'first_name']}
                  rules={[{ required: true, message: t('cart:checkout.form.first_name.warning') }]}
                >
                  <Input
                    size={'large'}
                    placeholder={t('cart:checkout.form.first_name.placeholder')}
                  />
                </Form.Item>
              </Col>
            }
            {settings.cart.checkoutFields.includes('last_name') &&
              <Col xs={24} lg={12}>
              <Form.Item
                name={['customer', 'last_name']}
                rules={[{ required: true, message: t('cart:checkout.form.last_name.warning') }]}
              >
                <Input size={'large'} placeholder={t('cart:checkout.form.last_name.placeholder')} />
              </Form.Item>
            </Col>
            }
          </Row>


          <Row gutter={10}>
            {settings.cart.checkoutFields.includes('street_name') &&
              <Col xs={24} lg={12}>
                <Form.Item
                  name={['customer', 'street_name']}
                  rules={[{ required: true, message: t('cart:checkout.form.street_name.warning') }]}
                >
                  <Input
                    size={'large'}
                    placeholder={t('cart:checkout.form.street_name.placeholder')}
                  />
                </Form.Item>
              </Col>
            }
            {settings.cart.checkoutFields.includes('door_number') &&
              <Col xs={24} lg={12}>
                <Form.Item
                  name={['customer', 'door_number']}
                  rules={[{ required: true, message: t('cart:checkout.form.door_number.warning') }]}
                >
                  <Input
                    size={'large'}
                    placeholder={t('cart:checkout.form.door_number.placeholder')}
                  />
                </Form.Item>
              </Col>
            }
          </Row>


          <Row gutter={10}>
            {settings.cart.checkoutFields.includes('postal_code') &&
              <Col xs={24} lg={12}>
                <Form.Item
                  name={['customer', 'postal_code']}
                  rules={[{ required: true, message: t('cart:checkout.form.postal_code.warning') }]}
                >
                  <Input
                    size={'large'}
                    placeholder={t('cart:checkout.form.postal_code.placeholder')}
                  />
                </Form.Item>
              </Col>
            }

            {settings.cart.checkoutFields.includes('city') &&
              <Col xs={24} lg={12}>
                <Form.Item
                  name={['customer', 'city']}
                  rules={[{ required: true, message: t('cart:checkout.form.city.warning') }]}
                >
                  <Input size={'large'} placeholder={t('cart:checkout.form.city.placeholder')}/>
                </Form.Item>
              </Col>
            }
          </Row>

          <Row gutter={10}>
            {settings.cart.checkoutFields.includes('country') &&
              <Col xs={24} lg={12}>
                <Form.Item
                  name={['customer', 'country']}
                  rules={[{ required: true, message: t('cart:checkout.form.country.warning') }]}
                >
                  <Select
                    showSearch={true}
                    filterOption={(input, option) =>
                      option ? option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 : false
                    }
                    size={'large'}
                    placeholder={t('cart:checkout.form.country.placeholder')}
                    onChange={this.onCountryChange}
                  >
                    {this.props.app.countries.map(country => {
                      return (
                        <Option key={country.id} value={country.id}>
                          {country.name}
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </Col>
            }
            {settings.cart.checkoutFields.includes('email') &&
              <Col xs={24} lg={12}>
                <Form.Item
                  name={['customer', 'email']}
                  rules={[
                    {
                      type: 'email',
                      message: t('cart:checkout.form.no_valid_email.warning')
                    },
                    {
                      required: true,
                      message: t('cart:checkout.form.email.warning')
                    }
                  ]}
                >
                  <Input size={'large'} placeholder={t('cart:checkout.form.email.placeholder')}/>
                </Form.Item>
              </Col>
            }
          </Row>


          <Row gutter={10}>
            {settings.cart.checkoutFields.includes('phone') &&
              <Col xs={24} lg={12}>
                <Form.Item
                  name={['customer', 'phone']}
                  rules={[{ required: true, message: t('cart:checkout.form.phone.warning') }]}
                >
                  <Input
                    size={'large'}
                    placeholder={t('cart:checkout.form.phone.placeholder')}
                    style={{ width: '100%' }}
                  />
                </Form.Item>
              </Col>
            }
            {settings.cart.checkoutFields.includes('company') &&
              <Col xs={24} lg={12}>
                <Form.Item name={['customer', 'company']} rules={[{ required: false }]}>
                  <Input
                    size={'large'}
                    placeholder={t('cart:checkout.form.company.placeholder')}
                    style={{ width: '100%' }}
                  />
                </Form.Item>
              </Col>
            }
          </Row>

          <Row style={{ marginBottom: 50 }}>
            <Col xs={24}>
              <em>{t('cart:checkout.form.personal_details.disclaimer')}</em>
            </Col>
          </Row>
        </div>

        {settings.cart.enableDifferentDeliveryAddress && (
          <Row>
            <hr style={{ borderStyle: 'dashed', width: '100%' }} />
            <Col xs={24} className={'form-action-highlight'}>
              <Form.Item
                valuePropName='checked'
                label={t('cart:checkout.form.different_delivery.label')}
                name={['customer', 'different_delivery_address']}
                rules={[{ required: false, type: 'boolean' }]}
              >
                <Switch onChange={e => this.switchDifferentDeliveryAddress(e)} />
              </Form.Item>
            </Col>
          </Row>
        )}

        {this.state.different_delivery_address && (
          <div className={'form-section'} style={{ marginBottom: 0 }}>
            <h2>{t('cart:checkout.form.delivery.title')}</h2>
            <hr />
            <Row gutter={10}>
              <Col xs={24} lg={12}>
                <Form.Item
                  name={['customer', 'delivery_address', 'first_name']}
                  rules={[
                    { required: true, message: t('cart:checkout.form.delivery.first_name.warning') }
                  ]}
                >
                  <Input
                    size={'large'}
                    placeholder={t('cart:checkout.form.delivery.first_name.placeholder')}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} lg={12}>
                <Form.Item
                  name={['customer', 'delivery_address', 'last_name']}
                  rules={[
                    { required: true, message: t('cart:checkout.form.delivery.last_name.warning') }
                  ]}
                >
                  <Input
                    size={'large'}
                    placeholder={t('cart:checkout.form.delivery.last_name.placeholder')}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={10}>
              <Col xs={24} lg={12}>
                <Form.Item
                  name={['customer', 'delivery_address', 'street_name']}
                  rules={[
                    {
                      required: true,
                      message: t('cart:checkout.form.delivery.street_name.warning')
                    }
                  ]}
                >
                  <Input
                    size={'large'}
                    placeholder={t('cart:checkout.form.delivery.street_name.placeholder')}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} lg={12}>
                <Form.Item
                  name={['customer', 'delivery_address', 'door_number']}
                  rules={[
                    {
                      required: true,
                      message: t('cart:checkout.form.delivery.door_number.warning')
                    }
                  ]}
                >
                  <Input
                    size={'large'}
                    placeholder={t('cart:checkout.form.delivery.door_number.placeholder')}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={10}>
              <Col xs={24} lg={12}>
                <Form.Item
                  name={['customer', 'delivery_address', 'postal_code']}
                  rules={[
                    {
                      required: true,
                      message: t('cart:checkout.form.delivery.postal_code.warning')
                    }
                  ]}
                >
                  <Input
                    size={'large'}
                    placeholder={t('cart:checkout.form.delivery.postal_code.placeholder')}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} lg={12}>
                <Form.Item
                  name={['customer', 'delivery_address', 'city']}
                  rules={[
                    { required: true, message: t('cart:checkout.form.delivery.city.warning') }
                  ]}
                >
                  <Input
                    size={'large'}
                    placeholder={t('cart:checkout.form.delivery.city.placeholder')}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={10}>
              <Col xs={24} lg={12}>
                <Form.Item
                  name={['customer', 'delivery_address', 'country']}
                  rules={[
                    { required: true, message: t('cart:checkout.form.delivery.country.warning') }
                  ]}
                >
                  <Select
                    showSearch={true}
                    filterOption={(input, option) =>
                      option
                        ? option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        : false
                    }
                    size={'large'}
                    placeholder={t('cart:checkout.form.delivery.country.placeholder')}
                    onChange={this.onDeliveryCountryChange}
                  >
                    {this.props.app.countries.map(country => {
                      return (
                        <Option key={country.id} value={country.id}>
                          {country.name}
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} lg={12}>
                <Form.Item
                  name={['customer', 'delivery_address', 'email']}
                  rules={[
                    {
                      type: 'email',
                      message: t('cart:checkout.form.delivery.no_valid_email.warning')
                    },
                    {
                      required: true,
                      message: t('cart:checkout.form.delivery.email.warning')
                    }
                  ]}
                >
                  <Input
                    size={'large'}
                    placeholder={t('cart:checkout.form.delivery.email.placeholder')}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={10}>
              <Col xs={24} lg={12}>
                <Form.Item
                  name={['customer', 'delivery_address', 'phone']}
                  rules={[
                    { required: true, message: t('cart:checkout.form.delivery.phone.warning') }
                  ]}
                >
                  <Input
                    size={'large'}
                    placeholder={t('cart:checkout.form.delivery.phone.placeholder')}
                    style={{ width: '100%' }}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} lg={12}>
                <Form.Item
                  name={['customer', 'delivery_address', 'company']}
                  rules={[{ required: false }]}
                >
                  <Input
                    size={'large'}
                    placeholder={t('cart:checkout.form.delivery.company.placeholder')}
                    style={{ width: '100%' }}
                  />
                </Form.Item>
              </Col>
            </Row>
          </div>
        )}

        {settings.navbar.userAccount.enable && !this.props.auth.isLoggedIn && (
          <Row>
            <Col xs={24} className={'form-action-highlight'} style={{ marginBottom: 0 }}>
              <Form.Item
                valuePropName='checked'
                label={t('cart:checkout.form.creacte_account.label')}
                name={'create_account'}
                rules={[{ required: false, type: 'boolean' }]}
              >
                <Switch onChange={e => this.switchCreateAccount(e)} />
              </Form.Item>

              {this.state.createAccount && (
                <>
                  {this.state.showPasswordError && (
                    <Alert
                      style={{ marginBottom: 10, marginTop: 10 }}
                      message={t('cart:checkout.form.password.alert.title')}
                      description={t('cart:checkout.form.password.alert.description')}
                      type={'warning'}
                      showIcon={true}
                      closable={false}
                    />
                  )}
                  <Form.Item
                    style={{ marginBottom: 10, marginTop: 10 }}
                    label={t('cart:checkout.form.password.label')}
                    name='password'
                    rules={[{ required: true, message: t('cart:checkout.form.password.warning') }]}
                  >
                    <Input
                      size={'large'}
                      type={'password'}
                      placeholder={t('cart:checkout.form.password.placeholder')}
                    />
                  </Form.Item>

                  <Form.Item
                    label={t('cart:checkout.form.password_repeat.label')}
                    name='password_repeat'
                    rules={[
                      { required: true, message: t('cart:checkout.form.password_repeat.warning') }
                    ]}
                  >
                    <Input
                      size={'large'}
                      type={'password'}
                      placeholder={t('cart:checkout.form.password_repeat.placeholder')}
                      onKeyUp={debounce(this.onKeyUp, 800)}
                    />
                  </Form.Item>

                  <Row>
                    <Col xs={{ span: 18, offset: 6 }}>
                      <Form.Item
                        style={{ marginBottom: 0 }}
                        name='news_letter'
                        valuePropName='checked'
                      >
                        <Checkbox>{t('cart:checkout.form.receive_newsletter.label')}</Checkbox>
                      </Form.Item>
                    </Col>
                  </Row>
                </>
              )}
            </Col>
            <hr style={{ borderStyle: 'dashed', width: '100%' }} />
          </Row>
        )}

        {costs.totalTotalWithVoucher > 0 && (
          <div className={'form-section'} style={{ marginTop: 50 }}>
            <h2>{t('cart:checkout.form.payment_options.title')}</h2>
            <hr />
            <Row gutter={10}>
              <Col xs={24}>
                <Form.Item
                  name='payment_method'
                  rules={[
                    {
                      required: true,
                      message: t('cart:checkout.form.payment_options.payment_method.warning')
                    }
                  ]}
                >
                  <Radio.Group
                    onChange={this.onPaymentMethodChange}
                    value={this.state.selectedPaymentMethod}
                  >
                    {this.getSupportedPaymentMethods().map((method, mindex) => {
                      // @ts-ignore
                      if (method.type === 'applepay' && !this.allowApplePay()) {
                        return undefined;
                      }

                      if (method.type === 'ideal') {
                        return (
                          <Row key={mindex}>
                            <Col xs={24} sm={12}>
                              <Radio
                                key={method.id}
                                value={method.type}
                                className={'payment-method'}
                                style={{ marginBottom: 0 }}
                              >
                                <Space>
                                  <img
                                    alt={`Payment method ${method.description}`}
                                    src={method.image}
                                  />
                                  <span>{method.description}</span>
                                </Space>
                              </Radio>
                            </Col>
                            <Col xs={24} sm={12}>
                              <div className={'payment-issuer'}>
                                <Form.Item
                                  noStyle={true}
                                  name='payment_issuer'
                                  rules={[
                                    this.state.selectedPaymentMethod === 'ideal'
                                      ? {
                                          required: true,
                                          message: t(
                                            'cart:checkout.form.payment_options.payment_issuer.warning'
                                          )
                                        }
                                      : { required: false }
                                  ]}
                                >
                                  {method.issuers.length > 0 && (
                                    <Select
                                      placeholder={t(
                                        'cart:checkout.form.payment_options.payment_issuer.placeholder'
                                      )}
                                      style={{ width: '100%' }}
                                    >
                                      {method.issuers.map(issuer => {
                                        return (
                                          <Option key={issuer.type} value={issuer.type}>
                                            {issuer.name}
                                          </Option>
                                        );
                                      })}
                                    </Select>
                                  )}
                                </Form.Item>
                              </div>
                            </Col>
                          </Row>
                        );
                      } else {
                        return (
                          <Radio key={method.id} value={method.type} className={'payment-method'}>
                            <Space>
                              <img
                                alt={`Payment method ${method.description}`}
                                src={method.image}
                              />
                              <span>{method.description}</span>
                            </Space>
                          </Radio>
                        );
                      }
                    })}
                  </Radio.Group>
                </Form.Item>
              </Col>
            </Row>
          </div>
        )}

        <Row gutter={10}>
          <Col xs={24}>
            <Form.Item style={{ marginBottom: 0 }} name='news_letter' valuePropName='checked'>
              <Checkbox>{t('cart:checkout.form.receive_newsletter.label')}</Checkbox>
            </Form.Item>
            <Form.Item
              name='agreement'
              valuePropName='checked'
              rules={[
                {
                  validator: (_, value) =>
                    value
                      ? Promise.resolve()
                      : Promise.reject(t('cart:checkout.form.agreement.should_accept.warning'))
                }
              ]}
            >
              <Checkbox>
                <span
                  dangerouslySetInnerHTML={{
                    __html: t('cart:checkout.form.agreement.label', {
                      link: `<a href="/terms-and-conditions" rel='noopener noreferrer' target="_blank">${linkText}</a>`,
                      interpolation: { escapeValue: false }
                    })
                  }}
                />
              </Checkbox>
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={10}>
          <Col xs={24}>
            <Form.Item name={'proceed_to_payment'}>
              <Button
                disabled={this.state.loading}
                block={true}
                size={'large'}
                type='primary'
                htmlType='submit'
              >
                {this.state.loading && (
                  <>
                    <Spin indicator={antIcon} />
                    &nbsp;
                  </>
                )}
                {t('cart:checkout.button.proceed_to_payment')} <ArrowRightOutlined />
              </Button>
            </Form.Item>
          </Col>
          <Link to={settings.products.shoppingPage}>
            <ArrowLeftOutlined /> {t('cart:checkout.button.back_to_products')}
          </Link>
        </Row>
      </Form>
    );
  }
}

const mapStateToProps = (state: any) => ({
  app: state.app,
  auth: state.auth,
  cart: state.cart
});

const mapDispatchToProps = (dispatch: any) => ({
  setCountry: (country: Country) => dispatch(setCountry(country))
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(CheckoutComponent));
