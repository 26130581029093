import React, { useState } from 'react';
import { BreadCrumbItem, PageSection } from '../../../types';
import BreadcrumbsComponent from '../../navigation/breadcrumbs.component';
import QueueAnim from 'rc-queue-anim';
import { useTranslation } from 'react-i18next';
import { Parallax } from 'react-parallax';
import { Link } from 'react-router-dom';
import { RightOutlined } from '@ant-design/icons';
import { Button, Carousel } from 'antd';
import { settings } from '../../../../settings';
import { ReactComponent as ArrowDown } from '../../../assets/icon-arrow-down.svg';

interface Props {
  section: PageSection;
}

const HeaderModule = (props: Props) => {
  const [activeImage, setActiveImage] = useState(0);
  const { t } = useTranslation();

  const sectionSettings = props.section.settings;

  const getHeaderContent = () => {
    let breadcrumbs: BreadCrumbItem[] = [];
    if (window.location.pathname !== '/') {
      breadcrumbs = [
        { title: t('app:breadcrumbs.home'), link: '/' },
        { title: sectionSettings.title[props.section.language], link: null }
      ];
    }

    return (
      <>
        {sectionSettings.title[props.section.language] && (
          <>
            <QueueAnim  className={'queue-anim-container'} type={'scale'} duration={sectionSettings.animate_text ? 1500 : 0}>
              {sectionSettings.title[props.section.language] &&
                <h1
                  key={1}
                  dangerouslySetInnerHTML={{
                    __html: sectionSettings.title[props.section.language]
                  }}
                />
              }
              {sectionSettings.subtitle[props.section.language] &&
                <em
                  key={2}
                  dangerouslySetInnerHTML={{
                    __html: sectionSettings.subtitle[props.section.language]
                  }}
                />
              }
              {sectionSettings.button &&
                sectionSettings.button.text &&
                sectionSettings.button.text[props.section.language] && (
                  <div key={3}>
                    <Link to={sectionSettings.button.url}>
                      <Button size={'large'} type={'primary'}>
                        {sectionSettings.button.text[props.section.language]} <RightOutlined />
                      </Button>
                    </Link>
                  </div>
                )}
            </QueueAnim>
          </>
        )}
        {settings.showBreadcrumbs && <BreadcrumbsComponent items={breadcrumbs} />}
      </>
    );
  };

  const generateParallaxImageComponent = (img: string) => {
    return (
      <Parallax
        disabled={!sectionSettings.parallax}
        bgImage={img}
        bgImageAlt={
          sectionSettings.background_image.alt ? sectionSettings.background_image.alt : ''
        }
        strength={settings.parallaxStrength}
      >
        <div
          key={props.section.id}
          className={`content-header header-${sectionSettings.size} ${
            sectionSettings.overlay ? 'with-overlay' : ''
          }`}
        >
          {getHeaderContent()}

          {(sectionSettings.size === 'medium-with-fluid' ||
            sectionSettings.size === 'large-with-fluid') && (
            <img
              className={'fluid-decoration-element'}
              alt={'Fluid decorative design element'}
              src={require('../../../../assets/fluid-decoration.png').default}
            />
          )}
        </div>
      </Parallax>
    );
  };

  const generateStaticImageComponent = (img: string) => {
    return (
      <div
        key={props.section.id}
        className={`content-header header-${sectionSettings.size} ${
          sectionSettings.overlay ? 'with-overlay' : ''
        }`}
      >
        <img alt={'Default header image for ' + process.env.REACT_APP_NAME} className={'single-bg'} src={img} />

        {getHeaderContent()}

        {(sectionSettings.size === 'medium-with-fluid' ||
          sectionSettings.size === 'large-with-fluid') && (
          <img
            className={'fluid-decoration-element'}
            alt={'Fluid decorative design element'}
            src={require('../../../../assets/fluid-decoration.png').default}
          />
        )}
      </div>
    );
  };

  const generateCarouselComponent = () => {
    return (
      <div
        key={props.section.id}
        className={`content-header header-${sectionSettings.size} ${
          sectionSettings.overlay ? 'with-overlay' : ''
        }`}
      >
        <Carousel
          beforeChange={onChange}
          autoplay={true}
          dots={false}
          pauseOnHover={false}
          infinite={true}
          autoplaySpeed={5000}
          speed={500}
          fade={true}
          cssEase={'linear'}
        >
          {sectionSettings.images.map((image: string, index: number) => {
            return <img alt={`Promo image ${index} for ${process.env.REACT_APP_NAME}`} key={index} src={process.env.REACT_APP_API_URL + image} />;
          })}
        </Carousel>
        {getHeaderContent()}

        <div className={'image-list-container'}>
          <ul className={'image-list'}>
            {sectionSettings.images.length > 1 &&
              sectionSettings.images.map((picture: string, pindex: number) => {
                return (
                  <li key={pindex} className={pindex === activeImage ? 'active' : ''}>
                    {(pindex + 1).toLocaleString('en-US', {
                      minimumIntegerDigits: 2,
                      useGrouping: false
                    })}
                  </li>
                );
              })}
          </ul>
          <ArrowDown />
        </div>

        {(sectionSettings.size === 'medium-with-fluid' ||
          sectionSettings.size === 'large-with-fluid') && (
          <img
            className={'fluid-decoration-element'}
            alt={'Fluid decorative design element'}
            src={require('../../../../assets/fluid-decoration.png').default}
          />
        )}
      </div>
    );
  };

  const generateVideoHeader = (imageUrl: string) => {
    return (
      <div
        key={props.section.id}
        className={`content-header header-${sectionSettings.size} ${
          sectionSettings.overlay ? 'with-overlay' : ''
        }`}
      >
        <video loop={true} muted={true} playsInline={true} autoPlay={true} src={imageUrl} />

        {getHeaderContent()}

        {(sectionSettings.size === 'medium-with-fluid' ||
          sectionSettings.size === 'large-with-fluid') && (
          <img
            className={'fluid-decoration-element'}
            alt={'Fluid decorative design element'}
            src={require('../../../../assets/fluid-decoration.png').default}
          />
        )}
      </div>
    );
  };

  const onChange = (currentSlide: number, nextSlide: number) => {
    setActiveImage(nextSlide);
  };

  const getImageUrl = () => {
    const images = sectionSettings.images;
    if (images && images.length > 0) {
      if (sectionSettings.background_image.non_api) {
        return sectionSettings.images[0];
      }
      return process.env.REACT_APP_API_URL + sectionSettings.images[0];
    }
    return require('../../../../assets/header-main.jpg').default;
  };

  const imageUrl = getImageUrl();

  if (imageUrl.includes('.mp4')) {
    return generateVideoHeader(imageUrl);
  } else if (sectionSettings.images && sectionSettings.images.length > 1) {
    return generateCarouselComponent();
  } else if (sectionSettings.parallax) {
    return generateParallaxImageComponent(imageUrl);
  }

  return generateStaticImageComponent(imageUrl);
};

export default HeaderModule;
