import React, { Component } from 'react';
import { Button, Col, Row } from 'antd';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { IAppState } from '../../redux/states/app';
import { acceptCookieConsent } from '../../redux/actions/app';
import { WithTranslation, withTranslation } from 'react-i18next';
import '../../styles/cookie-banner.less';
import { settings } from '../../../settings';

interface Props extends WithTranslation {
  history: any;
  acceptCookieConsent: typeof acceptCookieConsent;
  app: IAppState;
}

class CookieBannerComponent extends Component<Props> {
  render() {
    const t = this.props.i18n.t.bind(this.props.i18n);
    const linkText = t('app:cookie_banner.link.text');

    return (
      <>
        {!this.props.app.cookieConsent && (
          <div id={'cookie-banner'}>
            <Row>
              <Col xs={24} md={18}>
                <div id={'cookie-banner-content'}>
                  <h4>{t('app:cookie_banner.title')}</h4>
                  <p>
                    <span
                      dangerouslySetInnerHTML={{
                        __html: t('app:cookie_banner.description', {
                          link: `<a href="/cookie-policy" rel='noopener noreferrer' target="_blank">${linkText}</a>`,
                          interpolation: { escapeValue: false }
                        })
                      }}
                    />
                  </p>
                </div>
              </Col>
              <Col xs={24} md={6}>
                <div id={'cookie-banner-buttons'}>
                  <Button
                    onClick={() => this.props.acceptCookieConsent()}
                    id={'cookie-banner-accept'}
                    block={true}
                    size={'large'}
                    type={'default'}
                  >
                    {t('app:cookie_banner.button.ok.text')}
                  </Button>
                  {settings.cookies.enableLearnMoreButton &&
                    <Link to={settings.cookies.cookiePolicyUrl}>
                      <Button
                        block={true}
                        size={'large'}
                        type={'default'}
                        id={'cookie-banner-learn-more'}
                      >
                        {t('app:cookie_banner.button.learn_more.text')}
                      </Button>
                    </Link>
                  }
                </div>
              </Col>
            </Row>
          </div>
        )}
      </>
    );
  }
}

const mapStateToProps = (state: any) => ({
  app: state.app
});

const mapDispatchToProps = (dispatch: any) => ({
  acceptCookieConsent: () => dispatch(acceptCookieConsent())
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withTranslation()(CookieBannerComponent))
);
