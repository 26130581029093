import React from 'react';
import { Button, Col, Row, Space } from 'antd';
import { withRouter } from 'react-router-dom';
import { ICartState } from '../../redux/states/cart';
import { IAppState } from '../../redux/states/app';
import { FormInstance } from 'antd/lib/form';
import { AuthState, User } from '../../redux/states/user';
import { LoopBack } from '../../redux/api';
import { updateUser } from '../../redux/actions/auth';
import Swal from 'sweetalert2';
import { Country, Order } from '../../types';
import { setCountry } from '../../redux/actions/app';
import FileDownload from 'js-file-download';
import { withTranslation, WithTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import moment from 'moment';

interface Props extends WithTranslation {
  app: IAppState;
  auth: AuthState;
  cart: ICartState;
  history: any;
  updateUser: typeof updateUser;
  setCountry: typeof setCountry;
}

interface State {
  orders: Order[];
}

class ProfileOrdersComponent extends React.Component<Props, State> {
  formRef = React.createRef<FormInstance>();

  constructor(props: Props) {
    super(props);

    this.state = {
      orders: []
    };

    new LoopBack(this.props.auth.token)
      .get('/users/my-orders')
      .then(res => {
        this.setState({ orders: res });
      })
      .catch(err => {});

    window.scrollTo(0, 0);
  }

  componentDidMount(): void {}

  generatePDF = (orderId: number, orderNumber: number) => {
    const t = this.props.i18n.t.bind(this.props.i18n);

    new LoopBack(this.props.auth.token)
      .download(`/users/invoice/${orderId}`)
      .then(res => {
        FileDownload(res, `invoice-${orderNumber}.pdf`);
      })
      .catch(err => {
        Swal.fire({
          title: t('auth:profile.popup.invoice.error.title'),
          html: t('auth:profile.popup.invoice.error.description'),
          icon: 'error',
          confirmButtonText: t('auth:profile.popup.invoice.error.button.ok')
        });
      });
  };

  render() {
    const t = this.props.i18n.t.bind(this.props.i18n);

    return !this.props.auth.user.email_verified ? (
      <span>
        <em>{t('auth:profile.section.order_history.not_verified.description')}</em>
      </span>
    ) : (
      <>
        {this.state.orders.length === 0 ? (
          <span>
            <em>{t('auth:profile.section.order_history.no_orders.description')}</em>
          </span>
        ) : (
          this.state.orders.map(order => {
            return (
              <div className={'order-history-item'} key={order.id}>
                <Row className={'order-history-item-header'}>
                  <Col xs={12}>
                    <span>
                      <strong>
                        {t('auth:profile.section.order_history.table.order_number.label')}
                      </strong>{' '}
                      #{order.id}
                    </span>
                  </Col>
                  <Col xs={12} style={{ textAlign: 'right' }}>
                    <Space>
                      <span>
                        <strong>
                          {t('auth:profile.section.order_history.table.order_date.label')}
                        </strong>{' '}
                        {moment(order.created).format('dd DD MMM YYYY HH:mm')}
                      </span>
                      <Button
                        type={'default'}
                        onClick={() => this.generatePDF(order.id, order.order_number)}
                        size={'small'}
                      >
                        {t('auth:profile.section.order_history.table.button.invoice.text')}
                      </Button>
                    </Space>
                  </Col>
                </Row>

                {order.products.map((item, iindex) => {
                  const price = item.product.price * order.currency_rate;
                  return (
                    <Row key={iindex} className={'order-history-item-product'}>
                      <Col xs={12}>{item.product.name[this.props.app.language.code]}</Col>
                      <Col xs={4}>
                        <strong>{t('auth:profile.section.order_history.table.size.label')}</strong>{' '}
                        {item.size}
                      </Col>
                      <Col xs={4}>
                        <strong>
                          {t('auth:profile.section.order_history.table.quantity.label')}
                        </strong>{' '}
                        {item.quantity}x
                      </Col>
                      <Col xs={4} className={'order-history-item-product-price'}>
                        {`${order.currency} ${price.toFixed(2).replace('.', ',')}`}
                      </Col>
                    </Row>
                  );
                })}
                <hr />
                <Row className={'order-history-item-total'}>
                  <Col xs={{ offset: 12, span: 8 }} className={'order-history-item-total-label'}>
                    <strong>{t('auth:profile.section.order_history.table.subtotal.label')}</strong>
                  </Col>
                  <Col xs={4} className={'order-history-item-total-price'}>
                    {`${order.currency} ${order.subtotal_sum.toFixed(2).replace('.', ',')}`}
                  </Col>
                </Row>
                <Row className={'order-history-item-total'}>
                  <Col xs={{ offset: 12, span: 8 }} className={'order-history-item-total-label'}>
                    <strong>{t('auth:profile.section.order_history.table.tax.label')}</strong>
                  </Col>
                  <Col xs={4} className={'order-history-item-total-price'}>
                    {`${order.currency} ${(order.total_sum - order.subtotal_sum)
                      .toFixed(2)
                      .replace('.', ',')}`}
                  </Col>
                </Row>
                <Row className={'order-history-item-total'}>
                  <Col xs={{ offset: 12, span: 8 }} className={'order-history-item-total-label'}>
                    <strong>{t('auth:profile.section.order_history.table.total.label')}</strong>
                  </Col>
                  <Col xs={4} className={'order-history-item-total-price'}>
                    {`${order.currency} ${order.total_sum.toFixed(2).replace('.', ',')}`}
                  </Col>
                </Row>
              </div>
            );
          })
        )}
      </>
    );
  }
}

const mapStateToProps = (state: any) => ({
  app: state.app,
  auth: state.auth,
  cart: state.cart
});

const mapDispatchToProps = (dispatch: any) => ({
  updateUser: (user: User, token: string) => dispatch(updateUser(user, token)),
  setCountry: (country: Country) => dispatch(setCountry(country))
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ProfileOrdersComponent))
);
