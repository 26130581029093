import { AllCosts, CartItem, Currency } from '../types';

export const calculateAllCosts = (props: any): AllCosts => {
  let totalTax = 0;
  let totalShipping = 0;
  let totalShippingTax = 0;
  let freeShipping = false;
  let totalIncludingTax = 0;
  let totalShippingIncludingTax = 0;
  let totalTotal = 0;
  let preferredCurrencyObject = null;
  let totalPrice = 0;
  let totalVoucherReduction = 0;

  const products = props.cart.items;
  const events = props.cart.events;
  const giftCards = props.cart.giftCards;
  const voucher = props.cart.voucher;

  let subtractEvent = false;
  let subtractedEvent = false;
  let subtractCategory = '';
  if (voucher && voucher.voucher && voucher.exists && voucher.voucher.part_of_gift_card) {
    subtractEvent = true;
    subtractCategory = voucher.voucher.category;
  }

  if (products && products.length > 0) {
    totalPrice += products.reduce(
      (total: number, item: CartItem) =>
        total + (item.product.price + item.size.price) * item.quantity,
      0
    );
  }


  if (events && events.length > 0) {
    totalPrice += events.reduce((total: number, item: CartItem) => {
      let quantity = item.quantity;
      if (subtractEvent && !subtractedEvent && item.event.event_type === subtractCategory) {
        subtractedEvent = true;
        totalVoucherReduction = item.event.price;
      }
      return total + (item.event.price * quantity) + item.extra_amount;
    }, 0);
  }

  let totalNonAffectablePrice = 0;
  if (giftCards && giftCards.length > 0) {
    totalNonAffectablePrice += giftCards.reduce((total: number, item: CartItem) => {
      return total + item.giftCard.price * item.quantity;
    }, 0);
  }

  if (props.app.country) {
    const preferredCurrency = props.app.country.preferred_currency;
    const currencies: Currency[] = props.app.currencies;
    const preferredCurrencyObjects = currencies.filter(
      currency => currency.code === preferredCurrency
    );

    if (preferredCurrencyObjects.length > 0 && props.app.currency) {
      preferredCurrencyObject = preferredCurrencyObjects[0];

      const taxRate = props.app.country.tax_rate;
      const currencyRate = preferredCurrencyObject.rate;
      const shippingCostsLimit = props.app.country.shipping_costs_limit / currencyRate;
      const standardShippingCosts = props.app.country.default_shipping_costs / currencyRate;
      const trackedShippingCosts = props.app.country.tracked_shipping_costs / currencyRate;
      const deliveryOption = props.deliveryType;

      totalShipping = deliveryOption === 'tracked' ? trackedShippingCosts : standardShippingCosts;
      totalTax = (totalPrice / 100) * taxRate;
      totalIncludingTax = totalPrice + totalTax;

      if (totalIncludingTax >= shippingCostsLimit) {
        freeShipping = true;
        if (deliveryOption === 'tracked') {
          totalShipping = trackedShippingCosts - standardShippingCosts;
        } else {
          totalShipping = 0;
        }
      }

      // recalculate total tax and total including tax, but now
      // with the non affectable price from giftcards included
      let totalNonAffectablePriceTax = (totalNonAffectablePrice / 100) * taxRate;
      totalTax += totalNonAffectablePriceTax;
      totalIncludingTax = totalPrice + totalTax;

      totalShippingTax = (totalShipping / 100) * taxRate;
      totalShippingIncludingTax = totalShipping + totalShippingTax;
      totalTotal = totalShippingIncludingTax + totalIncludingTax;
    }
  }

  let totalTotalWithVoucher = totalTotal;

  if (voucher && voucher.voucher && voucher.exists) {
    if (voucher.voucher.type === 'percentage') {
      if (!voucher.voucher.part_of_gift_card) {
        totalVoucherReduction = (totalTotalWithVoucher / 100) * voucher.voucher.value;
      }
    } else {
      const currencies: Currency[] = props.app.currencies;
      if (currencies && currencies.length > 0) {
        const cIndex = currencies.findIndex(currency => currency.code === voucher.voucher.currency);
        if (cIndex > -1) {
          const currency = currencies[cIndex];
          totalVoucherReduction = voucher.voucher.value * currency.rate;
        }
      }
    }

    totalTotalWithVoucher -= totalVoucherReduction;
  }

  return {
    totalCosts: totalPrice + totalNonAffectablePrice,
    totalTax: totalTax,
    totalShipping: totalShipping,
    totalShippingIncludingTax: totalShippingIncludingTax,
    freeShipping: freeShipping,
    totalIncludingTax: totalIncludingTax,
    totalShippingTax: totalShippingTax,
    totalTotal: totalTotal + totalNonAffectablePrice,
    preferredCurrencyObject: preferredCurrencyObject,
    totalVoucherReduction: totalVoucherReduction,
    totalTotalWithVoucher: totalTotalWithVoucher + totalNonAffectablePrice
  };
};
