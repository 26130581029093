import React from 'react';
import { Button, Checkbox, Col, Drawer, Dropdown, Menu, Row, Slider, Spin } from 'antd';
import {
  Color,
  PageSection,
  Product,
  ProductCategory,
  ProductOccasion,
  ProductStyle,
  ProductTheme,
  ProductType,
  Size
} from '../../../types';
import { LoopBack } from '../../../redux/api';
import { Link, withRouter } from 'react-router-dom';
import { calculatePrice } from '../../../helpers/price.helper';
import { connect } from 'react-redux';
import { AuthState } from '../../../redux/states/user';
import { addItemToWishList, removeItemFromWishList } from '../../../redux/actions/wishlist';
import { CaretDownOutlined, CaretRightOutlined, SwapOutlined } from '@ant-design/icons';
import { IWishListState } from '../../../redux/states/wishlist';
import { IAppState } from '../../../redux/states/app';
import { prerenderIsReady } from '../../../helpers/prerender-ready.helper';
import { WithTranslation, withTranslation } from 'react-i18next';
import { settings } from '../../../../settings';
import { hideCategoriesBar, showCategoriesBar } from '../../../redux/actions/app';
import { ReactComponent as ArrowRight } from '../../../../assets/icon-arrow-right.svg';
import ProductsBoxedProduct from '../products-boxed-product.component';
import { ICartState } from '../../../redux/states/cart';
import * as qs from 'query-string';
import moment from 'moment';
import '../../../styles/products-boxed.less';
import QueueAnim from 'rc-queue-anim';
import Swal from 'sweetalert2';

interface Props extends WithTranslation {
  history: any;
  app: IAppState;
  location: any;
  match: any;
  auth: AuthState;
  wishlist: IWishListState;
  addItemToWishList: typeof addItemToWishList;
  removeItemFromWishList: typeof removeItemFromWishList;
  showCategoriesBar: typeof showCategoriesBar;
  hideCategoriesBar: typeof hideCategoriesBar;
  section: PageSection;
  cart: ICartState;
}

interface State {
  products: Product[];
  colors: Color[];
  productTypes: ProductType[];
  productCategories: ProductCategory[];
  sizesFilters: number[];
  colorFilters: number[];
  productThemes: ProductTheme[];
  productStyles: ProductStyle[];
  productOccasions: ProductOccasion[];
  productTypeFilters: number[];
  productCategoryFilters: number[];
  productStyleFilters: number[];
  productOccasionFilters: number[];
  productThemeFilters: number[];
  priceRange: [number, number];
  highestPrice: number;
  loadingProducts: boolean;
  sizes: Size[];
  measurement_unit: string;
  filterDrawer: boolean;
  path: string;
  sortBy: string | null;
  filterNewArrivals: boolean;
  subtitle: string;
}

class WebshopBoxedModule extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    let queryParams: any = qs.parse(window.location.search);
    const paramKeys = Object.keys(queryParams);

    const filterNewArrivals =
      paramKeys.indexOf('filter') > -1 && queryParams['filter'] === 'new-arrivals';

    const subtitle = this.getDefaultSubtitle();

    this.state = {
      path: window.location.href,
      products: [],
      colors: [],
      productTypes: [],
      productCategories: [],
      productOccasions: [],
      productStyles: [],
      productCategoryFilters: this.getCategoriesFromSettings(),
      sizesFilters: [],
      colorFilters: [],
      productThemes: [],
      productTypeFilters: this.getProductTypesFromSettings(),
      productThemeFilters: queryParams['product-theme'] ? [-1] : [],
      productStyleFilters: [],
      productOccasionFilters: [],
      priceRange: [0, -1],
      highestPrice: -1,
      loadingProducts: true,
      filterDrawer: false,
      filterNewArrivals: filterNewArrivals,
      subtitle: filterNewArrivals ? 'New Arrivals' : subtitle ? subtitle : 'All Products',
      sizes: [],
      sortBy: null,
      measurement_unit: this.props.app.country
        ? this.props.app.country.preferred_measurement_unit
        : 'imperial'
    };

    window.scrollTo(0, 0);

    new LoopBack(this.props.auth.token).get('/colors').then(res => {
      this.setState({ colors: res });
    });

    new LoopBack()
      .get('/product-occasions')
      .then((res: ProductOccasion[]) => {
        const filters = this.extractOccasionFilterFromURL(res);
        this.setState({
          productOccasions: res,
          productOccasionFilters: filters.filters
          // subtitle: filters.subtitle
        });
      })
      .catch(err => {
        this.setState({ productOccasions: [] });
      });

    new LoopBack()
      .get('/product-styles')
      .then((res: ProductStyle[]) => {
        this.setState({ productStyles: res });

        let queryParams: any = qs.parse(window.location.search);
        const paramKeys: any = Object.keys(queryParams);

        if (paramKeys.indexOf('product-style') > -1) {
          const tId = res.findIndex(
            style =>
              style.name[this.props.app.language.code].toLowerCase() ===
              queryParams['product-style']
          );

          if (tId > -1) {
            const styleId = res[tId].id;
            this.setState({
              productStyleFilters: [styleId]
              // subtitle: res[tId].name[this.props.app.language.code]
            });
          }
        }
      })
      .catch(err => {
        this.setState({ productStyles: [] });
      });

    new LoopBack()
      .get('/product-themes')
      .then((res: ProductTheme[]) => {
        const themeFilters = this.extractThemeFilterFromURL(res);
        this.setState({
          productThemes: res,
          productThemeFilters: themeFilters.filters
          // subtitle: subtitle
        });
      })
      .catch(err => {
        this.setState({ productThemes: [] });
      });

    new LoopBack()
      .get('/product-categories')
      .then((res: ProductCategory[]) => {
        const filters = this.extractCategoryFilterFromURL(res);
        this.setState({
          productCategories: res,
          productCategoryFilters: filters.filters
          // subtitle: filters.subtitle
        });
      })
      .catch(err => {
        this.setState({ productCategories: [] });
      });

    new LoopBack()
      .get('/product-types')
      .then(res => {
        this.setState({ productTypes: res });
      })
      .catch(err => {
        this.setState({ productTypes: [] });
      });

    new LoopBack()
      .get('/sizes')
      .then(res => {
        this.setState({ sizes: res });
      })
      .catch(err => {
        this.setState({ sizes: [] });
      });

    this.setFilters();
    let filter: any = { where: { active: true } };
    this.getProducts(filter, true);
  }

  getDefaultSubtitle = () => {
    const subtitle = this.props.section.settings.title[this.props.app.language.code];
    return subtitle;
  };

  getCategoriesFromSettings = () => {
    const productCategories = this.props.section.settings.product_categories
      ? this.props.section.settings.product_categories
      : [];
    return productCategories;
  };

  getProductTypesFromSettings = () => {
    const productTypes = this.props.section.settings.product_types
      ? this.props.section.settings.product_types
      : [];
    return productTypes;
  };

  extractOccasionFilterFromURL = (occasions: ProductOccasion[]) => {
    let queryParams: any = qs.parse(window.location.search);
    let filters: { filters: any[]; subtitle: string } = { filters: [], subtitle: '' };

    const occasionName = queryParams['product-occasion'];
    if (occasionName) {
      const catName = occasionName.split('-').join(' ');
      const catId = occasions.findIndex(
        occasion =>
          occasion.name[settings.languages[0].code].toLowerCase() === catName.toLowerCase()
      );

      if (catId > -1) {
        const occasionId = occasions[catId].id;

        filters.filters = [occasionId];
        filters.subtitle = occasions[catId].name[this.props.app.language.code];
      } else {
        filters.filters = [-1];
        filters.subtitle = '-';

        const t = this.props.i18n.t.bind(this.props.i18n);

        Swal.fire({
          title: t('products:popup.occasion_not_found.title'),
          html: t('products:popup.occasion_not_found.description'),
          icon: 'warning',
          confirmButtonText: t('products:popup.occasion_not_found.ok')
        });
      }
    }
    return filters;
  };

  extractCategoryFilterFromURL = (categories: ProductCategory[]) => {
    let queryParams: any = qs.parse(window.location.search);
    let filters: { filters: any[]; subtitle: string } = { filters: [], subtitle: '' };

    const categoryName = queryParams['product-category'];
    if (categoryName) {
      const catName = categoryName.split('-').join(' ');
      const catId = categories.findIndex(
        category =>
          category.name[settings.languages[0].code].toLowerCase() === catName.toLowerCase()
      );

      if (catId > -1) {
        const categoryId = categories[catId].id;

        filters.filters = [categoryId];
        filters.subtitle = categories[catId].name[this.props.app.language.code];
      } else {
        filters.filters = [-1];
        filters.subtitle = '-';

        const t = this.props.i18n.t.bind(this.props.i18n);

        Swal.fire({
          title: t('products:popup.category_not_found.title'),
          html: t('products:popup.category_not_found.description'),
          icon: 'warning',
          confirmButtonText: t('products:popup.category_not_found.ok')
        });
      }
    } else {
      this.setState({
        productCategoryFilters: this.getCategoriesFromSettings()
      });
    }

    return filters;
  };

  extractThemeFilterFromURL = (themes: ProductTheme[]) => {
    let queryParams: any = qs.parse(window.location.search);
    let filters: { filters: number[]; subtitle: string } = { filters: [], subtitle: '' };
    const themeName = queryParams['product-theme'];

    if (themeName) {
      const catName = themeName.split('-').join(' ');
      const catId = themes.findIndex(
        theme => theme.name[settings.languages[0].code].toLowerCase() === catName.toLowerCase()
      );

      if (catId > -1) {
        const themeId = themes[catId].id;

        filters.filters = [themeId];
        filters.subtitle = themes[catId].name[this.props.app.language.code];
      } else {
        filters.filters = [-1];
        filters.subtitle = '-';

        const t = this.props.i18n.t.bind(this.props.i18n);

        Swal.fire({
          title: t('products:popup.theme_not_found.title'),
          html: t('products:popup.theme_not_found.description'),
          icon: 'warning',
          confirmButtonText: t('products:popup.theme_not_found.ok')
        });
      }
    }

    return filters;
  };

  getProducts = (filter: any, init = false) => {
    new LoopBack()
      .get(`/products?filter=${JSON.stringify(filter)}`)
      .then(res => {
        const highestPrice = Math.max.apply(
          Math,
          res.map((product: Product) => {
            return calculatePrice(product.price, this.props.app.currency, this.props.app.country);
          })
        );

        const roundUpHighestPrice = Math.round(highestPrice / 100) * 100;

        this.setState(
          { products: res, highestPrice: roundUpHighestPrice, loadingProducts: false },
          () => {
            prerenderIsReady();
          }
        );
      })
      .catch(err => {
        this.setState({ loadingProducts: false });
        prerenderIsReady();
      });
  };

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any): void {
    if (window.location.href !== this.state.path) {
      this.setState(
        { path: window.location.href, loadingProducts: true, subtitle: this.getDefaultSubtitle() },
        () => {
          this.clearFilters();
          this.updateFilters();
        }
      );
    }
  }

  setFilters = () => {
    let queryParams: any = qs.parse(window.location.search);

    const newState: any = {
      // productTypeFilters: [],
      // productThemeFilters: [],
      // productStyleFilters: [],
      // productOccasionFilters: [],
      filterNewArrivals: false
      // colorFilters: [],
      // sizesFilters: []
    };

    // if (paramKeys.indexOf('product-type') > -1) {
    //   newState.productTypeFilters = queryParams['product-type'];
    // }

    //
    // if (paramKeys.indexOf('color') > -1) {
    //   newState.colorFilters = queryParams['color'];
    // }
    //
    // if (paramKeys.indexOf('size') > -1) {
    //   newState.sizesFilters = queryParams['size'];
    // }
    //
    this.setState({ ...newState });
  };

  updateFilters = () => {
    const occasionFilter = this.extractOccasionFilterFromURL(this.state.productOccasions);
    const categoryFilters = this.extractCategoryFilterFromURL(this.state.productCategories);
    const themeFilters = this.extractThemeFilterFromURL(this.state.productThemes);
    this.setState({
      loadingProducts: false,
      productCategoryFilters: categoryFilters.filters,
      productThemeFilters: themeFilters.filters,
      subtitle: themeFilters.subtitle
    });
  };

  gtagView = (products: Product[]) => {
    // @ts-ignore
    window.gtag('event', 'view_item_list', {
      items: products.map((product, index) => {
        return [
          {
            id: product.id,
            name: `${product.name[this.props.app.language.code]}${
              product.subtitle ? ` - ${product.subtitle[this.props.app.language.code]}` : ''
            }`,
            list_name: 'Webshop',
            brand: settings.appName,
            category: product.product_type_id,
            variant: product.color_id,
            list_position: index + 1,
            price: product.price
          }
        ];
      })
    });
  };

  onSizesSelect = (values: any) => {
    this.setState({ sizesFilters: values });
  };

  onColorsSelect = (values: any) => {
    this.setState({ colorFilters: values });
  };

  onProductTypesSelect = (values: any) => {
    this.setState({ productTypeFilters: values });
  };

  onPriceRangeSelect = (values: any) => {
    this.setState({ priceRange: values });
  };

  clearFilters = () => {
    let queryParams: any = qs.parse(window.location.search);
    const paramKeys = Object.keys(queryParams);

    const filterNewArrivals =
      paramKeys.indexOf('filter') > -1 && queryParams['filter'] === 'new-arrivals';

    const themeFilters = this.extractThemeFilterFromURL(this.state.productThemes);

    this.setState({
      filterNewArrivals: filterNewArrivals,
      subtitle: filterNewArrivals ? 'New Arrivals' : this.state.subtitle,
      colorFilters: [],
      productTypeFilters: this.getProductTypesFromSettings(),
      productCategoryFilters: this.getCategoriesFromSettings(),
      productThemeFilters: themeFilters.filters,
      productStyleFilters: [],
      productOccasionFilters: [],
      sizesFilters: [],
      priceRange: [0, this.state.highestPrice]
    });
  };

  filteredProducts = () => {
    let products = this.state.products;

    let minProd = products.sort((a, b) => (moment(a.created) > moment(b.created) ? -1 : 1));

    if (this.state.filterNewArrivals) {
      products = products.filter(product => moment().diff(product.created, 'months') < 3);
      if (products.length === 0) {
        products = minProd.slice(0, 8);
      }
    }

    if (this.state.sizesFilters.length > 0) {
      products = products.filter(product =>
        product.sizes.some(item => this.state.sizesFilters.includes(item))
      );
    }

    if (this.state.colorFilters.length > 0) {
      products = products.filter(product => this.state.colorFilters.includes(product.color_id));
    }

    if (this.state.productTypeFilters.length > 0) {
      products = products.filter(product =>
        this.state.productTypeFilters.includes(product.product_type_id)
      );
    }

    if (this.state.productThemeFilters.length > 0) {
      products = products.filter(product => {
        return this.state.productThemeFilters.includes(product.product_theme_id);
      });
    }

    if (this.state.productStyleFilters.length > 0) {
      products = products.filter(product => {
        return this.state.productStyleFilters.includes(product.product_style_id);
      });
    }

    if (this.state.productOccasionFilters.length > 0) {
      products = products.filter(product => {
        return this.state.productOccasionFilters.includes(product.product_occasion_id);
      });
    }

    if (this.state.productCategoryFilters.length > 0) {
      products = products.filter(product => {
        return this.state.productCategoryFilters.includes(product.product_category_id);
      });
    }

    const priceRange = this.state.priceRange;
    if (this.state.priceRange[1] !== -1 && this.state.priceRange[1] !== this.state.highestPrice) {
      products = products.filter(product => {
        const calculatedPrice = calculatePrice(
          product.price,
          this.props.app.currency,
          this.props.app.country
        );
        return calculatedPrice >= priceRange[0] && calculatedPrice <= priceRange[1];
      });
    }

    if (this.state.sortBy) {
      switch (this.state.sortBy) {
        case 'newest_first':
          products.sort((a, b) => (moment(a.created) > moment(b.created) ? -1 : 1));
          break;
        case 'low_to_high':
          products.sort((a, b) => (a.price < b.price ? -1 : 1));
          break;
        case 'high_to_low':
          products.sort((a, b) => (a.price < b.price ? 1 : -1));
          break;
      }
    }

    this.gtagView(products);
    return products;
  };

  showDrawer = () => {
    this.setState({ filterDrawer: true });
  };
  onCloseDrawer = () => {
    this.setState({ filterDrawer: false });
  };

  truncateString(str: string, n: number) {
    return str.length > n ? str.substring(0, n) + '...' : str;
  }

  formatter = (value: number | undefined) => {
    return `${this.props.app.currency?.symbol}${value}`;
  };

  areFiltersSelected = () => {
    const productCategoryFilters = this.state.productCategoryFilters;
    const productThemeFilters = this.state.productThemeFilters;
    const productStyleFilters = this.state.productStyleFilters;
    const productOccasionFilters = this.state.productOccasionFilters;
    const sizesFilters = this.state.sizesFilters;
    const colorFilters = this.state.colorFilters;
    const productTypeFilters = this.state.productTypeFilters;
    const priceRange =
      this.state.priceRange[1] !== -1 && this.state.priceRange[1] !== this.state.highestPrice;

    return (
      productThemeFilters.length !== 0 ||
      productTypeFilters.length !== 0 ||
      sizesFilters.length !== 0 ||
      colorFilters.length !== 0 ||
      productCategoryFilters.length !== 0 ||
      productStyleFilters.length !== 0 ||
      productOccasionFilters.length !== 0 ||
      priceRange
    );
  };

  onProductThemeSelect = (values: any) => {
    this.setState({ productThemeFilters: values });
  };

  onProductStyleSelect = (values: any) => {
    this.setState({ productStyleFilters: values });
  };

  onProductOccasionSelect = (values: any) => {
    this.setState({ productOccasionFilters: values });
  };

  onProductCategorySelect = (values: any) => {
    this.setState({ productCategoryFilters: values });
  };

  getFilters = () => {
    const t = this.props.i18n.t.bind(this.props.i18n);

    const marks: any = {
      0: `${this.props.app.currency?.symbol} 0`,
      [this.state.highestPrice]: `${this.props.app.currency?.symbol} ${this.state.highestPrice}`
    };

    return (
      <div className={'products-filters'}>
        <div className={'products-filter'}>
          <Row gutter={[40, 40]}>
            {settings.products.filter.showCategories && (
              <Col xs={24}>
                <div className={'products-filter-label'}>
                  {t('products:filters.product_categories')}
                </div>
                <Checkbox.Group
                  value={this.state.productCategoryFilters}
                  style={{ width: '100%' }}
                  onChange={this.onProductCategorySelect}
                >
                  <Row>
                    {this.state.productCategories.map((productCategory, pindex) => {
                      return (
                        <Col key={pindex} span={12}>
                          <Checkbox value={productCategory.id}>
                            {this.truncateString(
                              productCategory.name[this.props.app.language.code],
                              30
                            )}
                          </Checkbox>
                        </Col>
                      );
                    })}
                  </Row>
                </Checkbox.Group>
              </Col>
            )}
            <Col xs={24}>
              <div className={'products-filter-label'}>{t('products:filters.product_styles')}</div>
              <Checkbox.Group
                value={this.state.productStyleFilters}
                style={{ width: '100%' }}
                onChange={this.onProductStyleSelect}
              >
                <Row>
                  {this.state.productStyles.map((productStyle, pindex) => {
                    return (
                      <Col key={pindex} span={12}>
                        <Checkbox value={productStyle.id}>
                          {this.truncateString(productStyle.name[this.props.app.language.code], 30)}
                        </Checkbox>
                      </Col>
                    );
                  })}
                </Row>
              </Checkbox.Group>
            </Col>
            <Col xs={24}>
              <div className={'products-filter-label'}>
                {t('products:filters.product_occasions')}
              </div>
              <Checkbox.Group
                value={this.state.productOccasionFilters}
                style={{ width: '100%' }}
                onChange={this.onProductOccasionSelect}
              >
                <Row>
                  {this.state.productOccasions.map((productOccasion, pindex) => {
                    return (
                      <Col key={pindex} span={12}>
                        <Checkbox value={productOccasion.id}>
                          {this.truncateString(
                            productOccasion.name[this.props.app.language.code],
                            30
                          )}
                        </Checkbox>
                      </Col>
                    );
                  })}
                </Row>
              </Checkbox.Group>
            </Col>
            <Col xs={24}>
              <div className={'products-filter-label'}>{t('products:filters.product_themes')}</div>
              <Checkbox.Group
                value={this.state.productThemeFilters}
                style={{ width: '100%' }}
                onChange={this.onProductThemeSelect}
              >
                <Row>
                  {this.state.productThemes.map((productTheme, pindex) => {
                    return (
                      <Col key={pindex} span={12}>
                        <Checkbox value={productTheme.id}>
                          {this.truncateString(productTheme.name[this.props.app.language.code], 30)}
                        </Checkbox>
                      </Col>
                    );
                  })}
                </Row>
              </Checkbox.Group>
            </Col>
            <Col xs={24}>
              <div className={'products-filter-label'}>
                {t('products:filters.sizes')}
                {settings.products.filter.showUnitSwitch && (
                  <>
                    {this.state.measurement_unit === 'imperial' ? (
                      <div
                        className={'unit-link'}
                        onClick={() => this.setState({ measurement_unit: 'metric' })}
                      >
                        {t('products:filters.show_in_cm')} <SwapOutlined />
                      </div>
                    ) : (
                      <div
                        className={'unit-link'}
                        onClick={() => this.setState({ measurement_unit: 'imperial' })}
                      >
                        {t('products:filters.show_in_inches')} <SwapOutlined />
                      </div>
                    )}
                  </>
                )}
              </div>
              <Checkbox.Group
                style={{ width: '100%' }}
                value={this.state.sizesFilters}
                onChange={this.onSizesSelect}
              >
                <Row>
                  {this.state.sizes.map(size => {
                    return (
                      <Col key={size.id} xs={12} span={size.id}>
                        {this.state.measurement_unit === 'imperial' ? (
                          <Checkbox value={size.id}>{size.metric_name}</Checkbox>
                        ) : (
                          <Checkbox value={size.id}>{size.metric_name}</Checkbox>
                        )}
                      </Col>
                    );
                  })}
                </Row>
              </Checkbox.Group>
            </Col>
          </Row>

          <Row gutter={[40, 40]}>
            <Col xs={24}>
              <div className={'products-filter-label'}>{t('products:filters.colors')}</div>
              <Checkbox.Group
                style={{ width: '100%' }}
                value={this.state.colorFilters}
                onChange={this.onColorsSelect}
              >
                <Row>
                  {this.state.colors.map((color, cindex) => {
                    return (
                      <Col key={cindex} span={12}>
                        <Checkbox value={color.id}>
                          {color.name[this.props.app.language.code]}
                        </Checkbox>
                      </Col>
                    );
                  })}
                </Row>
              </Checkbox.Group>
            </Col>

            <Col xs={24}>
              <div className={'products-filter-label'}>{t('products:filters.product_types')}</div>
              <Checkbox.Group
                value={this.state.productTypeFilters}
                style={{ width: '100%' }}
                onChange={this.onProductTypesSelect}
              >
                <Row>
                  {this.state.productTypes.map((productType, pindex) => {
                    return (
                      <Col key={pindex} xs={24} sm={12}>
                        <Checkbox value={productType.id}>
                          {this.truncateString(
                            productType.name[this.props.app.language.code],
                            window.outerWidth > 992 ? 30 : 100
                          )}
                        </Checkbox>
                      </Col>
                    );
                  })}
                </Row>
              </Checkbox.Group>
            </Col>
          </Row>

          <Row gutter={[40, 40]}>
            <Col xs={24}>
              <div className={'products-filter-label'}>{t('products:filters.price_range')}</div>
              {this.state.highestPrice !== -1 && (
                <Slider
                  step={100}
                  tipFormatter={this.formatter}
                  max={this.state.highestPrice}
                  range={true}
                  onAfterChange={this.onPriceRangeSelect}
                  marks={marks}
                  defaultValue={[0, this.state.highestPrice]}
                />
              )}
            </Col>
          </Row>
        </div>

        <Button type={'primary'} block={true} onClick={this.clearFilters}>
          {t('products:filters.button.clear_filters.text')}
        </Button>

        <Row>
          <Col xs={24} lg={0}>
            <Button
              style={{ marginTop: 10 }}
              block={true}
              type={'dashed'}
              onClick={this.onCloseDrawer}
            >
              {t('products:filters.button.hide_filters.text')}
            </Button>
          </Col>
        </Row>
      </div>
    );
  };

  render() {
    const t = this.props.i18n.t.bind(this.props.i18n);
    const products = this.filteredProducts();
    const voucher = this.props.cart.voucher;

    const menu = (
      <Menu>
        <Menu.Item onClick={() => this.setState({ sortBy: 'newest_first' })}>
          {t('products:sorting.newest_first')}
        </Menu.Item>
        <Menu.Item onClick={() => this.setState({ sortBy: 'low_to_high' })}>
          {t('products:sorting.low_to_high')}
        </Menu.Item>
        <Menu.Item onClick={() => this.setState({ sortBy: 'high_to_low' })}>
          {t('products:sorting.high_to_low')}
        </Menu.Item>
        <Menu.Divider />
        <Menu.Item onClick={() => this.setState({ sortBy: null })}>
          {t('products:sorting.clear_sorting')}
        </Menu.Item>
      </Menu>
    );

    let headerSizes: any = {
      xs: { span: 24, offset: 0 },
      // md: { span: 17, offset: 7 },
      // lg: { span: 18, offset: 6 },
      xl: { span: 20, offset: 4 }
    };

    let productSizes: any = headerSizes;

    if (!settings.products.layoutOffset) {
      headerSizes = {
        xs: { span: 24, offset: 0 }
      };

      productSizes = {
        xs: { span: 18, offset: 3 }
      };
    }

    return (
      <div className={'products-boxed-layout'}>
        <div className={'products-boxed-container'}>
          <Row className={'products-boxed-header'}>
            <Col xs={headerSizes.xs} md={headerSizes.md} lg={headerSizes.lg} xl={headerSizes.xl}>
              <div className={'content-header with-overlay default-header'}>
                <QueueAnim
                  className={'queue-anim-container'}
                  type={'left'}
                  duration={1500}
                  delay={300}
                >
                  <h1 key={1}>{t('products:page.title')}</h1>

                  <h2 key={2}>
                    <em>{this.state.subtitle}</em>
                  </h2>
                </QueueAnim>
              </div>
            </Col>
          </Row>
          <div className={'content-body'}>
            <Row className={'products-container'}>
              <Col
                xs={productSizes.xs}
                md={productSizes.md}
                lg={productSizes.lg}
                xl={productSizes.xl}
              >
                <Row className={'products-actions'}>
                  <Col xs={{ span: 24, order: 1 }} sm={{ span: 12, order: 0 }}>
                    <Dropdown
                      className={`btn-sort ${this.state.sortBy && 'active'}`}
                      overlay={menu}
                    >
                      <a className='ant-dropdown-link' onClick={e => e.preventDefault()}>
                        {t('products:button.sort_by')} <CaretDownOutlined />
                      </a>
                    </Dropdown>
                    <span className={'btn-separator'}>|</span>
                    <Button
                      className={`btn-filters ${this.areFiltersSelected() && 'active'}`}
                      type={'ghost'}
                      onClick={this.showDrawer}
                    >
                      {t('products:button.show_filters')} <CaretRightOutlined />
                    </Button>
                  </Col>
                  <Col
                    xs={{ span: 24, order: 0 }}
                    sm={{ span: 12, order: 1 }}
                    style={{ textAlign: 'right' }}
                  >
                    <Link className={'btn-go-to-cart'} to={'/cart'}>
                      {t('products:button.go_to_cart')} <ArrowRight />
                    </Link>
                    <Drawer
                      title={t('products:filters.title')}
                      placement='right'
                      closable={true}
                      onClose={this.onCloseDrawer}
                      visible={this.state.filterDrawer}
                    >
                      {this.getFilters()}
                    </Drawer>
                  </Col>
                </Row>

                <Row>
                  <Col xs={{ span: 24, offset: 0 }}>
                    {!this.state.loadingProducts && products.length > 0 ? (
                      <Row gutter={{ xs: 0, lg: 20 }}>
                        {products.map((product, pindex) => {
                          return (
                            <ProductsBoxedProduct
                              key={pindex}
                              colors={this.state.colors}
                              product={product}
                              index={pindex}
                              measurement_unit={this.state.measurement_unit}
                              productCategories={this.state.productCategories}
                              productTypes={this.state.productTypes}
                              sizes={this.state.sizes}
                            />
                          );
                        })}
                      </Row>
                    ) : (
                      <Row>
                        {this.state.loadingProducts ? (
                          <span>
                            <Spin spinning={true} /> {t('products:loading_pages')}
                          </span>
                        ) : (
                          <span>{t('products:no_products_found')}</span>
                        )}
                      </Row>
                    )}
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: any) => ({
  app: state.app,
  auth: state.auth,
  cart: state.cart,
  wishlist: state.wishlist
});

const mapDispatchToProps = (dispatch: any) => ({
  addItemToWishList: (product: Product) => dispatch(addItemToWishList(product)),
  removeItemFromWishList: (id: number) => dispatch(removeItemFromWishList(id)),
  showCategoriesBar: () => dispatch(showCategoriesBar()),
  hideCategoriesBar: () => dispatch(hideCategoriesBar())
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withTranslation()(WebshopBoxedModule))
);
