import React, { Component } from 'react';
import { Button, Col, Form, Input, Layout, Menu, Modal, Row } from 'antd';
import { RightOutlined, TwitterOutlined } from '@ant-design/icons';
import moment from 'moment';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { IAppState } from '../../../redux/states/app';
import CookieBannerComponent from '../../app/cookie-banner.component';
import { clearCookieConsent, setCurrency, setLanguage } from '../../../redux/actions/app';
import { WithTranslation, withTranslation } from 'react-i18next';
import { settings } from '../../../../settings';
import { Currency } from '../../../types';
import { LoopBack } from '../../../redux/api';
import Swal from 'sweetalert2';
import { ReactComponent as FaceBookIcon } from '../../../../assets/icon-facebook.svg';
import { ReactComponent as YoutubeIcon } from '../../../../assets/icon-youtube.svg';
import { ReactComponent as InstagramIcon } from '../../../../assets/icon-instagram.svg';
import { ReactComponent as PinterestFilled } from '../../../assets/logo-pinterest.svg';
import '../../../styles/footer-minimal.less';

const { Footer } = Layout;

interface Props extends WithTranslation {
  history: any;
  setLanguage: typeof setLanguage;
  clearCookieConsent: typeof clearCookieConsent;
  setCurrency: typeof setCurrency;
  app: IAppState;
}

interface State {
  showSubscribeModal: boolean;
}

class FooterMinimalComponent extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      showSubscribeModal: false
    };
  }

  handleChangeLanguage = (language: { name: string; code: string }) => {
    this.props.i18n.changeLanguage(language.code.toLowerCase());
    this.props.setLanguage(language);
    window.scrollTo(0, 0);
  };

  language = () => {
    const t = this.props.i18n.t.bind(this.props.i18n);

    return (
      <Menu>
        {settings.languages.map((language: { name: string; code: string }, index: number) => {
          return (
            <Menu.Item key={index} onClick={() => this.handleChangeLanguage(language)}>
              {t(`app:navbar.menu.language.${language.code.toLowerCase()}.label`)}
            </Menu.Item>
          );
        })}
      </Menu>
    );
  };

  currency = () => {
    const t = this.props.i18n.t.bind(this.props.i18n);

    return (
      <Menu>
        {this.props.app.currencies.map((currency, index) => {
          return (
            <Menu.Item key={index} onClick={() => this.props.setCurrency(currency)}>
              {currency.symbol} {t(`app:navbar.menu.currency.${currency.code.toLowerCase()}.label`)}
            </Menu.Item>
          );
        })}
      </Menu>
    );
  };

  onFinish = (values: any) => {
    const t = this.props.i18n.t.bind(this.props.i18n);

    new LoopBack()
      .post('/newsletter', values)
      .then(res => {
        Swal.fire({
          title: t('app:footer.popup.signup.success.title'),
          html: t('app:footer.popup.signup.success.description'),
          icon: 'success'
        });
        this.setState({ showSubscribeModal: false });
      })
      .catch(err => {
        Swal.fire({
          title: t('app:footer.popup.signup.warning.title'),
          html: t('app:footer.popup.signup.warning.description'),
          icon: 'warning'
        });
      });
  };

  render() {
    const apiSettings = this.props.app.settings;
    const t = this.props.i18n.t.bind(this.props.i18n);
    const modalContainer = document.getElementById('footer-minimal');

    return (
      <Footer id={'footer-minimal'} className={'footer-minimal'}>
        <Modal
          getContainer={modalContainer ? modalContainer : document.body}
          visible={this.state.showSubscribeModal}
          title={false}
          onCancel={() => this.setState({ showSubscribeModal: false })}
          footer={[
            <Button
              key='Close'
              onClick={() => {
                this.setState({ showSubscribeModal: false });
              }}
            >
              Close
            </Button>
          ]}
        >
          <div id={'subscribe-modal-content'}>
            <Form name='basic' layout={'horizontal'} onFinish={this.onFinish}>
              <Row gutter={20}>
                <Col xs={24} id={'newsletter-subscription'}>
                  <h3>{t('app:footer.popup.signup.popup.title')}</h3>
                  <p>{t('app:footer.popup.signup.popup.description')}</p>
                  <Form.Item
                    name={'email'}
                    rules={[{ required: true, message: t('pages:contact.form.email.warning') }]}
                  >
                    <Input type='email' placeholder={t('pages:contact.form.email.placeholder')} />
                  </Form.Item>
                  <Button block={true} htmlType={'submit'} type={'primary'}>
                    {t('app:footer.popup.signup.popup.button')} <RightOutlined />
                  </Button>
                </Col>
              </Row>
            </Form>
          </div>
        </Modal>
        <Row>
          <Col id={'subscribe-row'} xs={24} sm={12} md={8}>
            <div style={{ float: 'left', marginRight: 10 }}>Follow Us</div>
            {apiSettings?.footer_settings?.social_media_links && (
              <div id={'footer-social-media-icons'} style={{ float: 'left' }}>
                {apiSettings?.footer_settings?.social_media_links.instagram && (
                  <a
                    rel={'noopener noreferrer'}
                    target={'_blank'}
                    href={apiSettings?.footer_settings?.social_media_links.instagram}
                  >
                    <InstagramIcon />
                  </a>
                )}
                {apiSettings?.footer_settings?.social_media_links.twitter && (
                  <a
                    rel={'noopener noreferrer'}
                    target={'_blank'}
                    href={apiSettings?.footer_settings?.social_media_links.twitter}
                  >
                    <TwitterOutlined />
                  </a>
                )}
                {apiSettings?.footer_settings?.social_media_links.facebook && (
                  <a
                    rel={'noopener noreferrer'}
                    target={'_blank'}
                    href={apiSettings?.footer_settings?.social_media_links.facebook}
                  >
                    <FaceBookIcon />
                  </a>
                )}
                {apiSettings?.footer_settings?.social_media_links.pinterest && (
                  <a
                    className={'custom-svg'}
                    href={apiSettings?.footer_settings?.social_media_links.pinterest}
                  >
                    <PinterestFilled />
                  </a>
                )}
                {apiSettings?.footer_settings?.social_media_links.youtube && (
                  <a
                    rel={'noopener noreferrer'}
                    target={'_blank'}
                    href={apiSettings?.footer_settings?.social_media_links.youtube}
                  >
                    <YoutubeIcon />
                  </a>
                )}
              </div>
            )}

            <span
              id={'subscribe-button'}
              onClick={() => this.setState({ showSubscribeModal: true })}
            >
              Subscribe
            </span>
          </Col>
          <Col xs={24} sm={12} md={16}>
            <div id={'footer-links'}>
              <Row>
                <Col xs={24} lg={12} xl={14}>
                  <div id={'links'}>
                    {apiSettings?.footer_settings &&
                      apiSettings.footer_settings.relevant_links.links.map((link, lindex) => {
                        return (
                          <Link key={lindex} className={'footer-link'} to={link.url}>
                            {link.name[this.props.app.language.code]}
                          </Link>
                        );
                      })}
                  </div>
                </Col>
                <Col xs={24} lg={12} xl={10}>
                  <div id={'copyright'}>
                    <span id={'copy-right-text'}>
                      {`© ${moment().format('YYYY')} ${settings.appName}, All Rights Reserved`}
                    </span>
                    <img alt={'Logo'} src={require('../../../../assets/footer-logo.png').default} />
                  </div>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>

        <CookieBannerComponent />
      </Footer>
    );
  }
}

const mapStateToProps = (state: any) => ({
  app: state.app
});

const mapDispatchToProps = (dispatch: any) => ({
  clearCookieConsent: () => dispatch(clearCookieConsent()),
  setCurrency: (currency: Currency) => dispatch(setCurrency(currency)),
  setLanguage: (language: { code: string; name: string }) => dispatch(setLanguage(language))
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withTranslation()(FooterMinimalComponent))
);
