import React from 'react';
import { Button, Col, Row, Select } from 'antd';
import { Color, Product, ProductCategory, ProductType, Size } from '../../types';
import { Link, withRouter } from 'react-router-dom';
import { formatPrice } from '../../helpers/price.helper';
import { connect } from 'react-redux';
import { AuthState } from '../../redux/states/user';
import Swal from 'sweetalert2';
import { addItemToWishList, removeItemFromWishList } from '../../redux/actions/wishlist';
import {
  CaretDownOutlined,
  HeartFilled,
  HeartOutlined,
  ShoppingCartOutlined
} from '@ant-design/icons';
import { IWishListState } from '../../redux/states/wishlist';
import { IAppState } from '../../redux/states/app';
import { productSchema } from '../../helpers/structured-data.helper';
import { WithTranslation, withTranslation } from 'react-i18next';
import { settings } from '../../../settings';
import { ICartState } from '../../redux/states/cart';
import { handleAddItemToCartClick } from '../../helpers/product.helper';
import { addItemToCart } from '../../redux/actions/cart';
import { ReactComponent as HeartIcon } from '../../../assets/icon-heart.svg';
import { ReactComponent as HeartFillIcon } from '../../../assets/icon-heart-fill.svg';

interface Props extends WithTranslation {
  index: number;
  product: Product;
  productCategories: ProductCategory[];
  productTypes: ProductType[];
  sizes: Size[];
  measurement_unit: string;

  colors: Color[];
  history: any;
  app: IAppState;
  auth: AuthState;
  cart: ICartState;
  wishlist: IWishListState;
  addItemToWishList: typeof addItemToWishList;
  removeItemFromWishList: typeof removeItemFromWishList;
}

interface State {
  size: Size | null;
  quantity: number;
  custom_text: string | null;
  thread_color: string | null;
}

class ProductsBoxedProductComponent extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      size: null,
      quantity: 1,
      custom_text: null,
      thread_color: null
    };
  }

  getProductCategory = (product: Product) => {
    const categoryIndex = this.props.productCategories.findIndex(
      type => product.product_category_id === type.id
    );
    if (categoryIndex > -1) {
      return this.props.productCategories[categoryIndex].name[this.props.app.language.code];
    }
    return '-';
  };

  getProductType = (product: Product) => {
    // console.log(this.props.productTypes);
    // console.log(product);
    const typeIndex = this.props.productTypes.findIndex(
      type => product.product_type_id === type.id
    );
    if (typeIndex > -1) {
      return this.props.productTypes[typeIndex].name[this.props.app.language.code];
    }
    return '-';
  };

  getSize = (sizeId: number) => {
    return this.props.sizes.filter(size => size.id === sizeId)[0];
  };

  productIsInWishList = (product: Product) => {
    return this.props.wishlist.items.some(item => item.id === product.id);
  };

  isProductSoldOut = (product: Product) => {
    return product.manage_stock && product.stock <= 0;
  };

  getStockMessage = (product: Product) => {
    const t = this.props.i18n.t.bind(this.props.i18n);

    if (product.manage_stock) {
      if (product.stock <= 0) {
        return <div className={'stock-message sold-out'}>{t('products:stock.out_of_stock')}</div>;
      } else if (product.stock === 1 && settings.products.fewLeftLimit > 1) {
        return <div className={'stock-message'}>{t('products:stock.one_left')}</div>;
      } else if (product.stock === 2 && settings.products.fewLeftLimit > 2) {
        return <div className={'stock-message'}>{t('products:stock.two_left')}</div>;
      } else if (product.stock === 3 && settings.products.fewLeftLimit > 3) {
        return <div className={'stock-message'}>{t('products:stock.three_left')}</div>;
      } else if (product.stock > 1 && product.stock < settings.products.fewLeftLimit) {
        return (
          <div className={'stock-message'}>
            {t('products:stock.few_left', { text: product.stock })}
          </div>
        );
      }
    }
    return undefined;
  };

  onChangeSize = (sizeId: number) => {
    this.setState({ size: this.getSize(sizeId) });
  };

  handleAddItemToWishListClick = (product: Product) => {
    const t = this.props.i18n.t.bind(this.props.i18n);

    this.props.addItemToWishList(product);
    Swal.fire({
      title: t('products:detail.popup.wishlist.success.title'),
      html: t('products:detail.popup.wishlist.success.description', {
        text: `<strong>${product.name[this.props.app.language.code]}</strong>`
      }),
      icon: 'success'
    });
  };

  render() {
    const image =
      this.props.product.pictures && this.props.product.pictures.length > 0
        ? this.props.product.pictures[0]
        : null;
    const productIsWished = this.productIsInWishList(this.props.product);
    const t = this.props.i18n.t.bind(this.props.i18n);
    const customCartIcon = settings.navbar.shoppingBag.customIcon;
    const popupContainer = document.getElementById('select-product-' + this.props.product.id);

    return (
      <Col key={this.props.index} xs={24} sm={12} lg={8} xl={6} className={'product-boxed-col'}>
        <script type='application/ld+json'>
          {this.props.app.currency &&
            JSON.stringify(
              productSchema(
                this.props.product,
                this.props.app.currency.code,
                this.props.app.language.code
              )
            )}
        </script>
        <div className={'product'}>
          <Row className={'product-image'}>
            <Col xs={24}>
              {this.getStockMessage(this.props.product)}
              <Link to={`${settings.products.shoppingPage}${this.props.product.slug}`}>
                {image && (
                  <img
                    alt={this.props.product.summary[this.props.app.language.code]}
                    src={process.env.REACT_APP_API_URL + image}
                  />
                )}
              </Link>
              {this.props.product.stock === null ||
                (this.props.product.stock > 0 && (
                  <div className={'product-quick-buy'}>
                    <Row gutter={5}>
                      <Col id={'select-product-' + this.props.product.id} xs={12}>
                        <Select
                          getPopupContainer={() =>
                            popupContainer ? popupContainer : document.body
                          }
                          suffixIcon={<CaretDownOutlined />}
                          onChange={e => this.onChangeSize(parseInt(e.toString()))}
                          placeholder={t('products:detail.details.sizes.placeholder')}
                          style={{ width: '100%' }}
                        >
                          {this.props.product.sizes.map((size, sindex) => {
                            const dbSize = this.getSize(size);
                            if (dbSize) {
                              return (
                                <Select.Option key={sindex} value={size}>
                                  {this.props.measurement_unit === 'imperial' ? (
                                    <span>{dbSize.imperial_name}</span>
                                  ) : (
                                    <span>{dbSize.metric_name}</span>
                                  )}

                                  {dbSize.price && dbSize.price > 0 ? (
                                    <span>
                                      &nbsp;(+{' '}
                                      {formatPrice(
                                        dbSize.price,
                                        this.props.app.currency,
                                        this.props.app.country
                                      )}
                                      )
                                    </span>
                                  ) : (
                                    <span />
                                  )}
                                </Select.Option>
                              );
                            }
                            return undefined;
                          })}
                        </Select>
                      </Col>
                      <Col xs={12}>
                        <Button
                          style={{ marginBottom: 10 }}
                          size={'large'}
                          onClick={() =>
                            handleAddItemToCartClick(
                              this.state,
                              this.props,
                              this.props.product,
                              this.props.colors
                            )
                          }
                          block={true}
                          type={'primary'}
                        >
                          {t('products:detail.button.add_to_cart.text')}

                          {customCartIcon ? (
                            <img src={require(`../../../assets/${customCartIcon}`).default} />
                          ) : (
                            <ShoppingCartOutlined />
                          )}
                        </Button>
                      </Col>
                    </Row>
                  </div>
                ))}
            </Col>
          </Row>
          <div className={'product-details-container'}>
            <Row>
              <Col xs={24}>
                <div className={'product-details'}>
                  <div style={{ float: 'left' }}>
                    {this.props.product.name[this.props.app.language.code]}
                  </div>

                  <div
                    style={{ float: 'right' }}
                    dangerouslySetInnerHTML={{
                      __html: formatPrice(
                        this.props.product.price,
                        this.props.app.currency,
                        this.props.app.country
                      )
                    }}
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col xs={24} className={'product-actions'}>
                <span className={'product-actions-category-label'}>
                  {this.getProductCategory(this.props.product)} -&nbsp;
                </span>
                {this.getProductType(this.props.product)}
                {productIsWished ? (
                  <div
                    className={`icon-button active`}
                    onClick={() => this.props.removeItemFromWishList(this.props.product.id)}
                  >
                    {settings.products.customWishlistIcon ? <HeartFillIcon /> : <HeartFilled />}
                  </div>
                ) : (
                  <div
                    className={`icon-button`}
                    onClick={() => this.handleAddItemToWishListClick(this.props.product)}
                  >
                    {settings.products.customWishlistIcon ? <HeartIcon /> : <HeartOutlined />}
                  </div>
                )}
              </Col>
            </Row>
          </div>
        </div>
      </Col>
    );
  }
}

const mapStateToProps = (state: any) => ({
  app: state.app,
  auth: state.auth,
  cart: state.cart,
  wishlist: state.wishlist
});

const mapDispatchToProps = (dispatch: any) => ({
  addItemToCart: (
    product: Product,
    size: { id: number; price: number; name: string },
    quantity: number,
    custom_text: string | null,
    thread_color: string | null,
    color_name: { [key: string]: string } | null
  ) => dispatch(addItemToCart(product, size, quantity, custom_text, thread_color, color_name)),
  addItemToWishList: (product: Product) => dispatch(addItemToWishList(product)),
  removeItemFromWishList: (id: number) => dispatch(removeItemFromWishList(id))
});

export default withRouter(
  connect<any, any, any>(
    mapStateToProps,
    mapDispatchToProps
  )(withTranslation()(ProductsBoxedProductComponent))
);
