import React from 'react';
import { Button, Checkbox, Col, Form, Row } from 'antd';
import { Link, withRouter } from 'react-router-dom';
import { ICartState } from '../../redux/states/cart';
import { IAppState } from '../../redux/states/app';
import { FormInstance } from 'antd/lib/form';
import { AuthState, User } from '../../redux/states/user';
import { LoopBack } from '../../redux/api';
import Swal from 'sweetalert2';
import { ArrowRightOutlined } from '@ant-design/icons';
import { withTranslation, WithTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { updateUser } from '../../redux/actions/auth';

interface Props extends WithTranslation {
  app: IAppState;
  auth: AuthState;
  cart: ICartState;
  history: any;
  updateUser: typeof updateUser;
}

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 6 }
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 24 }
  }
};

interface State {}

class SettingsFormComponent extends React.Component<Props, State> {
  formRef = React.createRef<FormInstance>();

  constructor(props: Props) {
    super(props);

    window.scrollTo(0, 0);
  }

  componentDidMount(): void {
    if (this.props.auth.isLoggedIn) {
      if (this.formRef && this.formRef.current) {
        this.formRef.current.setFieldsValue({ ...this.props.auth.user });
        if (this.props.auth.user.customer === undefined || this.props.auth.user.customer === null) {
          this.formRef.current.setFieldsValue({
            customer: { email: this.props.auth.user.email }
          });
        }
      }
    }
  }

  onFinish = (values: any) => {
    const t = this.props.i18n.t.bind(this.props.i18n);

    new LoopBack(this.props.auth.token)
      .patch('/users/me', values)
      .then(res => {
        this.props.updateUser(res.user, res.token);
        Swal.fire({
          title: t('auth:profile.popup.update.success.title'),
          html: t('auth:profile.popup.update.success.description'),
          icon: 'success',
          confirmButtonText: t('auth:profile.popup.update.success.button.ok')
        });
      })
      .catch(err => {
        Swal.fire({
          title: t('auth:profile.popup.update.error.title'),
          html: t('auth:profile.popup.update.error.description'),
          icon: 'error',
          confirmButtonText: t('auth:profile.popup.update.error.button.ok')
        });
      });
  };

  render() {
    const t = this.props.i18n.t.bind(this.props.i18n);

    return (
      <Form
        {...formItemLayout}
        initialValues={{ news_letter: this.props.auth.user.news_letter }}
        ref={this.formRef}
        name='profile'
        onFinish={this.onFinish}
        scrollToFirstError
      >
        <div className={'form-section'} style={{ marginBottom: 0 }}>
          <Link to={'/cart'} style={{ float: 'right' }}>
            {t('auth:profile.section.settings.button.text')} <ArrowRightOutlined />
          </Link>
          <h2>{t('auth:profile.section.settings.title')}</h2>
          <hr />

          <Row gutter={10}>
            <Col xs={24}>
              <Form.Item name='news_letter' valuePropName='checked'>
                <Checkbox>{t('auth:profile.section.settings.form.newsletter.label')}</Checkbox>
              </Form.Item>
            </Col>
          </Row>
        </div>

        <Row gutter={10}>
          <Col xs={24}>
            <Form.Item>
              <Button block={true} size={'large'} type='primary' htmlType='submit'>
                {t('auth:profile.section.settings.form.button.text')}
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    );
  }
}

const mapStateToProps = (state: any) => ({
  app: state.app,
  auth: state.auth,
  cart: state.cart
});

const mapDispatchToProps = (dispatch: any) => ({
  updateUser: (user: User, token: string) => dispatch(updateUser(user, token))
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withTranslation()(SettingsFormComponent))
);
