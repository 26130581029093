import React, { Component } from 'react';
import { Layout } from 'antd';
import NavbarComponent from '../navigation/navbar.component';
import FooterComponent from '../navigation/footer.component';
import NavbarFloatingComponent from '../navigation/navbar-floating.component';
import { Helmet } from 'react-helmet';
import { LoopBack } from '../../redux/api';
import { Page, PageSection } from '../../types';
import HeaderModule from './modules/header.module';
import { GenderModule } from './modules/gender.module';
import { CenteredParagraphModule } from './modules/centered-paragraph.module';
import { ParagraphModule } from './modules/paragraph.module';
import HowItWorks from './modules/how-it-works.module';
import HighlightedProductsModule from './modules/highlighted-products.module';
import { ImageWallModule } from './modules/image-wall.module';
import { CollapsedListItemModule } from './modules/collapsed-list-item.module';
import { ColumnLayoutModule } from './modules/column-layout.module';
import WebshopModule from './modules/webshop.module';
import WebshopBoxedModule from './modules/webshop-boxed.module';
import { prerenderIsReady } from '../../helpers/prerender-ready.helper';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { IAppState } from '../../redux/states/app';
import { WithTranslation, withTranslation } from 'react-i18next';
import { BannerModule } from './modules/banner.module';
import { SliderModule } from './modules/slider.module';
import UpcomingEventsModule from './modules/upcoming-events.module';
import UpcomingProductsModule from './modules/upcoming-products.module';
import { PhotoGalleryModule } from './modules/photo-gallery.module';
import { TwoColoredBlocksModule } from './modules/two-colored-blocks.module';
import { TextWithPictureBlockModule } from './modules/text-with-picture-block.module';
import { ICartState } from '../../redux/states/cart';
import GiftCardsModule from './modules/gift-cards.module';
import SkeletonComponent from '../app/skeleton.component';
import { settings } from '../../../settings';
import DonationModule from './modules/donation.module';
import SliderPartnersModule from './modules/slider-partners.module';
import { ImagesModule } from './modules/images.module';
import { LinkTreeModule } from './modules/link-tree.module';

const { Content } = Layout;

interface Props extends WithTranslation {
  app: IAppState;
  cart: ICartState;
  history: any;
}

interface State {
  loading: boolean;
  page: Page | null;
  path: string;
}

class DynamicComponent extends Component<Props, State> {
  constructor(props: any) {
    super(props);

    this.state = {
      loading: true,
      page: null,
      path: window.location.pathname
    };
    this.getPage();
  }

  getPage = () => {
    const path = window.location.pathname
      .substr(1)
      .split('/')
      .join('%2F');
    new LoopBack()
      .get(`/pages/%2F${path}`)
      .then(res => {
        if (res === null) {
          this.props.history.push('/404');
        }
        this.setState({ loading: false, page: res, path: window.location.pathname }, () => {
          if (window.location.href.includes('#')) {
            const id = window.location.href.split('#');
            if (id) {
              const element = document.getElementById(id[1]);
              if (element) {
                element.scrollIntoView();
              }
            }
          } else {
            window.scrollTo(0, 0);
          }
          prerenderIsReady();
        });
      })
      .catch(err => {
        this.setState({ loading: false, path: window.location.pathname });
        prerenderIsReady();
      });
  };

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any): void {
    if (window.location.pathname !== this.state.path && !this.state.loading) {
      window.scrollTo(0, 0);
      this.setState({ loading: true }, () => {
        this.getPage();
      });
    }
  }

  getComponent = (section: PageSection, index: number) => {
    section.id = `section-${index}`;
    section.language = this.props.app.language.code;
    let component = <React.Fragment key={section.id} />;
    switch (section.type) {
      case 'header':
        component = <HeaderModule key={section.id} section={section} />;
        break;
      case 'paragraph':
        component = (
          <ParagraphModule key={section.id} {...section} language={this.props.app.language.code} />
        );
        break;
      case 'centered_paragraph':
        component = (
          <CenteredParagraphModule
            key={section.id}
            {...section}
            language={this.props.app.language.code}
          />
        );
        break;
      case 'gender_block':
        component = <GenderModule key={section.id} {...section} />;
        break;
      case 'how_it_works':
        component = (
          <HowItWorks key={section.id} {...section} language={this.props.app.language.code} />
        );
        break;
      case 'slider_partners':
        component = <SliderPartnersModule key={section.id} section={section} />;
        break;
      case 'highlighted_products':
        component = <HighlightedProductsModule key={section.id} section={section} />;
        break;
      case 'image_wall':
        component = <ImageWallModule key={section.id} {...section} />;
        break;
      case 'donation_module':
        component = <DonationModule key={section.id} section={section} />;
        break;
      case 'slider':
        component = <SliderModule key={section.id} {...section} />;
        break;
      case 'photo_gallery':
        component = <PhotoGalleryModule key={section.id} {...section} />;
        break;
      case 'two_colored_blocks':
        component = <TwoColoredBlocksModule key={section.id} {...section} />;
        break;
      case 'text_with_picture_block':
        component = <TextWithPictureBlockModule key={section.id} {...section} />;
        break;
      case 'upcoming_events':
        component = <UpcomingEventsModule key={section.id} section={section} />;
        break;
      case 'upcoming_products':
        component = <UpcomingProductsModule key={section.id} section={section} />;
        break;
      case 'gift_cards':
        component = <GiftCardsModule key={section.id} section={section} />;
        break;
      case 'images':
        component = <ImagesModule key={section.id} {...section} />;
        break;
      case 'link_tree':
        component = <LinkTreeModule key={section.id} {...section} />;
        break;
      case 'collapsed_list_items':
        component = <CollapsedListItemModule key={section.id} {...section} />;
        break;
      case 'webshop':
        const t = this.props.i18n.t.bind(this.props.i18n);
        component = this.state.loading ? (
          <span>{t('products:loading.text')}</span>
        ) : settings.products.style === 'boxed' ? (
          <WebshopBoxedModule key={section.id} section={section} />
        ) : (
          <WebshopModule key={section.id} section={section} />
        );
        break;
      case 'banner':
        component = <BannerModule key={section.id} />;
        break;
      case 'column_layout':
        component = (
          <ColumnLayoutModule
            key={section.id}
            {...section}
            language={this.props.app.language.code}
          />
        );
        break;
    }
    return component;
  };

  render() {
    const voucher = this.props.cart.voucher;
    const className = voucher && voucher.exists && voucher.voucher ? 'voucher-applied' : '';
    const apiSettings = this.props.app.settings;

    return (
      <Layout
        className={
          this.state.page
            ? 'page-' + (this.state.page.slug === '/' ? 'home' : this.state.page.slug.substr(1))
            : ''
        }
      >
        {!this.state.loading && this.state.page && (
          <Helmet>
            <title>{this.state.page.title[this.props.app.language.code]}</title>
            <meta
              name='description'
              content={this.state.page.description[this.props.app.language.code]}
            />
            <script>window.prerenderReady = false;</script>
            <link rel='canonical' href={window.location.origin + window.location.pathname} />
            <meta
              property='og:title'
              content={this.state.page.title[this.props.app.language.code]}
            />
            <meta
              property='og:description'
              content={this.state.page.description[this.props.app.language.code]}
            />
            <meta property='og:url' content={window.location.origin + window.location.pathname} />
            <meta
              name='twitter:title'
              content={this.state.page.title[this.props.app.language.code]}
            />
            <meta
              name='twitter:description'
              content={this.state.page.description[this.props.app.language.code]}
            />
          </Helmet>
        )}
        <NavbarComponent />
        <Content
          className={`${
            this.props.app.showCategoriesBar ? 'has-categories-bar ' + className : className
          }${apiSettings ? ` is-${apiSettings.navbar_settings?.type}` : ''}`}
        >
          {settings.navbar.linksStyle === 'floating' && <NavbarFloatingComponent />}

          {this.state.loading ? (
            <SkeletonComponent />
          ) : (
            this.state.page &&
            this.state.page.sections.map((section, index) => {
              return this.getComponent(section, index);
            })
          )}
        </Content>
        <FooterComponent />
      </Layout>
    );
  }
}

const mapStateToProps = (state: any) => ({
  app: state.app,
  cart: state.cart
});

export default withRouter(connect(mapStateToProps)(withTranslation()(DynamicComponent)));
