import React from 'react';
import { Col, Row, Skeleton } from 'antd';
import '../../styles/skeleton.less';

interface Props {}

const SkeletonComponent: React.FC<Props> = () => {
  return (
    <>
      <Row gutter={16}>
        <Col xs={{ span: 24 }}>
          <Skeleton
            active={true}
            paragraph={{ rows: 1 }}
            title={false}
            className={'skeleton-image'}
          />
        </Col>
        <Col xs={{ span: 18, offset: 3 }}>
          <Row gutter={32} style={{ marginTop: 40 }}>
            <Col xs={24} lg={16}>
              <Skeleton active={true} paragraph={{ rows: 4 }} title={true} />
            </Col>
            <Col xs={24} lg={8}>
              <Skeleton
                active={true}
                paragraph={{ rows: 1 }}
                title={false}
                className={'skeleton-image'}
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col xs={{ span: 18, offset: 3 }}>
          <Row gutter={32}>
            <Col xs={12} lg={8}>
              <Skeleton
                active={true}
                paragraph={{ rows: 1 }}
                title={false}
                className={'skeleton-image-small'}
              />
              <Skeleton active={false} title={true} paragraph={{ rows: 3 }} />
            </Col>
            <Col xs={12} lg={8}>
              <Skeleton
                active={true}
                paragraph={{ rows: 1 }}
                title={false}
                className={'skeleton-image-small'}
              />
              <Skeleton active={false} title={true} paragraph={{ rows: 3 }} />
            </Col>
            <Col xs={24} lg={8}>
              <Skeleton
                active={true}
                paragraph={{ rows: 1 }}
                title={false}
                className={'skeleton-image'}
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col xs={{ span: 18, offset: 3 }}>
          <Row gutter={32}>
            <Col xs={12} lg={8}>
              <Skeleton
                active={true}
                paragraph={{ rows: 1 }}
                title={false}
                className={'skeleton-image-small'}
              />
              <Skeleton active={false} title={true} paragraph={{ rows: 3 }} />
            </Col>
            <Col xs={12} lg={8}>
              <Skeleton
                active={true}
                paragraph={{ rows: 1 }}
                title={false}
                className={'skeleton-image-small'}
              />
              <Skeleton active={false} title={true} paragraph={{ rows: 3 }} />
            </Col>
            <Col xs={24} lg={8}>
              <Skeleton
                active={true}
                paragraph={{ rows: 1 }}
                title={false}
                className={'skeleton-image'}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default SkeletonComponent;
