import React from 'react';
import { Col, Layout, Row } from 'antd';
import NavbarComponent from '../navigation/navbar.component';
import { Helmet } from 'react-helmet';
import FooterComponent from '../navigation/footer.component';
import { connect } from 'react-redux';
import { updateToken } from '../../redux/actions/auth';
import { AuthState } from '../../redux/states/user';
import { FormInstance } from 'antd/es/form';
import ChangePasswordFormComponent from './change-password-form.component';
import { IAppState } from '../../redux/states/app';
import QueueAnim from 'rc-queue-anim';
import { WithTranslation, withTranslation } from 'react-i18next';
import '../../styles/change-password.less';

const { Content } = Layout;

interface Props extends WithTranslation {
  app: IAppState;
  updateToken: typeof updateToken;
  auth: AuthState;
  history: any;
}

interface State {
  loading: boolean;
  showPasswordError: boolean;
}

class ChangePasswordComponent extends React.Component<Props, State> {
  formRef = React.createRef<FormInstance>();

  constructor(props: Props) {
    super(props);

    this.state = {
      loading: false,
      showPasswordError: false
    };
  }

  componentDidMount(): void {
    window.scrollTo(0, 0);
  }

  render() {
    const settings = this.props.app.settings;
    const t = this.props.i18n.t.bind(this.props.i18n);

    return (
      <Layout>
        <Helmet>
          <title>{t('auth:change_password.meta.title')}</title>
          <meta name='description' content={t('auth:change_password.meta.description')} />
          <meta name='robots' content='noindex,nofollow' />
          <link rel='canonical' href={window.location.origin + window.location.pathname} />
          <meta property='og:title' content={t('auth:change_password.meta.title')} />
          <meta property='og:description' content={t('auth:change_password.meta.description')} />
          <meta property='og:url' content={window.location.origin + window.location.pathname} />
          <meta name='twitter:title' content={t('auth:change_password.meta.title')} />
          <meta name='twitter:description' content={t('auth:change_password.meta.description')} />
        </Helmet>
        <NavbarComponent />
        <Content
          className={`${settings?.navbar_settings?.type === 'transparent' ? 'no-offset' : ''}`}
        >
          <div className={'content-header with-overlay default-header'}>
            <QueueAnim className={'queue-anim-container'} type={'scale'} duration={1500}>
              <h1 key={1}>{t('auth:change_password.page.title')}</h1>
            </QueueAnim>
          </div>
          <Row className={'change-password-container'}>
            <Col xs={{ span: 20, offset: 2 }} xl={{ span: 12, offset: 6 }}>
              <div className={'change-password-block'}>
                <h2>{t('auth:change_password.block.title')}</h2>
                <hr />
                <ChangePasswordFormComponent history={this.props.history} />
              </div>
            </Col>
          </Row>
        </Content>
        <FooterComponent />
      </Layout>
    );
  }
}

const mapStateToProps = (state: any) => ({
  app: state.app,
  auth: state.auth
});

const mapDispatchToProps = (dispatch: any) => ({
  updateToken: (token: string) => dispatch(updateToken(token))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(ChangePasswordComponent));
