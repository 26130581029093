import { Color, Event, Product, ProductCategory, ProductStyle, ProductType } from '../types';
import Swal from 'sweetalert2';
import { settings, settings as appSettings } from '../../settings';
import React from 'react';
import { i18n } from 'i18next';

export const getProductStyle = (
  product: Product,
  language: string,
  productStyles: ProductStyle[]
) => {
  const categoryIndex = productStyles.findIndex(style => product.product_style_id === style.id);
  if (categoryIndex > -1) {
    return productStyles[categoryIndex].name[language];
  }
  return '-';
};

export const getProductCategory = (
  product: Product,
  language: string,
  productCategories: ProductCategory[]
) => {
  const categoryIndex = productCategories.findIndex(
    type => product.product_category_id === type.id
  );
  if (categoryIndex > -1) {
    return productCategories[categoryIndex].name[language];
  }
  return '-';
};

export const getProductType = (product: Product, language: string, productTypes: ProductType[]) => {
  const typeIndex = productTypes.findIndex(type => product.product_type_id === type.id);
  if (typeIndex > -1) {
    return productTypes[typeIndex].name[language];
  }
  return '-';
};

export const gtagUpdateEventAddition = (event: Event, quantity: number, language: string, extraAmount?: number) => {
  // @ts-ignore
  window.gtag('event', 'add_to_cart', {
    items: [
      {
        id: event.id,
        name: `${event.name[language]}`,
        list_name: 'Cart',
        brand: settings.appName,
        category: event.location[language],
        list_position: 1,
        quantity: quantity,
        price: event.price + (extraAmount ? extraAmount : 0)
      }
    ]
  });
};

export const gtagUpdateProductAddition = (
  product: Product,
  size: { id: number; price: number; name: string },
  quantity: number,
  language: string
) => {
  // @ts-ignore
  window.gtag('event', 'add_to_cart', {
    items: [
      {
        id: product.id,
        name: `${product.name[language]}${
          product.subtitle ? ` - ${product.subtitle[language]}` : ''
        }`,
        list_name: 'Cart',
        brand: appSettings.appName,
        category: product.product_type_id,
        variant: size.name,
        list_position: 1,
        quantity: quantity,
        price: product.price + size.price
      }
    ]
  });
};

export const gtagViewEvent = (event: Event, language: string) => {
  // @ts-ignore
  window.gtag('event', 'view_item', {
    items: [
      {
        id: event.id,
        name: `${event.name[language]}`,
        list_name: 'Event detail',
        brand: settings.appName,
        category: event.location[language],
        list_position: 1,
        quantity: 1,
        price: event.price
      }
    ]
  });
};

const getCurrentColor = (product: Product, allColors: Color[]) => {
  const colorId = product.color_id;
  if (colorId && allColors.length > 1) {
    const colorIndex = allColors.findIndex(color => color.id === colorId);
    if (colorIndex > -1) {
      return allColors[colorIndex];
    }
  }
  return null;
};

export const handleAddEventToCartClick = (state: any, props: any) => {
  const event: Event | null = state.event as Event;
  const t = props.i18n.t.bind(props.i18n);
  props.addEventToCart(event, state.quantity, state.selectedSessions,state.donationAmount);
  gtagUpdateEventAddition(event, state.quantity, props.app.language.code, state.donationAmount);

  const enablePartialParticipation = settings.events.partialPartipation.enable;
  const enableDonation = settings.events.partialPartipation.donation.enable;
  const conditionType = settings.events.partialPartipation.donation.condition.type;

  if (enablePartialParticipation && enableDonation && conditionType === 'sessions') {
    if (state.selectedSessions.length === 0) {
      Swal.fire({
        title: t('events:detail.popup.no_sessions.title'),
        html: t('events:detail.popup.no_sessions.description'),
        icon: 'warning'
      });
      return false;
    }
  }


  Swal.fire({
    title: t('events:detail.popup.added_to_cart.success.title'),
    html: t('events:detail.popup.added_to_cart.success.description', {
      name: `<strong>${state.event ? state.event.name[props.app.language.code] : ''}</strong>`,
      quantity: state.quantity
    }),
    icon: 'success',
    showCancelButton: true,
    cancelButtonText: t('events:detail.popup.added_to_cart.button.continue_shopping.text'),
    confirmButtonText: t('events:detail.popup.added_to_cart.button.go_to_cart.text'),
    showConfirmButton: true,
    reverseButtons: true
  }).then(result => {
    if (result.value) {
      props.history.push('/cart');
    }
  });
};

export const handleAddItemToCartClick = (
  state: any,
  props: any,
  product: Product | null,
  colors: Color[]
) => {
  const customText = state.custom_text;
  const t = props.i18n.t.bind(props.i18n);

  if (product && product.choose_thread_color && state.thread_color === null) {
    Swal.fire({
      title: t('products:detail.popup.no_thread_color.title'),
      html: t('products:detail.popup.no_thread_color.description'),
      icon: 'warning'
    });
    return false;
  }

  if (product && product.personalise && (customText === null || customText === '')) {
    Swal.fire({
      title: t('products:detail.popup.no_text.title'),
      html: t('products:detail.popup.no_text.description'),
      icon: 'warning'
    });
    return false;
  }

  if (product && state.size) {
    const colorName = getCurrentColor(product, colors);

    const sizeName =
      state.measurement_unit === 'imperial' ? state.size.imperial_name : state.size.metric_name;

    props.addItemToCart(
      product,
      {
        id: state.size.id,
        price: state.size.price,
        name: sizeName
      },
      state.quantity,
      state.custom_text,
      state.thread_color,
      colorName ? colorName.name : null
    );
    gtagUpdateProductAddition(
      product,
      {
        id: state.size.id,
        price: state.size.price,
        name: state.size.imperial_name
      },
      state.quantity,
      props.app.language.code
    );
    Swal.fire({
      title: t('products:detail.popup.added_to_cart.success.title'),
      html: t('products:detail.popup.added_to_cart.success.description', {
        name: `<strong>${product.name[props.app.language.code]}</strong>`,
        quantity: state.quantity,
        size: state.size.imperial_name
      }),
      icon: 'success',
      showCancelButton: true,
      cancelButtonText: t('products:detail.popup.added_to_cart.button.continue_shopping.text'),
      confirmButtonText: t('products:detail.popup.added_to_cart.button.go_to_cart.text'),
      showConfirmButton: true,
      reverseButtons: true
    }).then(result => {
      if (result.value) {
        props.history.push('/cart');
      }
    });
  } else {
    Swal.fire({
      title: t('products:detail.popup.added_to_card.no_size.title'),
      html: t('products:detail.popup.added_to_cart.no_size.description'),
      icon: 'warning'
    });
  }
};

export const getStockMessage = (product: Product, i18n: i18n) => {
  const t = i18n.t.bind(i18n);
  if (product) {
    if (product.manage_stock) {
      if (product.stock <= 0) {
        return (
          <div className={'stock-message sold-out'}>{t('products:detail.stock.sold_out')}</div>
        );
      } else if (product.stock === 1) {
        return (
          <div className={'stock-message nearly-sold-out'}>
            {t('products:detail.stock.nearly_sold_out')}
          </div>
        );
      } else if (product.stock > 1 && product.stock < 10) {
        return (
          <div className={'stock-message nearly-sold-out'}>
            {t('products:detail.stock.few_left', { text: product.stock })}
          </div>
        );
      }
    }
  }
  return <div className={'stock-message available'}>{t('products:detail.stock.available')}</div>;
};
