import React from 'react';
import { Col, Layout, Row } from 'antd';
import FAQItemComponent from './faq-item.component';
import NavbarComponent from '../navigation/navbar.component';
import { Helmet } from 'react-helmet';
import FooterComponent from '../navigation/footer.component';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { IAppState } from '../../redux/states/app';
import { WithTranslation, withTranslation } from 'react-i18next';
import { settings } from '../../../settings';
import { BannerModule } from './modules/banner.module';
import { ICartState } from '../../redux/states/cart';
import NavbarFloatingComponent from '../navigation/navbar-floating.component';
import HeaderModule from './modules/header.module';
import '../../styles/faqs-styles.less';

const { Content } = Layout;

interface Props extends WithTranslation {
  history: any;
  cart: ICartState;
  app: IAppState;
}

class FAQSComponent extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
    window.scrollTo(0, 0);
  }

  render() {
    const t = this.props.i18n.t.bind(this.props.i18n);
    const voucher = this.props.cart.voucher;

    return (
      <Layout>
        <Helmet>
          <title>{t('pages:faqs.meta.title')}</title>
          <meta name='description' content={t('pages:faqs.meta.description')} />
          <link rel='canonical' href={window.location.origin + window.location.pathname} />
          <meta property='og:title' content={t('pages:faqs.meta.title')} />
          <script>window.prerenderReady = false;</script>
          <meta property='og:description' content={t('pages:faqs.meta.description')} />
          <meta property='og:url' content={window.location.origin + window.location.pathname} />
          <meta name='twitter:title' content={t('pages:faqs.meta.title')} />
          <meta name='twitter:description' content={t('pages:faqs.meta.description')} />
        </Helmet>
        <NavbarComponent />
        <Content
          className={
            voucher && voucher.exists && voucher.voucher ? 'voucher-applied page-faqs' : 'page-faqs'
          }
        >
          {settings.navbar.linksStyle === 'floating' && <NavbarFloatingComponent />}

          {settings.showDefaultHeader && (
            <HeaderModule
              section={{
                settings: {
                  title: { en: t('pages:faqs.page.title') },
                  subtitle: {},
                  size: 'small',
                  parallax: settings.parallaxInHeader,
                  images: [require('../../../assets/header-main.jpg').default],
                  background_image: { non_api: true }
                },
                type: 'header',
                id: 'header',
                language: this.props.app.language.code,
                uid: 'header'
              }}
            />
          )}

          <div className={'content-body'}>
            <Row className={'faqs-container'}>
              {settings.faqs.showDecoration && (
                <img
                  alt={`Decorative element of ${settings.appName}`}
                  className={'decoration decoration-top-left'}
                  src={require('../../../assets/decoration-1.png').default}
                />
              )}
              <Col xs={{ span: 22, offset: 1 }} md={{ span: 18, offset: 3 }}>
                <h2>{t('pages:faqs.block.title')}</h2>
                <FAQItemComponent />
              </Col>
              {settings.faqs.showDecoration && (
                <img
                  alt={`Decorative element of ${settings.appName}`}
                  className={'decoration decoration-bottom-right'}
                  src={require('../../../assets/decoration-2.png').default}
                />
              )}
            </Row>
          </div>
          {settings.showCompanyBanner && <BannerModule />}
        </Content>
        <FooterComponent />
      </Layout>
    );
  }
}

const mapStateToProps = (state: any) => ({
  app: state.app,
  cart: state.cart
});

export default withRouter(connect(mapStateToProps)(withTranslation()(FAQSComponent)));
