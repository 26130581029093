import React from 'react';
import { Button, Col, Form, Input, Layout, Row } from 'antd';
import NavbarComponent from '../navigation/navbar.component';
import { Helmet } from 'react-helmet';
import FooterComponent from '../navigation/footer.component';
import { BlogArticle, Country, Currency } from '../../types';
import { connect } from 'react-redux';
import { userLoginFetch } from '../../redux/actions/auth';
import { AuthState } from '../../redux/states/user';
import { Link } from 'react-router-dom';
import { IAppState } from '../../redux/states/app';
import QueueAnim from 'rc-queue-anim';
import { withTranslation, WithTranslation } from 'react-i18next';
import '../../styles/auth.less';
import { TFunction } from 'i18next';
import { ArrowRightOutlined, LoginOutlined } from '@ant-design/icons';

const { Content } = Layout;

interface Props extends WithTranslation {
  app: IAppState;
  userLoginFetch: typeof userLoginFetch;
  auth: AuthState;
  history: any;
}

interface State {
  articles: BlogArticle[];
}

class LoginComponent extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      articles: []
    };
  }

  componentDidMount(): void {
    window.scrollTo(0, 0);
  }

  onFinish = (values: any) => {
    const t = this.props.i18n.t.bind(this.props.i18n);
    this.props.userLoginFetch(values, this.props.app.countries, this.props.app.currencies, t);
  };

  render() {
    const settings = this.props.app.settings;
    const t = this.props.i18n.t.bind(this.props.i18n);

    return (
      <Layout>
        <Helmet>
          <title>{t('auth:login.meta.title')}</title>
          <meta name='description' content={t('auth:login.meta.description')} />
          <link rel='canonical' href={window.location.origin + window.location.pathname} />
          <meta property='og:title' content={t('auth:login.meta.title')} />
          <meta property='og:description' content={t('auth:login.meta.description')} />
          <meta property='og:url' content={window.location.origin + window.location.pathname} />
          <meta name='twitter:title' content={t('auth:login.meta.title')} />
          <meta name='twitter:description' content={t('auth:login.meta.description')} />
        </Helmet>
        <NavbarComponent />
        <Content
          className={`${settings?.navbar_settings?.type === 'transparent' ? 'no-offset' : ''}`}
        >
          <div className={'content-header with-overlay default-header'}>
            <QueueAnim className={'queue-anim-container'} type={'scale'} duration={1500}>
              <h1 key={1}>{t('auth:login.page.title')}</h1>
            </QueueAnim>
          </div>
          <Row className={'login-container'}>
            <Col xs={{ span: 20, offset: 2 }} xl={{ span: 16, offset: 4 }}>
              <Row className={'login-block-row'}>
                <Col xs={24} md={12}>
                  <div className='login-block'>
                    <h2>{t('auth:login.title')}</h2>
                    <Form layout='vertical' onFinish={this.onFinish}>
                      <Form.Item
                        name='email'
                        rules={[{ required: true, message: t('auth:login.form.email.warning') }]}
                      >
                        <Input
                          size={'large'}
                          placeholder={t('auth:login.form.email.placeholder')}
                        />
                      </Form.Item>
                      <Form.Item
                        style={{ marginTop: 20 }}
                        name='password'
                        rules={[{ required: true, message: t('auth:login.form.password.warning') }]}
                      >
                        <Input.Password
                          size={'large'}
                          placeholder={t('auth:login.form.password.placeholder')}
                        />
                      </Form.Item>
                      <Link
                        style={{ float: 'right', marginTop: 10, marginBottom: 10 }}
                        to='/forgot-password'
                        title={t('auth:login.button.forgot_password.title')}
                      >
                        {t('auth:login.button.forgot_password.text')} <ArrowRightOutlined />
                      </Link>
                      <Button
                        icon={<LoginOutlined />}
                        size={'large'}
                        block={true}
                        type={'primary'}
                        htmlType='submit'
                        loading={this.props.auth.loading}
                      >
                        {t('auth:login.button.login.text')}
                      </Button>
                    </Form>
                  </div>
                </Col>
                <Col xs={24} md={12}>
                  <div className='login-block no-account'>
                    <h2>{t('auth:login.register.title')}</h2>
                    <p style={{ lineHeight: 1.5 }}>{t('auth:login.register.description')}</p>
                    <Link to='/register'>
                      <Button size={'large'} className='btn-black' block={true} htmlType='submit'>
                        {t('auth:login.button.register.text')} <ArrowRightOutlined />
                      </Button>
                    </Link>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Content>
        <FooterComponent />
      </Layout>
    );
  }
}

const mapStateToProps = (state: any) => ({
  app: state.app,
  auth: state.auth
});

const mapDispatchToProps = (dispatch: any) => ({
  userLoginFetch: (userInfo: any, countries: Country[], currencies: Currency[], t: TFunction) =>
    dispatch(userLoginFetch(userInfo, countries, currencies, t))
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(LoginComponent));
