import React from 'react';
import { Button, Carousel, Col, InputNumber, Layout, Modal, Row, Select } from 'antd';
import NavbarComponent from '../navigation/navbar.component';
import { Helmet } from 'react-helmet';
import FooterComponent from '../navigation/footer.component';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { AuthState } from '../../redux/states/user';
import { removeItemFromWishList } from '../../redux/actions/wishlist';
import { IAppState } from '../../redux/states/app';
import { LoopBack } from '../../redux/api';
import { BreadCrumbItem, Event } from '../../types';
import SkeletonComponent from '../app/skeleton.component';
import { calculatePrice, formatPrice } from '../../helpers/price.helper';
import { addEventToCart } from '../../redux/actions/cart';
import { ArrowLeftOutlined, ArrowRightOutlined, ShoppingCartOutlined } from '@ant-design/icons';
import { eventSchema } from '../../helpers/structured-data.helper';
import { prerenderIsReady } from '../../helpers/prerender-ready.helper';
import { WithTranslation, withTranslation } from 'react-i18next';
import { settings, settings as appSettings } from '../../../settings';
import { ICartState } from '../../redux/states/cart';
import { gtagViewEvent, handleAddEventToCartClick } from '../../helpers/product.helper';
import '../../styles/product-detail.less';
import HeaderModule from './modules/header.module';
import moment from 'moment';

const { Content } = Layout;

interface Props extends WithTranslation {
  history: any;
  app: IAppState;
  auth: AuthState;
  cart: ICartState;
  addEventToCart: typeof addEventToCart;
}

interface State {
  event: Event | null;
  loading: boolean;
  mainImage: string | null;
  quantity: number;
  showDonationOptions: boolean;
  selectedSessions: object[];
  donationAmount: number | null;
  showSecondaryImageModal: boolean;
}

class EventDetailDefaultComponent extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    window.scrollTo(0, 0);

    this.state = {
      event: null,
      loading: true,
      mainImage: null,
      quantity: 1,
      selectedSessions: [],
      showDonationOptions: false,
      donationAmount: null,
      showSecondaryImageModal: false,
    };

    const filter = {};

    new LoopBack()
      .get(
        `/events/%2F${window.location.pathname.split('/').pop()}?filter=${JSON.stringify(filter)}`
      )
      .then((res: Event) => {
        if (res === null) {
          this.props.history.push('/404');
        } else {
          let mainImage = null;
          if (res.pictures) {
            mainImage = res.pictures[0];
          }

          gtagViewEvent(res, this.props.app.language.code);

          this.setState(
            {
              event: res,
              loading: false,
              mainImage: mainImage,
              quantity: 1
            },
            () => {
              prerenderIsReady();
            }
          );
        }
      })
      .catch(err => {
        prerenderIsReady();
        this.setState({ loading: false });
      });

    this.onChange = this.onChange.bind(this);
  }

  onNumOfDaysChange = (value: number) => {
    if (value >= settings.events.partialPartipation.donation.condition.quantity) {
      this.setState({ showDonationOptions: true });
    } else {
      this.setState({ showDonationOptions: false });
    }
  };

  onNumOfSessionsChange = (value: number[]) => {
    if (this.state.event) {
      const sessions = this.state.event.sessions['en'].filter((session: any) => value.includes(session.uid));
      if (value.length >= settings.events.partialPartipation.donation.condition.quantity) {
        this.setState({ showDonationOptions: true, selectedSessions: sessions });
      } else {
        this.setState({ showDonationOptions: false, selectedSessions: sessions });
      }
    }
  };

  onChange(currentSlide: number) {
    if (this.state.event && this.state.event.pictures) {
      this.setState({ mainImage: this.state.event.pictures[currentSlide] });
    }
  }

  getAmountInUsd = (amount: number) => {

    const currency = this.props.app.currencies.filter((currency) => currency.code === 'USD')[0];
    if (currency) {
      const usdRate = currency.rate;
      amount = amount * usdRate;
    }
    return amount;
  }

  getTotal = () => {
    let total = 0;
    if (this.state.event) {
      total = this.state.event.price * this.state.quantity;
    }

    if (
      settings.events.partialPartipation.enable &&
      settings.events.partialPartipation.donation.enable
    ) {
      if (this.state.showDonationOptions && this.props.app.currency && this.state.donationAmount) {
        total += this.state.donationAmount;
      }
    }

    return total;
  };

  render() {
    const t = this.props.i18n.t.bind(this.props.i18n);
    const customWishlistIcon = settings.navbar.wishlist.customIcon;
    const customCartIcon = settings.navbar.shoppingBag.customIcon;

    const other = {
      slidesToShow: 3,
      slidesPerRow: 1,
      slidesToScroll: 1,
      dots: true,
      centerMode: false,
      focusOnSelect: true,
      arrows: true,
      centerPadding: '50px'
    };

    if (this.state.event && this.state.event.pictures) {
      if (this.state.event.pictures.length === 2) {
        other.slidesToShow = 1;
        other.slidesPerRow = 2;
      } else if (this.state.event.pictures.length === 3) {
        other.slidesToShow = 1;
        other.slidesPerRow = 3;
      }
    }

    const breadcrumbs: BreadCrumbItem[] = [
      { title: t('app:breadcrumbs.home'), link: '/' },
      { title: t('app:breadcrumbs.events'), link: appSettings.events.rootUrl }
    ];

    if (this.state.event) {
      breadcrumbs.push({
        title: this.state.event.name[this.props.app.language.code],
        link: null
      });
    }

    let schema = null;
    if (this.state.event && this.props.app.currency) {
      schema = eventSchema(
        this.state.event,
        this.props.app.currency.code,
        this.props.app.language.code
      );
    }
    const voucher = this.props.cart.voucher;

    return (
      <Layout>
        {this.state.event && (
          <Helmet>
            <title>
              {t('events:detail.meta.title', {
                title: this.state.event.name[this.props.app.language.code]
              })}
            </title>
            <meta
              name='description'
              content={this.state.event.summary[this.props.app.language.code]}
            />
            <script>window.prerenderReady = false;</script>
            <link rel='canonical' href={window.location.origin + window.location.pathname} />
            {this.state.event && schema && (
              <script type='application/ld+json'>{JSON.stringify(schema)}</script>
            )}
            <meta
              property='og:title'
              content={t('events:detail.meta.title', {
                title: this.state.event.name[this.props.app.language.code]
              })}
            />
            <meta
              property='og:description'
              content={this.state.event.summary[this.props.app.language.code]}
            />
            <meta property='og:url' content={window.location.origin + window.location.pathname} />
            <meta
              name='twitter:title'
              content={t('events:detail.meta.title', {
                title: this.state.event.name[this.props.app.language.code]
              })}
            />
            <meta
              name='twitter:description'
              content={this.state.event.summary[this.props.app.language.code]}
            />
          </Helmet>
        )}
        <NavbarComponent />
        <Content className={voucher && voucher.exists && voucher.voucher ? 'voucher-applied' : ''}>
          {this.state.loading ? (
            <SkeletonComponent />
          ) : (
            <>
              {settings.showDefaultHeader && this.state.event && (
                <HeaderModule
                  section={{
                    settings: {
                      title: this.state.event.name,
                      subtitle: {},
                      size: 'small',
                      parallax: settings.parallaxInHeader,
                      images: [require('../../../assets/header-main.jpg').default],
                      background_image: { non_api: true }
                    },
                    type: 'header',
                    id: 'header',
                    language: this.props.app.language.code,
                    uid: 'header'
                  }}
                />
              )}

              <div className={'content-body'}>
                <Row className={'product-container'}>
                  <Col
                    xs={{ span: 22, offset: 1 }}
                    md={{ span: 10, offset: 1 }}
                    lg={{ span: 13, offset: 1 }}
                    xl={{ span: 8, offset: 3 }}
                  >
                    <Row>
                      {this.state.event !== null && (
                        <Col xs={24}>
                          <Link to={appSettings.events.rootUrl}>
                            <ArrowLeftOutlined />{' '}
                            {t('products:detail.button.go_back_to_events.text')}
                          </Link>
                          <div
                            className={'product-images-container product-image-slider'}
                            style={{ marginTop: 20 }}
                          >
                            <img
                              className={'product-main-image'}
                              alt={this.state.event.name[this.props.app.language.code]}
                              src={
                                this.state.mainImage
                                  ? process.env.REACT_APP_API_URL + this.state.mainImage
                                  : ''
                              }
                            />
                            {this.state.event.pictures && this.state.event.pictures.length > 1 && (
                              <Carousel {...other} afterChange={this.onChange}>
                                {this.state.event.pictures.map((picture, pindex) => {
                                  return (
                                    <div
                                      key={pindex}
                                      className={'product-thumbnail'}
                                      onClick={() => this.setState({ mainImage: picture })}
                                    >
                                      <img
                                        alt={`Variation ${pindex + 1} for ${this.state.event &&
                                          this.state.event.name[this.props.app.language.code]}`}
                                        src={process.env.REACT_APP_API_URL + picture}
                                      />
                                    </div>
                                  );
                                })}
                              </Carousel>
                            )}
                          </div>

                          {this.state.event.secondary_image &&
                            <img
                              onClick={() => this.setState({showSecondaryImageModal: true})}
                              className={'product-secondary-image'}
                              alt={this.state.event.name[this.props.app.language.code]}
                              src={
                                this.state.event.secondary_image
                                  ? process.env.REACT_APP_API_URL + this.state.event.secondary_image
                                  : ''
                              }
                            />
                          }

                          <Modal
                            footer={[
                              <Button
                                key='Close'
                                onClick={() => {
                                  this.setState({showSecondaryImageModal: false});
                                }}
                              >
                                Close
                              </Button>
                            ]}
                            width={'100%'}
                            visible={this.state.showSecondaryImageModal}
                            onCancel={() => this.setState({showSecondaryImageModal: false})}
                          >
                            <img
                              style={{width: '100%', height: 'auto'}}
                              alt={this.state.event.name[this.props.app.language.code]}
                              src={
                                this.state.event.secondary_image
                                  ? process.env.REACT_APP_API_URL + this.state.event.secondary_image
                                  : ''
                              }
                            />
                          </Modal>

                        </Col>
                      )}

                      {/*{settings.events.sessions.enable &&*/}
                      {/*  this.state.event &&*/}
                      {/*  this.state.event.sessions && (*/}
                      {/*    <Row className={'event-sessions'}>*/}
                      {/*      <h3>Sessions:</h3>*/}
                      {/*      {this.state.event.sessions[this.props.app.language.code].map(*/}
                      {/*        session => {*/}
                      {/*          return (*/}
                      {/*            <Row className={'event-session'}>*/}
                      {/*              <Col xs={8}>*/}
                      {/*                <div style={{ overflow: ' hidden', height: '100%' }}>*/}
                      {/*                  <img*/}
                      {/*                    style={{*/}
                      {/*                      width: '100%',*/}
                      {/*                      height: '100%',*/}
                      {/*                      objectFit: 'cover'*/}
                      {/*                    }}*/}
                      {/*                    alt={''}*/}
                      {/*                    src={process.env.REACT_APP_API_URL + session.image.url}*/}
                      {/*                  />*/}
                      {/*                </div>*/}
                      {/*              </Col>*/}
                      {/*              <Col xs={16}>*/}
                      {/*                <div className={'event-session-body'}>*/}
                      {/*                  <h4>{session.title}</h4>*/}
                      {/*                  <div*/}
                      {/*                    dangerouslySetInnerHTML={{*/}
                      {/*                      __html: session.description*/}
                      {/*                    }}*/}
                      {/*                  />*/}
                      {/*                </div>*/}
                      {/*              </Col>*/}
                      {/*            </Row>*/}
                      {/*          );*/}
                      {/*        }*/}
                      {/*      )}*/}
                      {/*    </Row>*/}
                      {/*  )}*/}
                    </Row>
                  </Col>
                  <Col xs={24} md={13} lg={10} xl={{ span: 8, offset: 1 }}>
                    {this.state.event && (
                      <div className={'product-details-container'}>
                        <div className={'product-details-name'}>
                          <Row>
                            <Col xs={{span: 24, order: 1}} sm={{span: 12, order: 0}}>
                              <h2>{this.state.event.name[this.props.app.language.code]}</h2>
                            </Col>
                            <Col xs={{span: 24, order: 0}} sm={{span: 12, order: 1}} className={'product-price'}>
                              {this.state.event.price === 0 ? (
                                <span>FREE EVENT</span>
                              ) : (
                                <span
                                  dangerouslySetInnerHTML={{
                                    __html: formatPrice(
                                      this.state.event.price,
                                      this.props.app.currency,
                                      this.props.app.country
                                    )
                                  }}
                                />
                              )}
                            </Col>
                          </Row>
                          <Row>
                            <strong>{t('events:detail.details.subtitle')}</strong>
                          </Row>
                        </div>
                        <p
                          className={'product-details-description'}
                          dangerouslySetInnerHTML={{
                            __html: this.state.event.description[this.props.app.language.code]
                          }}
                        />

                        <div className={'product-list-items'}>
                          <Row className={'product-list-item'}>
                            <Col xs={12} sm={8}>
                              {t('products:detail.details.quantity')}
                            </Col>
                            <Col xs={12} sm={16}>
                              <InputNumber
                                min={1}
                                onChange={e =>
                                  this.setState({ quantity: e ? parseInt(e.toString()) : 1 })
                                }
                                defaultValue={this.state.quantity}
                              />
                            </Col>
                          </Row>

                          {settings.events.partialPartipation.enable && settings.events.partialPartipation.donation.condition.type === 'days' && (
                            <Row className={'product-list-item'}>
                              <Col xs={12} sm={8}>
                                {t('events:detail.details.days_to_participate')}
                              </Col>
                              <Col xs={12} sm={16}>
                                <Select
                                  onSelect={this.onNumOfDaysChange}
                                  style={{ width: '100%' }}
                                  placeholder={'Choose number of days'}
                                >
                                  <Select.Option value={1}>
                                    1 day{' '}
                                    {`(till ${moment(this.state.event.start_date)
                                      .add(1, 'days')
                                      .format('ddd DD MMM YYYY')})`}
                                  </Select.Option>
                                  <Select.Option value={2}>
                                    2 days{' '}
                                    {`(till ${moment(this.state.event.start_date)
                                      .add(2, 'days')
                                      .format('ddd DD MMM YYYY')})`}
                                  </Select.Option>
                                  <Select.Option value={3}>
                                    3 days{' '}
                                    {`(till ${moment(this.state.event.start_date)
                                      .add(3, 'days')
                                      .format('ddd DD MMM YYYY')})`}
                                  </Select.Option>
                                  <Select.Option value={4}>
                                    4 days{' '}
                                    {`(till ${moment(this.state.event.start_date)
                                      .add(4, 'days')
                                      .format('ddd DD MMM YYYY')})`}
                                  </Select.Option>
                                  <Select.Option value={5}>
                                    5 days{' '}
                                    {`(till ${moment(this.state.event.start_date)
                                      .add(5, 'days')
                                      .format('ddd DD MMM YYYY')})`}
                                  </Select.Option>
                                  <Select.Option value={6}>
                                    6 days{' '}
                                    {`(till ${moment(this.state.event.start_date)
                                      .add(6, 'days')
                                      .format('ddd DD MMM YYYY')})`}
                                  </Select.Option>
                                  <Select.Option value={7}>All days</Select.Option>
                                </Select>
                              </Col>
                            </Row>
                          )}

                          {settings.events.partialPartipation.enable && settings.events.partialPartipation.donation.condition.type === 'sessions' && (
                            <Row className={'product-list-item'}>
                              <Col xs={24} sm={12} md={8}>
                                {t('events:detail.details.sessions')}
                              </Col>
                              <Col xs={24} sm={12} md={16}>
                                <Select
                                  mode={'multiple'}
                                  onChange={this.onNumOfSessionsChange}
                                  style={{ width: '100%' }}
                                  placeholder={'Choose session(s)'}
                                >
                                  {this.state.event.sessions['en'].map((session,index) => {
                                    return (
                                    <Select.Option value={session.uid}>
                                      {session.title} {moment(session.start_date).format('DD MMM HH:mm')}
                                    </Select.Option>
                                    )
                                  })}
                                </Select>
                              </Col>
                            </Row>
                          )}



                          {settings.events.partialPartipation.donation.enable &&
                            this.state.showDonationOptions && (
                              <Row className={'product-list-item'}>
                                <Col xs={12} sm={8}>
                                  {t('events:detail.details.donation_amount')}
                                </Col>
                                <Col xs={12} sm={16}>
                                  {
                                    <Select
                                      onSelect={e =>
                                        this.setState({ donationAmount: parseInt(e.toString()) })
                                      }
                                      placeholder={'Choose Donation Amount'}
                                      style={{ width: '100%' }}
                                    >
                                      <Select.Option value={0}>None</Select.Option>
                                      {settings.events.partialPartipation.donation.donationOptions.map(
                                        (option, index) => {
                                          return (
                                            <Select.Option key={index + 1} value={option}>
                                              {formatPrice(
                                                option,
                                                this.props.app.currency,
                                                this.props.app.country
                                              )}
                                            </Select.Option>
                                          );
                                        }
                                      )}
                                    </Select>
                                  }
                                  <em style={{ color: '#666' }}>
                                    Organising an event takes time, effort and resources. We
                                    appreciate a small donation so we can continue to organise
                                    events as these in the future as well.
                                  </em>
                                </Col>
                              </Row>
                            )}

                          <Row className={'product-list-item total'}>
                            <Col xs={12} sm={8}>
                              {t('products:detail.details.total')}
                            </Col>
                            <Col xs={12} sm={16}>
                              {formatPrice(
                                this.getTotal(),
                                this.props.app.currency,
                                this.props.app.country
                              )}
                            </Col>
                          </Row>
                        </div>

                        <Row gutter={20}>
                          <Col xs={24}>
                            {this.getTotal() > 0 && this.props.app.currency && (
                              <Button
                                className={'donate'}
                                style={{ marginBottom: 10 }}
                                block={true}
                                size={'large'}
                                type={'primary'}
                                target={'_blank'}
                                href={`https://www.paypal.com/paypalme/blankcanvascommunity/${this.getAmountInUsd(this.getTotal()).toFixed(2) }USD`}
                              >
                                DONATE
                              </Button>
                            )}

                            <Button
                              style={{ marginBottom: 10 }}
                              size={'large'}
                              onClick={() => handleAddEventToCartClick(this.state, this.props)}
                              block={true}
                              type={'primary'}
                            >
                              {customWishlistIcon ? (
                                <img src={require(`../../../assets/${customCartIcon}`).default} />
                              ) : (
                                <ShoppingCartOutlined />
                              )}
                              &nbsp;
                              {t('products:detail.button.add_to_cart.text')}
                            </Button>
                          </Col>
                          <Col xs={24}>
                            <Link to={appSettings.events.rootUrl}>
                              <ArrowLeftOutlined />{' '}
                              {t('products:detail.button.go_back_to_events.text')}
                            </Link>
                            <Link to={'/cart'} style={{ float: 'right' }}>
                              {t('products:detail.button.go_to_cart.text')}
                              <ArrowRightOutlined />
                            </Link>
                          </Col>
                        </Row>
                      </div>
                    )}
                  </Col>
                </Row>
              </div>
            </>
          )}
        </Content>
        <FooterComponent />
      </Layout>
    );
  }
}

const mapStateToProps = (state: any) => ({
  app: state.app,
  auth: state.auth,
  wishlist: state.wishlist,
  cart: state.cart
});

const mapDispatchToProps = (dispatch: any) => ({
  removeItemFromWishList: (id: number) => dispatch(removeItemFromWishList(id)),
  addEventToCart: (event: Event, quantity: number,sessions?: object[], donationAmount?: number) =>
    dispatch(addEventToCart(event, quantity, sessions,donationAmount))
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withTranslation()(EventDetailDefaultComponent))
);
