import React from 'react';
import { Button, Carousel, Col, Input, InputNumber, Layout, Row, Select } from 'antd';
import NavbarComponent from '../navigation/navbar.component';
import { Helmet } from 'react-helmet';
import FooterComponent from '../navigation/footer.component';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { AuthState } from '../../redux/states/user';
import { addItemToWishList, removeItemFromWishList } from '../../redux/actions/wishlist';
import { IAppState } from '../../redux/states/app';
import { LoopBack } from '../../redux/api';
import { BreadCrumbItem, Product, Size } from '../../types';
import SkeletonComponent from '../app/skeleton.component';
import { formatPrice } from '../../helpers/price.helper';
import { addItemToCart } from '../../redux/actions/cart';
import Swal from 'sweetalert2';
import { IWishListState } from '../../redux/states/wishlist';
import {
  ArrowLeftOutlined,
  ArrowRightOutlined,
  HeartOutlined,
  ShoppingCartOutlined,
  SwapOutlined
} from '@ant-design/icons';
import { productSchema } from '../../helpers/structured-data.helper';
import { prerenderIsReady } from '../../helpers/prerender-ready.helper';
import { WithTranslation, withTranslation } from 'react-i18next';
import { settings, settings as appSettings } from '../../../settings';
import { ICartState } from '../../redux/states/cart';
import { getStockMessage, handleAddItemToCartClick } from '../../helpers/product.helper';
import '../../styles/product-detail.less';
import HeaderModule from './modules/header.module';

const { Content } = Layout;

interface Props extends WithTranslation {
  history: any;
  app: IAppState;
  auth: AuthState;
  cart: ICartState;
  wishlist: IWishListState;
  addItemToCart: typeof addItemToCart;
  addItemToWishList: typeof addItemToWishList;
  removeItemFromWishList: typeof removeItemFromWishList;
}

interface State {
  product: Product | null;
  loading: boolean;
  loadingSizes: boolean;
  mainImage: string | null;
  currentSlide: number;
  size: Size | null;
  sizes: Size[];
  quantity: number;
  custom_text: string | null;
  measurement_unit: string;
  thread_color: string | null;
}

class ProductDetailComponent extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    window.scrollTo(0, 0);

    this.state = {
      currentSlide: 0,
      product: null,
      loading: true,
      loadingSizes: true,
      mainImage: null,
      size: null,
      sizes: [],
      quantity: 1,
      custom_text: null,
      thread_color: null,
      measurement_unit: this.props.app.country
        ? this.props.app.country.preferred_measurement_unit
        : 'imperial'
    };

    const filter = { include: [{ relation: 'product_type' }] };

    new LoopBack()
      .get('/sizes')
      .then(res => {
        this.setState({ sizes: res, loadingSizes: false });
      })
      .catch(err => {
        this.setState({ loadingSizes: false, sizes: [] });
      });

    new LoopBack()
      .get(
        `/products/%2F${window.location.pathname.split('/').pop()}?filter=${JSON.stringify(filter)}`
      )
      .then((res: Product) => {
        if (res === null) {
          this.props.history.push('/404');
        } else {
          let mainImage = null;
          if (res.pictures) {
            mainImage = res.pictures[0];
          }

          // @ts-ignore
          window.gtag('event', 'view_item', {
            items: [
              {
                id: res.id,
                name: `${res.name[this.props.app.language.code]}${
                  res.subtitle ? ` - ${res.subtitle[this.props.app.language.code]}` : ''
                }`,
                list_name: 'Product detail',
                brand: appSettings.appName,
                category: res.product_type_id,
                variant: res.color_id,
                list_position: 1,
                quantity: 1,
                price: res.price
              }
            ]
          });

          this.setState(
            {
              product: res,
              loading: false,
              mainImage: mainImage,
              quantity: 1
            },
            () => {
              prerenderIsReady();
            }
          );
        }
      })
      .catch(err => {
        prerenderIsReady();
        this.setState({ loading: false });
      });

    this.onChange = this.onChange.bind(this);
  }

  onChange(currentSlide: number) {
    if (this.state.product && this.state.product.pictures) {
      this.setState({ mainImage: this.state.product.pictures[currentSlide] });
    }
  }

  handleAddItemToWishListClick = () => {
    const t = this.props.i18n.t.bind(this.props.i18n);
    const product = this.state.product;
    if (product) {
      this.props.addItemToWishList(product);
      Swal.fire({
        title: t('products:detail.popup.wishlist.success.title'),
        html: t('products:detail.popup.wishlist.success.description', {
          text: `<strong>${product.name[this.props.app.language.code]}</strong>`
        }),
        icon: 'success'
      });
    }
  };

  productIsInWishList = () => {
    const product = this.state.product;
    if (product) {
      return this.props.wishlist.items.some(item => item.id === product.id);
    }
    return false;
  };

  removeItemFromWishList = () => {
    if (this.state.product) {
      this.props.removeItemFromWishList(this.state.product.id);
    }
  };

  onChangeSize = (sizeId: number) => {
    this.setState({ size: this.getSize(sizeId) });
  };

  onChangeThreadColor = (color: string) => {
    this.setState({ thread_color: color });
  };

  getSize = (sizeId: number) => {
    return this.state.sizes.filter(size => size.id === sizeId)[0];
  };

  render() {
    const t = this.props.i18n.t.bind(this.props.i18n);
    const customWishlistIcon = settings.navbar.wishlist.customIcon;
    const customCartIcon = settings.navbar.shoppingBag.customIcon;

    const other = {
      slidesToShow: 3,
      slidesPerRow: 1,
      slidesToScroll: 1,
      dots: true,
      centerMode: false,
      focusOnSelect: false,
      arrows: true,
      centerPadding: '50px'
    };

    if (this.state.product && this.state.product.pictures) {
      if (this.state.product.pictures.length === 2) {
        other.slidesToShow = 1;
        other.slidesPerRow = 2;
      } else if (this.state.product.pictures.length === 3) {
        other.slidesToShow = 1;
        other.slidesPerRow = 3;
      }
    }

    const breadcrumbs: BreadCrumbItem[] = [
      { title: t('app:breadcrumbs.home'), link: '/' },
      { title: t('app:breadcrumbs.shop'), link: appSettings.products.shoppingPage }
    ];

    if (this.state.product) {
      breadcrumbs.push({
        title: this.state.product.name[this.props.app.language.code],
        link: null
      });
    }

    let schema = null;
    if (this.state.product && this.props.app.currency) {
      schema = productSchema(
        this.state.product,
        this.props.app.currency.code,
        this.props.app.language.code
      );
    }
    const voucher = this.props.cart.voucher;

    return (
      <Layout>
        {this.state.product && (
          <Helmet>
            <title>
              {this.state.product?.subtitle
                ? t('products:detail.meta.title_with_subtitle', {
                    title: this.state.product?.name[this.props.app.language.code],
                    subtitle: this.state.product.subtitle[this.props.app.language.code]
                  })
                : t('products:detail.meta.title', {
                    title: this.state.product?.name[this.props.app.language.code]
                  })}
            </title>
            <meta
              name='description'
              content={this.state.product.summary[this.props.app.language.code]}
            />
            <script>window.prerenderReady = false;</script>
            <link rel='canonical' href={window.location.origin + window.location.pathname} />
            {this.state.product && schema && (
              <script type='application/ld+json'>{JSON.stringify(schema)}</script>
            )}
            <meta
              property='og:title'
              content={
                this.state.product?.subtitle
                  ? t('products:detail.meta.title_with_subtitle', {
                      title: this.state.product?.name[this.props.app.language.code],
                      subtitle: this.state.product.subtitle[this.props.app.language.code]
                    })
                  : t('products:detail.meta.title', {
                      title: this.state.product?.name[this.props.app.language.code]
                    })
              }
            />
            <meta
              property='og:description'
              content={this.state.product.summary[this.props.app.language.code]}
            />
            <meta property='og:url' content={window.location.origin + window.location.pathname} />
            <meta
              name='twitter:title'
              content={
                this.state.product?.subtitle
                  ? t('products:detail.meta.title_with_subtitle', {
                      title: this.state.product?.name[this.props.app.language.code],
                      subtitle: this.state.product.subtitle[this.props.app.language.code]
                    })
                  : t('products:detail.meta.title', {
                      title: this.state.product?.name[this.props.app.language.code]
                    })
              }
            />
            <meta
              name='twitter:description'
              content={this.state.product.summary[this.props.app.language.code]}
            />
          </Helmet>
        )}
        <NavbarComponent />
        <Content className={voucher && voucher.exists && voucher.voucher ? 'voucher-applied' : ''}>
          {this.state.loading ? (
            <SkeletonComponent />
          ) : (
            <>
              {settings.showDefaultHeader && this.state.product && (
                <HeaderModule
                  section={{
                    settings: {
                      title: this.state.product.name,
                      subtitle: this.state.product.subtitle,
                      size: 'small',
                      parallax: settings.parallaxInHeader,
                      images: [require('../../../assets/header-main.jpg').default],
                      background_image: { non_api: true }
                    },
                    type: 'header',
                    id: 'header',
                    language: this.props.app.language.code,
                    uid: 'header'
                  }}
                />
              )}

              <div className={'content-body'}>
                <Row className={'product-container'}>
                  <Col
                    xs={{ span: 22, offset: 1 }}
                    md={{ span: 10, offset: 1 }}
                    lg={{ span: 13, offset: 1 }}
                    xl={{ span: 8, offset: 3 }}
                  >
                    <Row>
                      {this.state.product !== null && (
                        <Col xs={24}>
                          <Link to={appSettings.products.shoppingPage}>
                            <ArrowLeftOutlined /> {t('products:detail.button.go_to_shop')}
                          </Link>
                          <div
                            className={'product-images-container product-image-slider'}
                            style={{ marginTop: 20 }}
                          >
                            <img
                              className={'product-main-image'}
                              alt={this.state.product.name[this.props.app.language.code]}
                              src={
                                this.state.mainImage
                                  ? process.env.REACT_APP_API_URL + this.state.mainImage
                                  : ''
                              }
                            />
                            {this.state.product.pictures && this.state.product.pictures.length > 1 && (
                              <Carousel {...other} afterChange={this.onChange}>
                                {this.state.product.pictures.map((picture, pindex) => {
                                  return (
                                    <div
                                      key={pindex}
                                      className={'product-thumbnail'}
                                      onClick={() =>
                                        this.setState({ mainImage: picture, currentSlide: pindex })
                                      }
                                    >
                                      <img
                                        alt={`Variation ${pindex + 1} for ${this.state.product &&
                                          this.state.product.name[this.props.app.language.code]}`}
                                        src={process.env.REACT_APP_API_URL + picture}
                                      />
                                    </div>
                                  );
                                })}
                              </Carousel>
                            )}
                          </div>
                        </Col>
                      )}
                    </Row>
                  </Col>
                  <Col xs={24} md={13} lg={10} xl={{ span: 8, offset: 1 }}>
                    {this.state.product && (
                      <div className={'product-details-container'}>
                        <div className={'product-details-name'}>
                          <Row>
                            <Col xs={18}>
                              <h2>{this.state.product.name[this.props.app.language.code]}</h2>
                            </Col>
                            <Col xs={6} className={'product-price'}>
                              <span
                                dangerouslySetInnerHTML={{
                                  __html: formatPrice(
                                    this.state.product.price,
                                    this.props.app.currency,
                                    this.props.app.country
                                  )
                                }}
                              />
                            </Col>
                          </Row>
                          {this.state.product.subtitle && (
                            <Row>
                              <strong>
                                {this.state.product.subtitle[this.props.app.language.code]}
                              </strong>
                            </Row>
                          )}
                        </div>
                        <p
                          className={'product-details-description'}
                          dangerouslySetInnerHTML={{
                            __html: this.state.product.description[this.props.app.language.code]
                          }}
                        />

                        <div className={'product-list-items'}>
                          <Row className={'product-list-item'}>
                            <Col xs={12} sm={8}>
                              {t('products:detail.details.color')}
                            </Col>
                            <Col xs={12} sm={16}>
                              {t('products:detail.details.color.white')}
                            </Col>
                          </Row>
                          {this.state.product.choose_thread_color && (
                            <Row className={'product-list-item'}>
                              <Col xs={12} sm={8}>
                                {t('products:detail.details.color_thread')}
                              </Col>
                              <Col xs={12} sm={16}>
                                <Select
                                  placeholder={t(
                                    'products:detail.details.thread_colors.placeholder'
                                  )}
                                  style={{ width: '100%' }}
                                  onChange={e => this.onChangeThreadColor(e.toString())}
                                >
                                  {this.state.product.thread_colors.map((color, tindex) => {
                                    return (
                                      <Select.Option key={tindex} value={color}>
                                        {color}
                                      </Select.Option>
                                    );
                                  })}
                                </Select>
                              </Col>
                            </Row>
                          )}
                          <Row className={'product-list-item'}>
                            <Col xs={12} sm={8}>
                              {t('products:detail.details.stock')}
                            </Col>
                            <Col xs={12} sm={16}>
                              {getStockMessage(this.state.product, this.props.i18n)}
                            </Col>
                          </Row>
                          {this.state.product.personalise && (
                            <Row className={'product-list-item'}>
                              <Col xs={12} sm={8}>
                                {this.state.product.max_characters < 4 ? (
                                  <span>
                                    {t('products:detail.details.custom_initials', {
                                      text: this.state.product.max_characters
                                    })}
                                  </span>
                                ) : (
                                  <span>
                                    {t('products:detail.details.custom_initials', {
                                      text: this.state.product.max_characters
                                    })}
                                  </span>
                                )}
                              </Col>

                              <Col xs={12} sm={16}>
                                <Input
                                  onChange={e => this.setState({ custom_text: e.target.value })}
                                  placeholder={
                                    this.state.product.max_characters < 4
                                      ? this.state.product.max_characters === 1
                                        ? t('products:detail.details.custom_text.enter_initial')
                                        : t('products:detail.details.custom_text.enter_initials')
                                      : t('products:detail.details.custom_text.enter_text')
                                  }
                                />
                              </Col>
                            </Row>
                          )}
                          <Row className={'product-list-item'}>
                            <Col xs={12} sm={8}>
                              {t('products:detail.details.size')}
                              {this.state.product.size_type === 'physical_item' && (
                                <>
                                  {this.state.measurement_unit === 'imperial' ? (
                                    <div
                                      className={'unit-link'}
                                      onClick={() => this.setState({ measurement_unit: 'metric' })}
                                    >
                                      {t('products:detail.details.sizes.show_in_cm')}{' '}
                                      <SwapOutlined />
                                    </div>
                                  ) : (
                                    <div
                                      className={'unit-link'}
                                      onClick={() =>
                                        this.setState({ measurement_unit: 'imperial' })
                                      }
                                    >
                                      {t('products:detail.details.sizes.show_in_inches')}{' '}
                                      <SwapOutlined />
                                    </div>
                                  )}
                                </>
                              )}
                            </Col>
                            <Col xs={12} sm={16}>
                              <Select
                                placeholder={t('products:detail.details.sizes.placeholder')}
                                style={{ width: '100%' }}
                                defaultValue={this.state.size ? this.state.size.id : undefined}
                                onChange={e => this.onChangeSize(e)}
                              >
                                {this.state.product.sizes.map((size, sindex) => {
                                  const dbSize = this.getSize(size);
                                  if (dbSize) {
                                    return (
                                      <Select.Option key={sindex} value={size}>
                                        {this.state.measurement_unit === 'imperial' ? (
                                          <span>{dbSize.imperial_name}</span>
                                        ) : (
                                          <span>{dbSize.metric_name}</span>
                                        )}

                                        {dbSize.price && dbSize.price > 0 ? (
                                          <span>
                                            &nbsp;(+{' '}
                                            {formatPrice(
                                              dbSize.price,
                                              this.props.app.currency,
                                              this.props.app.country
                                            )}
                                            )
                                          </span>
                                        ) : (
                                          <span />
                                        )}
                                      </Select.Option>
                                    );
                                  }
                                  return undefined;
                                })}
                              </Select>
                            </Col>
                          </Row>
                          <Row className={'product-list-item'}>
                            <Col xs={12} sm={8}>
                              {t('products:detail.details.quantity')}
                            </Col>
                            <Col xs={12} sm={16}>
                              <InputNumber
                                min={1}
                                onChange={e =>
                                  this.setState({ quantity: e ? parseInt(e.toString()) : 1 })
                                }
                                defaultValue={this.state.quantity}
                              />
                            </Col>
                          </Row>
                          <Row className={'product-list-item total'}>
                            <Col xs={12} sm={8}>
                              {t('products:detail.details.total')}
                            </Col>
                            <Col xs={12} sm={16}>
                              {formatPrice(
                                (this.state.product.price +
                                  (this.state.size ? this.state.size.price : 0)) *
                                  this.state.quantity,
                                this.props.app.currency,
                                this.props.app.country
                              )}
                            </Col>
                          </Row>
                        </div>

                        <Row gutter={20}>
                          <Col xs={24} sm={12} md={24} lg={24} xl={24} xxl={12}>
                            {this.state.product.manage_stock && this.state.product.stock <= 0 ? (
                              <Button
                                style={{ marginBottom: 10 }}
                                disabled={true}
                                size={'large'}
                                block={true}
                                type={'primary'}
                              >
                                {customWishlistIcon ? (
                                  <img src={require(`../../../assets/${customCartIcon}`).default} />
                                ) : (
                                  <ShoppingCartOutlined />
                                )}
                                &nbsp;
                                {t('products:detail.button.out_of_stock.text')}
                              </Button>
                            ) : (
                              <Button
                                style={{ marginBottom: 10 }}
                                size={'large'}
                                onClick={() =>
                                  handleAddItemToCartClick(
                                    this.state,
                                    this.props,
                                    this.state.product,
                                    []
                                  )
                                }
                                block={true}
                                type={'primary'}
                              >
                                {customWishlistIcon ? (
                                  <img src={require(`../../../assets/${customCartIcon}`).default} />
                                ) : (
                                  <ShoppingCartOutlined />
                                )}
                                &nbsp;
                                {t('products:detail.button.add_to_cart.text')}
                              </Button>
                            )}
                          </Col>
                          <Col xs={24} sm={12} md={24} lg={24} xl={24} xxl={12}>
                            {this.productIsInWishList() ? (
                              <Button
                                style={{ marginBottom: 10 }}
                                size={'large'}
                                onClick={this.removeItemFromWishList}
                                block={true}
                                type={'dashed'}
                              >
                                {customWishlistIcon ? (
                                  <img
                                    src={require(`../../../assets/${customWishlistIcon}`).default}
                                  />
                                ) : (
                                  <HeartOutlined />
                                )}
                                &nbsp;{t('products:detail.button.remove_from_wishlist.text')}
                              </Button>
                            ) : (
                              <Button
                                style={{ marginBottom: 10 }}
                                size={'large'}
                                onClick={this.handleAddItemToWishListClick}
                                block={true}
                                type={'dashed'}
                              >
                                {customWishlistIcon ? (
                                  <img
                                    src={require(`../../../assets/${customWishlistIcon}`).default}
                                  />
                                ) : (
                                  <HeartOutlined />
                                )}
                                &nbsp;{t('products:detail.button.add_to_wishlist.text')}
                              </Button>
                            )}
                          </Col>
                          <Col xs={24}>
                            <Link to={appSettings.products.shoppingPage}>
                              <ArrowLeftOutlined />{' '}
                              {t('products:detail.button.go_back_to_shop.text')}
                            </Link>
                            <Link to={'/cart'} style={{ float: 'right' }}>
                              {t('products:detail.button.go_to_cart.text')}
                              <ArrowRightOutlined />
                            </Link>
                          </Col>
                        </Row>
                      </div>
                    )}
                  </Col>
                </Row>
              </div>
            </>
          )}
        </Content>
        <FooterComponent />
      </Layout>
    );
  }
}

const mapStateToProps = (state: any) => ({
  app: state.app,
  auth: state.auth,
  wishlist: state.wishlist,
  cart: state.cart
});

const mapDispatchToProps = (dispatch: any) => ({
  addItemToWishList: (product: Product) => dispatch(addItemToWishList(product)),
  removeItemFromWishList: (id: number) => dispatch(removeItemFromWishList(id)),
  addItemToCart: (
    product: Product,
    size: { id: number; price: number; name: string },
    quantity: number,
    custom_text: string | null,
    thread_color: string | null,
    color_name: { [key: string]: string } | null
  ) => dispatch(addItemToCart(product, size, quantity, custom_text, thread_color, color_name))
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ProductDetailComponent))
);
