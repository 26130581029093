import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Badge, Button, Col, Drawer, Dropdown, Menu, Result, Row } from 'antd';
import {
  ArrowRightOutlined,
  DeleteOutlined,
  InfoCircleOutlined,
  ShoppingCartOutlined,
  ShoppingOutlined
} from '@ant-design/icons';
import { AuthState } from '../../redux/states/user';
import { Link, withRouter } from 'react-router-dom';
import { ICartState } from '../../redux/states/cart';
import { IAppState } from '../../redux/states/app';
import { settings } from '../../../settings';
import { withTranslation, WithTranslation } from 'react-i18next';
import { CartItem } from '../../types';
import { formatPrice } from '../../helpers/price.helper';
import {
  removeEventFromCart,
  removeGiftCardFromCart,
  removeItemFromCart
} from '../../redux/actions/cart';
import { gtagUpdateEventRemoval, gtagUpdateProductRemoval } from '../../helpers/gtag';
import ShoppingListComponent from '../pages/cart/shopping-list.component';

interface Props extends WithTranslation {
  history: any;
  auth: AuthState;
  cart: ICartState;
  app: IAppState;
  removeItemFromCart: typeof removeItemFromCart;
  removeEventFromCart: typeof removeEventFromCart;
  removeGiftCardFromCart: typeof removeGiftCardFromCart;
}

interface State {
  cartVisible: boolean;
  showShoppingBagDrawer: boolean;
}

class ShoppingCartComponent extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      cartVisible: false,
      showShoppingBagDrawer: false
    };
  }

  handleVisibleChange = (flag: boolean) => {
    this.setState({ cartVisible: flag });
  };

  handleRemoveItem = (item: CartItem) => {
    if (item.product) {
      let id = `${item.product.id}-${item.size.id}${
        item.custom_text ? `-${item.custom_text}` : ''
      }`;
      gtagUpdateProductRemoval(item, this.props.app.language.code);
      this.props.removeItemFromCart(id);
    }
    if (item.event) {
      let id = item.event.id.toString();
      gtagUpdateEventRemoval(item, this.props.app.language.code);
      this.props.removeEventFromCart(id);
    }

    if (item.giftCard) {
      let id = item.giftCard.id.toString();
      this.props.removeGiftCardFromCart(id);
    }
  };

  shoppingList = () => {
    const t = this.props.i18n.t.bind(this.props.i18n);
    const products = this.props.cart.items;
    let totalPrice = 0;
    if (products && products.length > 0) {
      totalPrice += products.reduce(
        (c: number, item: CartItem) => (item.product.price + item.size.price) * item.quantity + c,
        0
      );
    }

    const events = this.props.cart.events;
    if (events && events.length > 0) {
      totalPrice += events.reduce(
        (c: number, item: CartItem) => (item.event.price * item.quantity )+ c + item.extra_amount,
        0
      );
    }

    const giftCards = this.props.cart.giftCards;
    if (giftCards && giftCards.length > 0) {
      totalPrice += giftCards.reduce(
        (c: number, item: CartItem) => item.giftCard.price * item.quantity + c,
        0
      );
    }

    let menu = (
      <Menu>
        <Menu.Item>
          <div className={'menu-no-items'}>
            <InfoCircleOutlined /> {t('app:navbar.menu.cart.no_items')}
          </div>
        </Menu.Item>
        {settings.cart.showGoHomeShoppingCartButton &&
          window.location.pathname !== settings.products.shoppingPage && <Menu.Divider />}
        {settings.cart.showGoHomeShoppingCartButton &&
          window.location.pathname !== settings.products.shoppingPage && (
            <Menu.Item key={'button'}>
              <Link to={settings.products.shoppingPage}>
                <Button style={{ width: 400 }} type={'primary'}>
                  {t('app:navbar.menu.cart.button.go_to_shop.text')}
                </Button>
              </Link>
            </Menu.Item>
          )}
      </Menu>
    );

    if (products.length > 0 || events.length > 0 || giftCards.length > 0) {
      menu = (
        <Menu>
          {products.map((item: CartItem, index: number) => {
            return (
              <Menu.Item key={index}>
                <Row>
                  <Col xs={16}>
                    <Link to={`${settings.products.shoppingPage}${item.product.slug}`}>
                      {item.product.name[this.props.app.language.code]}
                    </Link>
                    &nbsp;({item.size.name})&nbsp;
                    <DeleteOutlined
                      style={{ marginLeft: 5 }}
                      onClick={() => this.handleRemoveItem(item)}
                    />
                  </Col>
                  <Col xs={2} className={'menu-amount'}>
                    {item.quantity}x
                  </Col>
                  <Col xs={6} className={'menu-price'}>
                    <span
                      dangerouslySetInnerHTML={{
                        __html: formatPrice(
                          ((item.product.price + item.size.price) * item.quantity),
                          this.props.app.currency,
                          this.props.app.country
                        )
                      }}
                    />
                  </Col>
                </Row>
              </Menu.Item>
            );
          })}
          {events &&
            events.map((item: CartItem, index: number) => {
              return (
                <Menu.Item key={index}>
                  <Row>
                    <Col xs={16}>
                      <Link to={`${settings.events.rootUrl}${item.event.slug}`}>
                        {item.event.name[this.props.app.language.code]}
                      </Link>
                      <DeleteOutlined
                        style={{ marginLeft: 5 }}
                        onClick={() => this.handleRemoveItem(item)}
                      />
                    </Col>
                    <Col xs={2} className={'menu-amount'}>
                      {item.quantity}x
                    </Col>
                    <Col xs={6} className={'menu-price'}>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: formatPrice(
                            (item.event.price * item.quantity) + item.extra_amount,
                            this.props.app.currency,
                            this.props.app.country
                          )
                        }}
                      />
                    </Col>
                  </Row>
                </Menu.Item>
              );
            })}
          {giftCards &&
            giftCards.map((item: CartItem, index: number) => {
              return (
                <Menu.Item key={index}>
                  <Row>
                    <Col xs={16}>
                      <Link to={`/gift-card${item.giftCard.slug}`}>
                        {item.giftCard.name[this.props.app.language.code]}
                      </Link>
                      <DeleteOutlined
                        style={{ marginLeft: 5 }}
                        onClick={() => this.handleRemoveItem(item)}
                      />
                    </Col>
                    <Col xs={2} className={'menu-amount'}>
                      {item.quantity}x
                    </Col>
                    <Col xs={6} className={'menu-price'}>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: formatPrice(
                            item.giftCard.price * item.quantity,
                            this.props.app.currency,
                            this.props.app.country
                          )
                        }}
                      />
                    </Col>
                  </Row>
                </Menu.Item>
              );
            })}
          <Menu.Divider />
          <Menu.Item>
            <Row>
              <Col xs={{ span: 4, offset: 12 }} className={'menu-total'}>
                <strong>{t('app:navbar.menu.cart.total')}</strong>
              </Col>
              <Col xs={8} className={'menu-total-price'}>
                <span
                  dangerouslySetInnerHTML={{
                    __html: formatPrice(totalPrice, this.props.app.currency, this.props.app.country)
                  }}
                />
              </Col>
            </Row>
          </Menu.Item>
          <Menu.Item key={'button'}>
            <Link to={'/cart'}>
              <Button style={{ width: 400 }} type={'primary'}>
                {t('app:navbar.menu.cart.button.checkout.text')} <ArrowRightOutlined />
              </Button>
            </Link>
          </Menu.Item>
        </Menu>
      );
    }
    return menu;
  };

  render() {
    let count = 0;
    const products = this.props.cart.items;
    const events = this.props.cart.events;
    const giftCards = this.props.cart.giftCards;
    const t = this.props.i18n.t.bind(this.props.i18n);
    const customIcon = settings.navbar.shoppingBag.customIcon;
    if (products) {
      count += products.length;
    }

    if (events) {
      count += events.length;
    }

    if (giftCards) {
      count += giftCards.length;
    }

    if (settings.navbar.shoppingBag.popupStyle === 'drawer') {
      return (
        <>
          <span id={'shopping-cart'} onClick={() => this.setState({ showShoppingBagDrawer: true })}>
            {customIcon ? (
              <Badge className={'header-cart-badge'} count={count}>
                <img
                  alt={'shopping cart icon'}
                  src={require(`../../../assets/${customIcon}`).default}
                />
              </Badge>
            ) : (
              <Badge className={'header-cart-badge'} count={count}>
                {settings.navbar.shoppingBag.icon === 'cart' ? (
                  <ShoppingCartOutlined />
                ) : (
                  <ShoppingOutlined />
                )}
              </Badge>
            )}
          </span>
          <Drawer
            width={'30%'}
            title={t('products:navbar.menu.cart.title')}
            placement='right'
            closable={true}
            onClose={() => this.setState({ showShoppingBagDrawer: false })}
            visible={this.state.showShoppingBagDrawer}
          >
            {settings.navbar.shoppingBag.listStyle === 'compact' && this.shoppingList()}
            {settings.navbar.shoppingBag.listStyle === 'default' && (
              <>
                {this.props.cart.items.length === 0 ? (
                  <Result
                    icon={<ShoppingCartOutlined />}
                    status='info'
                    title={t('pages:cart.no_products.title')}
                    extra={
                      <Link to={settings.products.shoppingPage}>
                        <Button
                          icon={<ShoppingCartOutlined />}
                          size={'large'}
                          type='primary'
                          key='console'
                        >
                          {t('pages:cart.no_products.button.go_shopping.text')}
                        </Button>
                      </Link>
                    }
                  />
                ) : (
                  <>
                    <ShoppingListComponent forceVoucherView={false} />
                    <Row>
                      <Col xs={24}>
                        <Link to={'/cart'}>
                          <Button block={true} type={'primary'}>
                            Proceed to Checkout <ArrowRightOutlined />
                          </Button>
                        </Link>
                      </Col>
                    </Row>
                  </>
                )}
              </>
            )}
          </Drawer>
        </>
      );
    } else {
      return window.innerWidth < 768 ? (
        <span id={'shopping-cart'}>
          <Dropdown
            overlay={this.shoppingList}
            onVisibleChange={this.handleVisibleChange}
            visible={this.state.cartVisible}
          >
            <Badge className={'header-cart-badge'} count={count}>
              {customIcon ? (
                <img
                  alt={'shopping cart icon'}
                  src={require(`../../../assets/${customIcon}`).default}
                />
              ) : (
                <ShoppingCartOutlined />
              )}
            </Badge>
          </Dropdown>
        </span>
      ) : (
        <Link id={'shopping-cart'} to={'/cart'}>
          <Dropdown
            overlay={this.shoppingList}
            onVisibleChange={this.handleVisibleChange}
            visible={this.state.cartVisible}
          >
            <Badge className={'header-cart-badge'} count={count}>
              {customIcon ? (
                <img
                  alt={'shopping cart icon'}
                  src={require(`../../../assets/${customIcon}`).default}
                />
              ) : (
                <ShoppingCartOutlined />
              )}
            </Badge>
          </Dropdown>
        </Link>
      );
    }
  }
}

const mapDispatchToProps = (dispatch: any) => ({
  removeItemFromCart: (id: string) => dispatch(removeItemFromCart(id)),
  removeEventFromCart: (id: string) => dispatch(removeEventFromCart(id)),
  removeGiftCardFromCart: (id: string) => dispatch(removeGiftCardFromCart(id))
});

const mapStateToProps = (state: any) => ({
  app: state.app,
  auth: state.auth,
  cart: state.cart,
  wishlist: state.wishlist
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ShoppingCartComponent))
);
