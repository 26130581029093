import {
  ApiError,
  AUTH_ERROR,
  AUTH_LOGIN,
  AUTH_LOGOUT,
  AUTH_REQUEST,
  UPDATE_TOKEN,
  UPDATE_USER
} from '../types';
import { LoopBack } from '../api';
import Swal from 'sweetalert2';
import { User } from '../states/user';
import { Country, Currency } from '../../types';
import { setCountry, setCurrency } from './app';
import { TFunction } from 'i18next';

export const userLoginFetch = (
  user: any,
  countries: Country[],
  currencies: Currency[],
  t: TFunction
) => {
  return (dispatch: any) => {
    dispatch(authRequest());
    new LoopBack()
      .post('/users/login', user)
      .then((data: any) => {
        const error: ApiError = data.error;
        if (error) {
          dispatch(authError());
          if (error.statusCode === 401) {
            Swal.fire({
              title: t('auth:login.popup.401.title'),
              html: t('auth:login.popup.401.description'),
              icon: 'error'
            });
          } else {
            Swal.fire({
              title: t('auth:login.popup.500.title'),
              html: t('auth:login.popup.500.description'),
              icon: 'error',
              footer: error.message
            });
          }
        } else if (!data.email_verified) {
          dispatch(authError());
          Swal.fire({
            title: t('auth:login.popup.not_verified.title'),
            html: t('auth:login.popup.not_verified.description'),
            icon: 'warning',
            showCancelButton: true,
            cancelButtonText: t('auth:login.popup.not_verified.button.cancel'),
            confirmButtonText: t('auth:login.popup.not_verified.button.send_link'),
            showConfirmButton: true
          }).then(result => {
            if (result.value) {
              new LoopBack()
                .post('/users/verification-link', user)
                .then((data: any) => {
                  Swal.fire({
                    title: t('auth:login.popup.email_sent.title'),
                    html: t('auth:login.popup.email_sent.description'),
                    icon: 'success'
                  });
                })
                .catch(err => {
                  Swal.fire({
                    title: t('auth:login.popup.no_email_sent.title'),
                    html: t('auth:login.popup.no_email_sent.description'),
                    icon: 'error'
                  });
                });
            } else {
            }
          });
        } else {
          // set country and currency in navigation bar based on user login
          if (data.user.customer && data.user.customer.country) {
            const country = countries.filter(country => country.id === data.user.customer.country);
            if (country.length > 0) {
              const currency = currencies.filter(
                _currency => _currency.code === country[0].preferred_currency
              );
              if (currency) {
                dispatch(setCurrency(currency[0]));
              }
              dispatch(setCountry(country[0]));
            }
          }

          dispatch(loginUser(data));
          Swal.fire({
            title: t('auth:login.popup.success.title'),
            html: t('auth:login.popup.success.description'),
            icon: 'success'
          });
        }
      })
      .catch(err => {
        dispatch(authError());
        if (err.status === 401) {
          Swal.fire({
            title: t('auth:login.popup.401.title'),
            html: t('auth:login.popup.401.description'),
            icon: 'error'
          });
        } else {
          Swal.fire({
            title: t('auth:login.popup.500.title'),
            html: t('auth:login.popup.500.description'),
            icon: 'error',
            footer: err.message
          });
        }
      });
  };
};

const authRequest = () => ({
  type: AUTH_REQUEST
});

export const authError = () => ({
  type: AUTH_ERROR
});

export const updateUser = (userObj: User, token: string) => ({
  type: UPDATE_USER,
  payload: { user: userObj, token: token }
});

export const updateToken = (token: string) => ({
  type: UPDATE_TOKEN,
  payload: token
});

const loginUser = (userObj: any) => ({
  type: AUTH_LOGIN,
  payload: userObj
});

export const logoutUser = () => ({
  type: AUTH_LOGOUT
});
